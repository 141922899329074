/* ----- Interactive States ----- */
/*
@State: Default
@Description: Reset starting properties for this component to avoid inheriting properties from a parent component.
*/
.Is-Interactive {--Focus-Margin:0; --Focus-Radius:0; --Highlight-Margin:0; --Highlight-Radius:0; cursor:pointer; background:none; outline:none;}

/*
@State: Hovered
@Description: The highlight area is displayed when the user begins hovering the element. The design is applied to the :after psuedo element using the background property with the the --Highlight-Hovered variable. The Is-Hovered class can be used to apply a hover without actually hovering the element. This is mainly used for testing.
*/
.Is-Interactive:after {grid-area:highlight; pointer-events:none; content:""; position:relative; z-index:0; margin:var(--Highlight-Margin); border-radius:var(--Highlight-Radius); transition-duration:var(--Very-Quick);}
.Is-Interactive:hover,
.Is-Interactive.Is-Hovered {z-index:1;}
.Is-Interactive:hover:after,
.Is-Interactive:hover > *,
.Is-Interactive.Is-Hovered:after,
.Is-Interactive.Is-Hovered > * {filter:var(--Hovered-Filter);}


/*
@State: Pressed
@Description: This uses the same highlight area on the :after psuedo element as Hovered state. Pressing the element adjusts the background to --Highlight-Pressed variable. The Is-Pressed class can be used to apply pressed effect without actually pressing the element. This is mainly used for testing.
*/
.Is-Interactive.Is-Pressed {z-index:1;}
.Is-Interactive.Is-Pressed:after,
.Is-Interactive.Is-Pressed > * {filter:var(--Pressed-Filter);}

/*
@State: Focused
@Description: Focus is displayed when the user begins navigating by keyboard. The design is applied to the :before psuedo element and use the box-shadow property to create the focus ring. Focus can also be displayed when an element inside gains the focus. The Is-Focused class can be used to apply a focus effect without actually setting the element as focused. This is mainly used for testing.
*/
.Is-Interactive:before {grid-area:focus; pointer-events:none; content:""; position:relative; z-index:2; align-self: stretch; justify-self:stretch; border-radius:var(--Focus-Radius); opacity:0.6; margin:-5px; box-shadow:0px 0px 0px 1px rgba(128,128,128,0), 0px 0px 0px 5px rgba(128,128,128,0); transition-duration:var(--Quick);}
.Is-Keyboard-Navigating .Is-Interactive:focus-within:before,
.Is-Keyboard-Navigating .Is-Interactive:focus:before,
.Is-Keyboard-Navigating .Is-Interactive.Is-Focused:before {box-shadow:var(--Focus-Box-Shadow); margin:var(--Focus-Margin);}

/*
@State: Hinted
@Description: Hints are displayed whenever user attention on an object is required. It uses a box shadow animation to highlight the element.
*/
.Is-Interactive.Is-Hinted:before {animation:scale-ripple 3s linear 0s infinite normal both running; box-shadow:var(--Hint-Box-Shadow); margin:var(--Focus-Margin);}


/*
@State: Single Checking
@Description:
*/
.Is-Interactive > .Operator-Radio {display:grid; width:100%; grid-template-columns:[icon-start] 1fr [icon-end]; grid-template-rows:[icon-start] 1fr [icon-end]; transform:translateX( calc( var(--Page-Gutter) * -1 ) ); opacity:0; transition-duration:var(--Slow);}
.Is-Single-Checking .Is-Interactive > .Operator-Radio {transform:translateX(0px); opacity:1;}
.Is-Interactive > .Operator-Radio > .Glyph {grid-area:icon;}
.Is-Interactive > .Operator-Radio > .Glyph > svg {transition-duration:var(--Quick); transform:scale( var(--Glyph-Scale) ); opacity:1;}
.Is-Interactive > .Operator-Radio > .Glyph.Selected > svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) ); opacity:0;}
.Is-Interactive.Is-Selected > .Operator-Radio > .Glyph > svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) ); opacity:0;}
.Is-Interactive.Is-Selected > .Operator-Radio > .Glyph.Selected > svg {transform:scale( var(--Glyph-Scale) ); opacity:1;}

/*
@State: Checking
@Description:
*/
.Is-Interactive > .Operator-Check {display:grid; width:100%; grid-template-columns:[icon-start] 1fr [icon-end]; grid-template-rows:[icon-start] 1fr [icon-end]; transform:translateX( calc( var(--Page-Gutter) * -1 ) ); opacity:0; transition-duration:var(--Slow);}
.Is-Checking .Is-Interactive > .Operator-Check {transform:translateX(0px); opacity:1;}
.Is-Interactive > .Operator-Check > .Glyph {grid-area:icon;}
.Is-Interactive > .Operator-Check > .Glyph > svg {transition-duration:var(--Quick); transform:scale( var(--Glyph-Scale) ); opacity:1;}
.Is-Interactive > .Operator-Check > .Glyph.Selected > svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) ); opacity:0;}
.Is-Interactive.Is-Selected > .Operator-Check > .Glyph > svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) ); opacity:0;}
.Is-Interactive.Is-Selected > .Operator-Check > .Glyph.Selected > svg {transform:scale( var(--Glyph-Scale) ); opacity:1;}

/*
@State: Reordering
@Description:
*/
.Is-Interactive > .Operator-Grab {width:100%; pointer-events:none; transform:translateX(var(--Page-Gutter)) scale(0.8); opacity:0; transition-duration:var(--Slow);}
.Is-Reordering .Is-Interactive > .Operator-Grab {transform:translateX(0px) scale(0.8); opacity:1; pointer-events:auto;}
.Is-Reordering .Is-Interactive {cursor:grab;}
.Is-Reordering .Is-Interactive.Is-Pressed {cursor:grabbing;}

/*
@State: Menued
@Description:
*/
.Is-Interactive > .Operator-Menu {width:100%; transform:scale(0.25); opacity:0; pointer-events:none; transition-duration:var(--Slow);}
.Is-Menued.Is-Interactive > .Operator-Menu {transform:scale(1); opacity:1;  pointer-events:auto;}
.Is-Reordering .Is-Menued.Is-Interactive > .Operator-Menu {transform:scale(0.25); opacity:0; pointer-events:none;}

/*
@State: Current
@Description: The Is-Current class is applied when the element is clicked and has navigated to another panel. It is part of the breadcrumb.
*/
.Is-Current {background: none;}

/*
@State: Selected
@Description: The Is-Selected class is applied when the element is clicked. It can switch the display state of an Icon, Label or Indicator to Icon Selected, Label Press or Indicator Selected if it is present.
*/
.Is-Interactive > [data-field^="Icon"] > svg {transition-duration:var(--Quick); transform:scale( calc(var(--Glyph-Scale) * 0.25) ); opacity:0;}
.Is-Interactive > [data-field="Icon"] > svg {transition-duration:var(--Quick); transform:scale( calc( var(--Glyph-Scale) * var(--Glyph-Adjustment,1) ) ); opacity:1;}
.Is-Interactive.Is-Selected > [data-field="Icon"] > svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) ); opacity:0;}
.Is-Interactive.Is-Selected > [data-field="Icon Selected"] > svg {transform:scale( calc( var(--Glyph-Scale) * var(--Glyph-Adjustment,1) ) ); opacity:1;}

.Is-Interactive > [data-field^="Label"] {grid-area:label; display:block; opacity:0;}
.Is-Interactive > [data-field="Label"] {opacity:1;}
.Is-Interactive.Is-Selected > [data-field="Label"] {opacity:0;}
.Is-Interactive.Is-Selected > [data-field="Label Selected"] {opacity:1;}

.Is-Interactive > [data-field^="Accessory"] > svg {transition-duration:var(--Nominal); transform:scale( calc(var(--Glyph-Scale) * 0.25) ); opacity:0;}
.Is-Interactive > [data-field="Accessory"] > svg {transform:scale( var(--Glyph-Scale) ); opacity:1;}
.Is-Interactive.Is-Selected > [data-field="Accessory"] > svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) ); opacity:0;}
.Is-Interactive.Is-Selected > [data-field="Accessory Selected"] > svg {transform:scale( var(--Glyph-Scale) ); opacity:1;}

/*
@State: Loading
@Description: This indicates that the Interactive element is loading some information from elsewhere.
*/
.Is-Interactive.Is-Loading {pointer-events:none;}
.Is-Interactive.Is-Loading > [data-field="Icon"] > svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) );  opacity:0;}
.Is-Interactive.Is-Loading > [data-field="Icon Loading"] {animation:spin 3s linear 0s infinite normal both running;}
.Is-Interactive.Is-Loading > [data-field="Icon Loading"] > svg {transform:scale( var(--Glyph-Scale) ); opacity:1; }
.Is-Interactive.Is-Loading > [data-field="Label"],
.Is-Interactive.Is-Loading > [data-field^="State"],
.Is-Interactive.Is-Loading > [data-field^="Accessory"] {opacity:0;}
.Is-Interactive.Is-Loading > [data-field="Label Loading"] {opacity:1;}

/*
@State: Back
@Description: This indicates that the Interactive element is is a back button.
*/
.Is-Interactive.Is-Back > [data-field^="Icon"] > svg {transition-duration:var(--Quick); transform:scale( calc(var(--Glyph-Scale) * 0.25) );  opacity:0;}
.Is-Interactive.Is-Back > [data-field="Icon Back"] > svg {transform:scale( var(--Glyph-Scale) ); opacity:1;}
.Is-Interactive.Is-Back > [data-field^="Label"] {opacity:0;}
.Is-Interactive.Is-Back > [data-field="Label Back"] {opacity:1;}

/*
@State: Close
@Description: This indicates that the Interactive element is is a close button.
*/
.Is-Interactive.Is-Close > [data-field^="Icon"] > svg {transition-duration:var(--Quick); transform:scale( calc(var(--Glyph-Scale) * 0.25) );  opacity:0;}
.Is-Interactive.Is-Close > [data-field="Icon Close"] > svg {transform:scale( var(--Glyph-Scale) ); opacity:1;}
.Is-Interactive.Is-Close > [data-field^="Label"] {opacity:0;}
.Is-Interactive.Is-Close > [data-field="Label Close"] {opacity:1;}

/*
@State: Profile
@Description: This indicates that the Interactive element is is a profile button.
*/
.Is-Interactive.Is-Profile > [data-field^="Icon"] > svg {transition-duration:var(--Quick); transform:scale( calc(var(--Glyph-Scale) * 0.25) );  opacity:0;}
.Is-Interactive.Is-Profile > [data-field="Icon Profile"] > svg {transform:scale( var(--Glyph-Scale) ); opacity:1;}
.Is-Interactive.Is-Profile > [data-field^="Label"] {opacity:0;}
.Is-Interactive.Is-Profile > [data-field="Label Profile"] {opacity:1;}

/*
@State: Sign-In
@Description: This indicates that the Interactive element is is a sign in button.
*/
.Is-Interactive.Is-Sign-In > [data-field^="Icon"] > svg {transition-duration:var(--Quick); transform:scale( calc(var(--Glyph-Scale) * 0.25) );  opacity:0;}
.Is-Interactive.Is-Sign-In > [data-field="Icon Sign-In"] > svg {transform:scale( var(--Glyph-Scale) ); opacity:1;}
.Is-Interactive.Is-Sign-In > [data-field^="Label"] {opacity:0;}
.Is-Interactive.Is-Sign-In > [data-field="Label Sign-In"] {opacity:1;}


/*
@State: Disabled
@Description: This state is applied when the button cannot be pressed. It can still recieve focus. TODO: Other states should be prevented from being activated. Also requires more direct control of opacity on sub elements */
.Is-Interactive.Is-Disabled {opacity:0.5; cursor:default;}

/*
@State: Expand / Collapse
@Description: 
*/
.Is-Expandable {display:none!important;}
.Is-Expanded + .Is-Expandable {display:inherit!important; animation:vertical-fade-in var(--Page-Transition-Speed,0.2s) ease 0s 1 normal both running;}
