.post-row { display: flex; }
.post-list.menuable .post-row .menu {
    width: 30px; height: 30px;
    background:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjhweCIgaGVpZ2h0PSIyOHB4IiB2aWV3Qm94PSIwIDAgMjggMjgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9IkVsaXBzaXMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsLTMiIGZpbGw9IiNFMUUxRTEiIGN4PSIxNCIgY3k9IjE0IiByPSIxNCI+PC9jaXJjbGU+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNS44ODg4ODg5LDE0IEMxNS44ODg4ODg5LDE1LjEwNTU1NTYgMTQuOTk0NDQ0NCwxNiAxMy44ODg4ODg5LDE2IEMxMi43ODMzMzMzLDE2IDExLjg4ODg4ODksMTUuMTA1NTU1NiAxMS44ODg4ODg5LDE0IEMxMS44ODg4ODg5LDEyLjg5NDQ0NDQgMTIuNzgzMzMzMywxMiAxMy44ODg4ODg5LDEyIEMxNC45OTQ0NDQ0LDEyIDE1Ljg4ODg4ODksMTIuODk0NDQ0NCAxNS44ODg4ODg5LDE0IFogTTE4Ljc3Nzc3NzgsMTIgQzE3LjY3MjIyMjIsMTIgMTYuNzc3Nzc3OCwxMi44OTQ0NDQ0IDE2Ljc3Nzc3NzgsMTQgQzE2Ljc3Nzc3NzgsMTUuMTA1NTU1NiAxNy42NzIyMjIyLDE2IDE4Ljc3Nzc3NzgsMTYgQzE5Ljg4MzMzMzMsMTYgMjAuNzc3Nzc3OCwxNS4xMDU1NTU2IDIwLjc3Nzc3NzgsMTQgQzIwLjc3Nzc3NzgsMTIuODk0NDQ0NCAxOS44ODMzMzMzLDEyIDE4Ljc3Nzc3NzgsMTIgWiBNOSwxMiBDNy44OTQ0NDQ0NCwxMiA3LDEyLjg5NDQ0NDQgNywxNCBDNywxNS4xMDU1NTU2IDcuODk0NDQ0NDQsMTYgOSwxNiBDMTAuMTA1NTU1NiwxNiAxMSwxNS4xMDU1NTU2IDExLDE0IEMxMSwxMi44OTQ0NDQ0IDEwLjEwNTU1NTYsMTIgOSwxMiBaIiBpZD0iU2hhcGUiIGZpbGw9IiM2NzZFN0MiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvc3ZnPg==') no-repeat center;
}
.post-list.selectable .post-row .checkbox {
    width: 30px; height: 30px;
    background:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzBweCIgaGVpZ2h0PSIzMHB4IiB2aWV3Qm94PSIwIDAgMzAgMzAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICAgICAgICAgICAgICA8ZyBpZD0icmFkaW9ib3hPZmYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGZpbGw9IiNGRUZFRkUiIGZpbGwtcnVsZT0ibm9uemVybyIgY3g9IjE0IiBjeT0iMTQiIHI9IjE0Ij48L2NpcmNsZT4KICAgICAgICAgICAgICAgICAgICAgICAgPGNpcmNsZSBzdHJva2U9IiMzOTkzRTUiIHN0cm9rZS13aWR0aD0iMyIgY3g9IjE0IiBjeT0iMTQiIHI9IjEzIj48L2NpcmNsZT4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvc3ZnPg==') no-repeat center;
}
.post-list.selectable .post-row.selected .checkbox {
    background:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzBweCIgaGVpZ2h0PSIzMHB4IiB2aWV3Qm94PSIwIDAgMzAgMzAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICAgICAgICAgICAgICA8ZyBpZD0icmFkaW9ib3hPZmYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGZpbGw9IiNGRUZFRkUiIGZpbGwtcnVsZT0ibm9uemVybyIgY3g9IjE0IiBjeT0iMTQiIHI9IjE0Ij48L2NpcmNsZT4KICAgICAgICAgICAgICAgICAgICAgICAgPGNpcmNsZSBzdHJva2U9IiMzOTkzRTUiIHN0cm9rZS13aWR0aD0iMCIgY3g9IjE0IiBjeT0iMTQiIHI9IjEzIj48L2NpcmNsZT4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTEyLjYxODY0OTIsMTkuNzkxMzY4NCBMMjEuODkyODQyNywxMC41MTcxNzQ5IEMyMi4yMDc3NjIxLDEwLjIwMjI1NTUgMjIuMjA3NzYyMSw5LjY5MTYyMDQ2IDIxLjg5Mjg0MjcsOS4zNzY3MDExMSBMMjAuNzUyMzY5LDguMjM2MjI3MzIgQzIwLjQzNzQ0OTYsNy45MjEyNTc1NiAxOS45MjY4MTQ1LDcuOTIxMjU3NTYgMTkuNjExODQ0OCw4LjIzNjIyNzMyIEwxMi4wNDgzODcxLDE1Ljc5OTYzNDYgTDguNTE3MTg3NSwxMi4yNjg0MzUgQzguMjAyMjY4MTUsMTEuOTUzNTE1NiA3LjY5MTYzMzA2LDExLjk1MzUxNTYgNy4zNzY2NjMzMSwxMi4yNjg0MzUgTDYuMjM2MTg5NTIsMTMuNDA4OTA4OCBDNS45MjEyNzAxNiwxMy43MjM4MjgxIDUuOTIxMjcwMTYsMTQuMjM0NDYzMiA2LjIzNjE4OTUyLDE0LjU0OTM4MjYgTDExLjQ3ODEyNSwxOS43OTEzMTggQzExLjc5MzA5NDgsMjAuMTA2Mjg3OCAxMi4zMDM2Nzk0LDIwLjEwNjI4NzggMTIuNjE4NjQ5MiwxOS43OTEzNjg0IFoiIGlkPSJQYXRoIiBzdHJva2U9IiMzOTkzRTUiIGZpbGw9IiMzOTkzRTUiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvc3ZnPg==') no-repeat center;
}

.post-row .content {
    display: grid;
    grid-template-columns: [icon-start] 40px [icon-end title-start details-start updated-start] 2fr [title-end details-end updated-end shared-start] 1fr [shared-end];
    grid-template-rows: [icon-start title-start shared-start] auto [icon-end title-end details-start] auto [details-end updated-start] auto [updated-end shared-end];
    min-height: 40px;
    font-family: Lato, Arial;
    flex:1;
}

.post-list.SM .post-row .content {
    grid-template-columns: [icon-start] 40px [icon-end title-start shared-start details-start] 1fr [shared-end updated-start] 1fr [title-end updated-end details-end];
    grid-template-rows: [icon-start title-start] auto [icon-end title-end details-start] auto [details-end shared-start updated-start] auto [updated-end shared-end];
}

.post-row .Type-Icon { grid-area: icon; display: flex; justify-content:center;align-items:center; }
.post-row .Type-Icon svg { max-width: 18px; max-height: 18px; }
.post-row .Title { grid-area: title; padding: 4px 2px; display: flex;
    justify-content: left;
    align-items: center; }
.post-row .Details { grid-area: details; font-size: smaller; color: #888; }
.post-row .Shared { grid-area: shared; display: flex; color: #888;
    justify-content: left;
    align-items: center;}
.post-row .Shared .Field-Icon { max-width: 25px; }
.post-row .Shared .Field-Icon svg { max-width: 14px; max-height: 14px; width: 14px; fill:#888; margin-top: 2px; }
.post-row .Shared ul { display: flex; list-style-type: none; flex-wrap: wrap; }
.post-row .Shared ul li { margin-left: 4px; font-size: smaller; }
.post-row .Shared ul li:last-child:after { content: ""; }
.post-row .Shared ul li:after { content: ","; }
.post-row .Updated { grid-area: updated; display: flex; color: #888; }
.post-row .Updated .Field-Icon { max-width: 25px; }
.post-row .Updated .Field-Icon svg { max-width: 14px; max-height: 14px; width: 14px; fill:#888; margin-top: 2px; }
.post-row .Updated span { margin-left: 4px; font-size: smaller; }

.post-list.SM .post-row .Shared .Field-Icon svg { max-width: 10px; max-height: 10px; margin-top: 3px; }
.post-list.SM .post-row .Updated .Field-Icon svg { max-width: 10px; max-height: 10px; margin-top: 3px; }

.post-list.draggable .post-row .drag-handle { width:20px; min-height: 20px; background: #eee; border: 1px solid #888; }

.post-row { position: relative; }
.post-list.is-dragging .dropzone-before { position: absolute; top:0; left:0; width: 100%; height: 50%;  }
.post-list.is-dragging .dropzone-after { position: absolute; top:50%; left:0; width: 100%; height: 50%; }
.dropzone-before.dropzone-active { border-top: 2px solid red; }
.dropzone-after.dropzone-active { border-bottom: 2px solid red;  }
.post-list.is-dragging .dragged-item .dropzone-before { display: none;  }
.post-list.is-dragging .dragged-item .dropzone-after { display: none; }
