.popover {
    z-index:10001;
}
.popover.default {
	--popover-w: 300px;
	width: 100%;
	max-width: 300px;
	background:#eee;
    border-radius: 10px;
    transition-property: opacity, transform;
    transition-duration: 200ms;
}
.popover.default.closed {
    opacity: 0;
}
.popover.default.docked.closed {
    transform:translateY(50px);
}

.popover.default.docked { border-radius: 10px 10px 0px 0px; }
.popover.default.top-left { border-radius: 0px 10px 10px 10px; }
.popover.default.top-right { border-radius: 10px 0px 10px 10px; }
.popover.default.bottom-left { border-radius: 10px 10px 10px 0px; }
.popover.default.bottom-right { border-radius: 10px 10px 0px 10px; }

.popover.float { position: fixed; }
.popover.float.top-left { top:var(--popover-y); left:var(--popover-x); }
.popover.float.top-right { top:var(--popover-y); right:max( calc( 100vw - var(--popover-x) ), 0px ); }
.popover.float.bottom-left { bottom:calc( 100vh - var(--popover-y) ); left:var(--popover-x); }
.popover.float.bottom-right { bottom:calc( 100vh - var(--popover-y) ); right:max( calc( 100vw - var(--popover-x) ), 0px ); }

.popover.float.top-middle { top:var(--popover-y); left:min( max(0px, calc( var(--popover-x) - 150px ) ), calc( 100vw - 300px ) ); }
.popover.float.bottom-middle { bottom:calc( 100vh - var(--popover-y) ); left:min( max(0px, calc( var(--popover-x) - 150px ) ), calc( 100vw - 300px ) ); }


.popover.docked { position: fixed; bottom: 0px; margin: 0 auto; left: 0; right: 0; }

.popover-backdrop {
    position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index:10000; x-background: rgba(0, 0, 0, 0.1);
    transition-property: opacity;
    transition-duration: 200ms;
}
.popover-backdrop.closed {
    opacity: 0;
}