/* --------------------------------- Overlay ------------------------------ */
/*
@Name: Is-Overlayed
@Summary: Transitions and setup for overlay and backdrop.
*/
.Overlay {pointer-events:none; opacity:0; transition-delay: var(--Overlay-Transition-Speed);}
.Is-Overlayed .Overlay {pointer-events:auto; opacity:1; transition-delay: 0s;}

/** When dealing with multiple overlays, apply a dismissing animation */
.Overlay + .Overlay.Is-Dimissing { opacity:0; }

/*
@Name: Overlay Origin Direction
@Summary: Four directions for the overlay to appear from.
*/
.Overlay-Origin-Top-Left {--Overlay-Reveal-Origin:top left; --Overlay-Reveal-Position:scale(0.9); /*--Overlay-Inset-X:; --Overlay-Inset-Y:;*/}
.Overlay-Origin-Top-Right {--Overlay-Reveal-Origin:top right; --Overlay-Reveal-Position:scale(0.9);}
.Overlay-Origin-Bottom-Left {--Overlay-Reveal-Origin:bottom left; --Overlay-Reveal-Position:scale(0.9);}
.Overlay-Origin-Bottom-Right {--Overlay-Reveal-Origin:bottom left; --Overlay-Reveal-Position:scale(0.9);}

/* --------------------------------- Overlay 1 ------------------------------ */
/*
@Name: Overlay 1
@Summary: Simple narrow width mobile size popover. Aligns to bottom on mobile devices floats near button on regular and larger layouts.
*/

.Overlay-1 {--Overlay-Transition-Speed:var(--Nominal); --Overlay-Popover-Radius:var(--Panel-Radius,15px); --Overlay-Background:rgba(0,0,0,0.4);  --Overlay-Filter:blur(5px); display:grid; z-index:2; width:100%; height:100%;}
.Overlay-1.wide {--Page-Gutter:25px;}
.Overlay-1.regular  {--Page-Gutter:20px;}
.Overlay-1.narrow {--Page-Gutter:15px;}
.Overlay-1.thin {--Page-Gutter:10px;}
.Overlay-1.short {--Page-Gutter:15px;}
/*
.Overlay-1.Overlay-Origin-Top-Left {}
*/

/* --- Wide --- */
/* --- Regular ---  */
.Overlay-1.wide { --Overlay-Origin-X:550; --Overlay-Origin-Y:50;}

.Overlay-1.wide {--Overlay-View-Width:375; --Header-Height:calc(60px + var(--Inset-Top)); --Nav-Background-Angle:180deg; --Profile-Icon-Width:calc(var(--Spacing-Regular) + 30px + var(--Page-Gutter)); grid-template-columns:[backdrop-start] 1fr [main-start] calc( ( var(--Overlay-View-Width) * 1px ) - var(--Profile-Icon-Width) ) [profile-start] var(--Profile-Icon-Width) [profile-end main-end] calc( ( var(--Offset-Width) - var(--Overlay-Origin-X) ) * 1px ) [backdrop-end]; grid-template-rows:[backdrop-start] calc( var(--Overlay-Origin-Y) * 1px ) [main-start profile-start] var(--Profile-Icon-Width) [profile-end] auto [main-end] 1fr [backdrop-end];}

/* --- Regular ---  */
.Overlay-1.regular { --Overlay-Origin-X:550; --Overlay-Origin-Y:50;}

.Overlay-1.regular {--Overlay-View-Width:375; --Header-Height:calc(60px + var(--Inset-Top)); --Nav-Background-Angle:180deg; --Profile-Icon-Width:calc(var(--Spacing-Regular) + 30px + var(--Page-Gutter)); grid-template-columns:[backdrop-start] 1fr [main-start] calc( ( var(--Overlay-View-Width) * 1px ) - var(--Profile-Icon-Width) ) [profile-start] var(--Profile-Icon-Width) [profile-end main-end] calc( ( var(--Offset-Width) - var(--Overlay-Origin-X) ) * 1px ) [backdrop-end]; grid-template-rows:[backdrop-start] calc( var(--Overlay-Origin-Y) * 1px ) [main-start profile-start] var(--Profile-Icon-Width) [profile-end] auto [main-end] 1fr [backdrop-end];}

/* --- Narrow --- */
.Overlay-1.narrow {--Overlay-Reveal-Position:translateY(50px) scale(1); --Inset-Top:0px; --Overlay-Reveal-Origin:bottom center; --Header-Height:calc(60px + var(--Inset-Top)); --Nav-Background-Angle:180deg; --Profile-Icon-Width:calc(var(--Spacing-Regular) + 30px + var(--Page-Gutter));}
.Overlay-1.narrow {grid-template-columns:[backdrop-start main-start] 1fr [profile-start] var(--Profile-Icon-Width) [profile-end main-end backdrop-end]; grid-template-rows:[backdrop-start] 1fr [main-start profile-start] var(--Profile-Icon-Width) [profile-end] auto [main-end backdrop-end];}
.Overlay-1.narrow main {border-bottom-left-radius: 0; border-bottom-right-radius: 0;}

/* --- Backdrop --- */
.Overlay-1 #Overlay-Backdrop {display:block; transition-duration:var(--Overlay-Transition-Speed); grid-area:backdrop; background:rgba(0,0,0,0); content:""; backdrop-filter:none; -webkit-backdrop-filter:none;}
.Is-Overlayed .Overlay-1 #Overlay-Backdrop {display:block; grid-area:backdrop; background:var(--Overlay-Background); content:""; backdrop-filter: var(--Overlay-Filter); -webkit-backdrop-filter: var(--Overlay-Filter);}




/* --- Main --- */
.Overlay-1 main {position:relative; grid-area:main; height:75vh; transform-origin:var(--Overlay-Reveal-Origin); --Vertical-Position:50px; animation:vertical-fade-out var(--Overlay-Transition-Speed) var(--EaseInBack) 0s 1 normal both running; border-radius: var(--Overlay-Popover-Radius);}
.Is-Overlayed .Overlay-1 main {animation:vertical-fade-in var(--Overlay-Transition-Speed) var(--EaseOutBack) 0s 1 normal both running;}
/* Close Button */
.Overlay-1 #Close-Button {grid-area:profile; z-index:3; align-self:center; justify-self:center; --Vertical-Position:50px; animation:vertical-fade-out var(--Overlay-Transition-Speed) var(--EaseInBack) 0s 1 normal both running;}
.Is-Overlayed .Overlay-1 #Close-Button {animation:vertical-fade-in var(--Overlay-Transition-Speed) var(--EaseOutBack) 0s 1 normal both running;}


.Overlay-1 main.Stack-Init.Stack-Active {--Page-Transition-Speed:0s;}




/* --------------------------------- Overlay 2 ------------------------------ */
/*
@Name: Overlay 2
@Summary: Simple narrow width mobile size popover. Aligns to bottom on mobile devices floats near button on regular and larger layouts.
*/

.Overlay-2 {--Overlay-Transition-Speed:var(--Nominal); --Overlay-Popover-Radius:15px; --Overlay-Background:rgba(0,0,0,0.4);  --Overlay-Filter:blur(5px); display:grid; z-index:2; width:100%; height:100%;}
.Overlay-2.wide {--Page-Gutter:25px;}
.Overlay-2.regular  {--Page-Gutter:20px;}
.Overlay-2.narrow {--Page-Gutter:15px;}
.Overlay-2.thin {--Page-Gutter:10px;}
.Overlay-2.short {--Page-Gutter:15px;}

/* --- Wide --- */
.Overlay-2.wide {--Overlay-Reveal-Position:translateX(20px) scale(0.9); --Overlay-Reveal-Origin:top right; --Header-Height:60px; --Logo-Height:var(--Header-Height); --Nav-Width:180px; --Nav-Width-Inset:var(--Nav-Width); --Nav-Height:100%; --Nav-Height-Inset:0px; --Nav-Background-Angle:-90deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(var(--Spacing-Regular) + 30px + var(--Page-Gutter));}
.Overlay-2.wide {position:relative; grid-template-columns:[banner-start logo-start navigation-start main-start] var(--Nav-Width) [navigation-end logo-end ] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end banner-end]; grid-template-rows:[banner-start navigation-start logo-start search-start profile-start main-start] var(--Header-Height) [logo-end search-end profile-end banner-end] 1fr [main-end navigation-end];}

/* --- Regular --- */
.Overlay-2.regular {--Overlay-Reveal-Position:translateX(20px) scale(0.9); --Overlay-Reveal-Origin:top right; --Header-Height:60px; --Logo-Height:var(--Header-Height); --Nav-Width:calc(80px + var(--Inset-Left)); --Nav-Width-Inset:var(--Nav-Width); --Nav-Height:100%; --Nav-Background-Angle:-90deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(var(--Spacing-Regular) + 30px + var(--Page-Gutter));}
.Landscape-L .Overlay-2.regular, .Landscape-R .Overlay-2.regular {--Header-Height:50px;}
.Overlay-2.regular {position:relative; grid-template-columns:[logo-start banner-start navigation-start main-start] var(--Nav-Width) [logo-end navigation-end] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end banner-end]; grid-template-rows:[banner-start navigation-start logo-start search-start profile-start main-start] var(--Header-Height) [logo-end search-end profile-end banner-end] 1fr [main-end navigation-end];}

/* --- Narrow --- */
.Overlay-2.narrow {--Overlay-Reveal-Position:translateY(50px) scale(1); --Overlay-Reveal-Origin:bottom center; --Header-Height:calc(40px + var(--Inset-Top)); --Nav-Width:100%;  --Nav-Width-Inset:0px; --Nav-Height:calc(0px + var(--Inset-Bottom)); --Nav-Background-Angle:180deg; --Profile-Icon-Width:calc(var(--Spacing-Regular) + 30px + var(--Page-Gutter));}
.Overlay-2.narrow {grid-template-columns:[backdrop-start main-start] 1fr [profile-start] var(--Profile-Icon-Width) [profile-end main-end backdrop-end]; grid-template-rows:[backdrop-start] 1fr [main-start profile-start] var(--Profile-Icon-Width) [profile-end] 1fr [main-end backdrop-end];}
.Overlay-2.narrow main {border-bottom-left-radius: 0; border-bottom-right-radius: 0;}

/* --- Backdrop --- */
.Overlay-2 #Overlay-Backdrop {display:block; transition-duration:var(--Overlay-Transition-Speed); grid-area:backdrop; background:rgba(0,0,0,0); content:""; backdrop-filter:none; -webkit-backdrop-filter:none;}
.Is-Overlayed .Overlay-2 #Overlay-Backdrop {display:block; grid-area:backdrop; background:var(--Overlay-Background); content:""; backdrop-filter: var(--Overlay-Filter); -webkit-backdrop-filter: var(--Overlay-Filter);}


/* --- Main --- */
.Overlay-2 main {position:relative; grid-area:main; transform-origin:var(--Overlay-Reveal-Origin); --Vertical-Position:50px; animation:vertical-fade-out var(--Overlay-Transition-Speed) var(--EaseInBack) 0s 1 normal both running; border-radius: var(--Overlay-Popover-Radius);}
.Is-Overlayed .Overlay-2 main {animation:vertical-fade-in var(--Overlay-Transition-Speed) var(--EaseOutBack) 0s 1 normal both running;}
/* Close Button */
.Overlay-2 #Close-Button {grid-area:profile; z-index:3; align-self:center; justify-self:center; --Vertical-Position:50px; animation:vertical-fade-out var(--Overlay-Transition-Speed) var(--EaseInBack) 0s 1 normal both running;}
.Is-Overlayed .Overlay-2 #Close-Button {animation:vertical-fade-in var(--Overlay-Transition-Speed) var(--EaseOutBack) 0s 1 normal both running;}


.Overlay-2 main.Stack-Init.Stack-Active {--Page-Transition-Speed:0s;}
