/** Support for Status and Progress data in Field-L3 */
.Field-L3[data-status="Uploading"] > [data-field="Field"],
.Field-L3[data-status="Processing"] > [data-field="Field"],
.Field-L3[data-status="Error"] > [data-field="Field"] {
  display: none;
}
.Field-L3[data-status="Uploading"] > [data-field="Status"],
.Field-L3[data-status="Processing"] > [data-field="Status"],
.Field-L3[data-status="Error"] > [data-field="Status"] {
  display: block; grid-area: field; align-self: center;
  justify-self: center; padding:10px; padding-bottom:35px; font: var(--Body); background: var(--Overlay-Backdrop);
  border-radius: 10px; min-width:50%; text-align: center;
}
.Field-L3[data-status="Uploading"] > [data-field="Progress"],
.Field-L3[data-status="Processing"] > [data-field="Progress"] {
  display: block; grid-area: field; align-self: center;
  justify-self: center; padding-top:25px; font: var(--Body); text-align: center;
}
