/* --- Logo --- */
#Logo {grid-area:logo; display:grid; background: var(--Background-Primary); overflow:hidden; grid-template-columns:[button-start] 1fr [button-end]; grid-template-rows:[button-start] 1fr [button-end];}

#Logo-Button {grid-area:button; grid-template-columns:[focus-start icon-start] 1fr [icon-end focus-end]; grid-template-rows:[focus-start highlight-start icon-start] 1fr [icon-end highlight-end focus-end];}
#Logo-Button [data-field^="Icon"] {width:100%; height: 100%; --Glyph-Scale:var(--Icon-Scale);}
#Logo-Button [data-field^="Icon"]:before {display:none;}
#Logo-Button [data-field="Icon Logo"] {display:none;}
#Logo-Button [data-field^="Label"] {display:none;}

#Logo.MD #Logo-Button [data-field="Icon"] {display:none;}
#Logo.MD #Logo-Button [data-field="Icon Logo"] {display:block;}
#Logo.MD #Logo-Button [data-field="Icon Logo"] > svg {transform:scale( calc( var(--Glyph-Scale) * var(--Glyph-Adjustment,1) ) ); opacity:1;}
