html.portal body { background: #f9f9f9; }
html.portal body * { -webkit-tap-highlight-color: rgba(0,0,0,0) !important; }
@font-face{font-family:"Lato";src:url('https://s3-us-west-2.amazonaws.com/glide-misc/general/Lato-Light.ttf') format("truetype"); font-weight: lighter; }
@font-face{font-family:"Lato";src:url('https://s3-us-west-2.amazonaws.com/glide-misc/general/Lato-Regular.ttf') format("truetype"); font-weight: normal; }
@font-face{font-family:"Lato";src:url('https://s3-us-west-2.amazonaws.com/glide-misc/general/Lato-Bold.ttf') format("truetype"); font-weight: bold; }

/* Editor CSS */
html.portal .user-avatar-menu {
    position: absolute; top: 15px; right: 15px; width: 35px; height: 35px; border-radius: 20px;
    overflow: hidden; box-shadow: 0 1px 3px rgba(0,0,0,0.3);
    background:#fff url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMjU2IDBjODguMzY2IDAgMTYwIDcxLjYzNCAxNjAgMTYwcy03MS42MzQgMTYwLTE2MCAxNjBTOTYgMjQ4LjM2NiA5NiAxNjAgMTY3LjYzNCAwIDI1NiAwem0xODMuMjgzIDMzMy44MjFsLTcxLjMxMy0xNy44MjhjLTc0LjkyMyA1My44OS0xNjUuNzM4IDQxLjg2NC0yMjMuOTQgMGwtNzEuMzEzIDE3LjgyOEMyOS45ODEgMzQ0LjUwNSAwIDM4Mi45MDMgMCA0MjYuOTU1VjQ2NGMwIDI2LjUxIDIxLjQ5IDQ4IDQ4IDQ4aDQxNmMyNi41MSAwIDQ4LTIxLjQ5IDQ4LTQ4di0zNy4wNDVjMC00NC4wNTItMjkuOTgxLTgyLjQ1LTcyLjcxNy05My4xMzR6Ii8+PC9zdmc+) no-repeat center;
    background-size:60%;
}
html.portal .user-avatar-name { position: absolute; top: 23px; right: 60px; font-family: Lato, Arial; font-size: 14px; }

html.portal .ed-app-tile { width: 90px; display: inline-block; height: 110px; }
html.portal .ed-app-icon { width: 65px;height: 65px;margin: 10px auto;border: 1px solid #ddd;border-radius: 18px; }
html.portal .ed-app-title { text-align: center; font-size: 12px; font-family: Arial; }

html.portal .ed-title { padding: 15px;font-size: 18px;color: #666;font-family: Lato, Arial;font-weight: bold;border-bottom: 1px solid #ccc; }
