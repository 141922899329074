/**
z-index fix in Overlay.

- when pushing a new layer inside an overlay that layer's (.Layer-Current) has z-index: 1, while the layer below has z-index: 2.
  this means the top layer is not receiving click events.

See "Arrangements.css":
- .MD.Arrangement-1 > article.Layer-Lvl1.Layer-Current {opacity:1; z-index:1; pointer-events: auto;}
- .MD.Arrangement-1 > article[class*="Layer-Lvl"] {opacity:0; z-index:2; [...]
*/
.MD.Arrangement-1 > article.Layer-Lvl1.Layer-Current { z-index: 3; }
