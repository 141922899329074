:root {
  --Spacing-Very-Narrow:2px;
}




/* ----- Action Color Schemes ----- */
/*
@Name:Action Importance
@Description: Default colors are assigned to the action. The color areas are:
Backdrop - Large area of color in the button
On Backdrop - Color going over the colored area
On Surface - Usually text which is not on the large area of color
There are primary and secondary version of these colors and on the surface a tint variation too to handle colored icons or links.
Accent refers to the tint of the icon color or other highlights

Major Importance mostly changes the backdrop to a stronger color.
Minor Importance removes the backdrop color.
*/

.Action {
  --Backdrop:var(--Active-Secondary);
  --Backdrop-Selected:var(--Active-Primary);
  --On-Backdrop-Primary:var(--Surface-Primary);
  --On-Backdrop-Primary-Selected:var(--Overlay-Primary);
  --On-Backdrop-Secondary:var(--Surface-Secondary);
  --On-Backdrop-Secondary-Selected:var(--Overlay-Secondary);
  --On-Backdrop-Accent:var(--Active-Primary);
  --On-Backdrop-Accent-Selected:var(--Overlay-Primary);
  --On-Surface-Primary:var(--Surface-Primary);  
  --On-Surface-Primary-Selected:var(--Active-Primary);
  --On-Surface-Secondary:var(--Surface-Secondary);
  --On-Surface-Secondary-Selected:var(--Surface-Secondary);
  --On-Surface-Accent:var(--Surface-Primary);
  --On-Surface-Accent-Selected:var(--Active-Primary);
}

.Action-Importance-Major {
  --Backdrop:var(--Active-Primary);
  --Backdrop-Selected:var(--Active-Primary);
  --On-Backdrop-Primary:var(--Overlay-Primary);
  --On-Backdrop-Primary-Selected:var(--Overlay-Primary);
  --On-Backdrop-Secondary:var(--Overlay-Secondary);
  --On-Backdrop-Secondary-Selected:var(--Overlay-Secondary);
  --On-Backdrop-Accent:var(--Overlay-Primary);
  --On-Backdrop-Accent-Selected:var(--Overlay-Primary);
  --On-Surface-Primary:var(--Active-Primary);  
  --On-Surface-Primary-Selected:var(--Active-Primary);
  --On-Surface-Secondary:var(--Surface-Primary);
  --On-Surface-Secondary-Selected:var(--Surface-Primary);
  --On-Surface-Accent:var(--Active-Primary);
  --On-Surface-Accent-Selected:var(--Active-Primary);
}

.Action-Importance-Minor {
  --Backdrop:transparent;
  --Backdrop-Selected:var(--Active-Secondary);
  --On-Backdrop-Primary:var(--Surface-Primary);
  --On-Backdrop-Primary-Selected:var(--Surface-Primary);
  --On-Backdrop-Secondary:var(--Surface-Secondary);
  --On-Backdrop-Secondary-Selected:var(--Surface-Primary);
  --On-Backdrop-Accent:var(--Surface-Primary);
  --On-Backdrop-Accent-Selected:var(--Surface-Primary);
  --On-Surface-Primary:var(--Surface-Primary);  
  --On-Surface-Primary-Selected:var(--Surface-Primary);
  --On-Surface-Secondary:var(--Surface-Secondary);
  --On-Surface-Secondary-Selected:var(--Surface-Secondary);
  --On-Surface-Accent:var(--Surface-Primary);
  --On-Surface-Accent-Selected:var(--Active-Primary);
}

/* --- Action Meaning Success, Danger, Warning, Info --- */
.Action-Meaning-Success {--Active-Primary:var(--Meaning-Success);}
.Action-Meaning-Danger {--Active-Primary:var(--Meaning-Danger);}
.Action-Meaning-Warning {--Active-Primary:var(--Meaning-Warning);}
.Action-Meaning-Info {--Active-Primary:var(--Meaning-Info);}


/* ----- Hide detail at lower sizes ----- */
.XS.Action > [data-field^="Label"],
.XS.Action > [data-field^="State"],
.SM.Action > [data-field^="State"],
.MD.Action > [data-field^="State"] {display:none;}

/* ----- Hide Element if Omitted ----- */
.Action [data-field]:empty {display:none;}



/* --- Highlight --- */
.Action:after {grid-area:1 / 1 / -1 / -1; background:var(--Backdrop);}
.Action.Is-Selected:after {background:var(--Backdrop-Selected);}

.Action:before {grid-area:1 / 1 / -1 / -1;}

/* --- Icon --- */
.Action > [data-field^="Icon"] {grid-area:icon; display:block; justify-self:center; align-self: center; width:var(--Icon-Width,20px); transition-duration:var(--Nominal);}
.Action > [data-field^="Icon"] svg {fill:var(--On-Backdrop-Accent);}
.Action.Is-Selected > [data-field^="Icon"] svg {fill:var(--On-Backdrop-Accent-Selected);}
.XS.Action {--Icon-Width:25px;}
.SM.Action {--Icon-Width:25px;}
.MD.Action {--Icon-Width:20px;}
.LG.Action {--Icon-Width:25px;}
.XL.Action {--Icon-Width:30px;}

/* --- Label --- */
.Action > [data-field^="Label"] {grid-area:label; display:flex; align-items:center; white-space:nowrap; min-height:var(--Icon-Width); color:var(--On-Backdrop-Primary); font:var(--Caption); text-align:left;}
.Action.Is-Selected > [data-field^="Label"] {color:var(--On-Backdrop-Primary-Selected);}
.Action > [data-field^="Label"] p {width:100%;}
.XS.Action > [data-field^="Label"],
.SM.Action > [data-field^="Label"] {font:var(--Footnote); white-space:normal; text-align:center; justify-content: center; padding-right:0; min-height: auto;}
.LG.Action > [data-field^="Label"] {font:var(--Byline); }
.XL.Action > [data-field^="Label"] {font:var(--Body); }

/* --- State --- */
.Action > [data-field^="State"] {grid-area:state; display:block; font:var(--Caption); align-self:center; text-align:right; color:var(--On-Backdrop-Secondary);}
.Action.Is-Selected > [data-field^="State"] {color:var(--On-Backdrop-Secondary-Selected);}
.Action.Action-Align-Right > [data-field^="State"] {text-align:left;}
.XL.Action > [data-field^="State"] {font:var(--Body);}

/* --- Accessory --- */
.Action > [data-field^="Accessory"] {grid-area:accessory; position:relative; display:block; width:var(--Accessory-Width,10px); align-self:center; justify-self:center;}
.Action > [data-field^="Accessory"] svg {fill:var(--On-Backdrop-Primary);}
.Action.Is-Selected > [data-field^="Accessory"] svg {fill:var(--On-Backdrop-Primary-Selected);}
.XS.Action {--Accessory-Width:10px;}
.SM.Action {--Accessory-Width:10px;}
.MD.Action {--Accessory-Width:15px;}
.LG.Action {--Accessory-Width:20px;}
.XL.Action {--Accessory-Width:20px;}

/* --- Badging --- */
.Action [data-field^="Badge"] {grid-area:1 / 1 / -1 / -1; display:flex; align-self: start; justify-self:end; z-index:2; background:var(--Badge-Background); color:var(--Badge-Overlay); pointer-events: none; font:var(--px12)/var(--px12) sans-serif; border-radius: 1000px; justify-content: center; align-items: center; padding:5px 5px 4px; position:relative; left:var(--Badge-X,10px); top:var(--Badge-Y,-10px); box-shadow:var(--Badge-Shadow); min-width:12px; min-height:11px; opacity:1; transform:scale(1); transition-duration:var(--Very-Slow); transition-timing-function:cubic-bezier(0.13, 2.36, 0.48, 0.74); white-space: nowrap;}
.XS.Action {--Badge-X:-3px; --Badge-Y:3px;}
.SM.Action {--Badge-X:-3px; --Badge-Y:3px;}
.Action [data-field="Badge"]:empty,
.Action [data-field="Badge"][data-notifications="0"],
.Action [data-field="Badge"]:not([data-notifications]) {display:block; opacity:0; transform:scale(0.5);}
.Action [data-field="Badge"]:before {content:attr(data-notifications);}
.Action [data-field="Badge"] > * {display:none;}


/* --- Default Action Properties --- */
.Action {display:grid; grid-gap:var(--Action-Row-Gap) var(--Action-Col-Gap); grid-template-columns:var(--Action-Left-Padding) minmax(min-content,max-content) minmax(min-content,max-content) minmax(min-content,max-content) minmax(min-content,max-content) var(--Action-Right-Padding); grid-template-rows: minmax(var(--Action-Top-Padding),max-content) minmax(min-content,max-content) minmax(var(--Action-Bottom-Padding),max-content);}

/* --- Action Alignment and Justification --- */
.Action.Action-Align-Center {grid-template-columns:minmax(var(--Action-Left-Padding),1fr) auto auto auto auto minmax(var(--Action-Right-Padding),1fr);}
.Action.Action-Align-Left.Is-Justified:not(.XS):not(.SM) {grid-template-columns:var(--Action-Left-Padding) minmax(min-content,max-content) minmax(min-content,max-content) minmax(min-content,max-content) minmax(min-content,max-content) minmax(var(--Action-Right-Padding),1fr);}
.Action.Action-Align-Right.Is-Justified:not(.XS):not(.SM) {grid-template-columns:minmax(var(--Action-Left-Padding),1fr) minmax(min-content,max-content) minmax(min-content,max-content) minmax(min-content,max-content) minmax(min-content,max-content) var(--Action-Right-Padding);}

.Action.Has-Icon.Has-Label.Has-State.Has-Accessory {
grid-template-areas:
". .     .     .     .         ."
". icon  label state accessory ."
". .     .     .     .         .";
}

.Action.Action-Align-Right.Has-Icon.Has-Label.Has-State.Has-Accessory {
  grid-template-areas:
  ". .     .     .     .         ."
  ". label state icon  accessory ."
  ". .     .     .     .         .";
}

.Action.Has-Icon.Has-Label.Has-State {
  grid-template-areas:
  ". .     .     .     .         ."
  ". icon  label state state     ."
  ". .     .     .     .         .";
}
.Action.Action-Align-Right.Has-Icon.Has-Label.Has-State {
  grid-template-areas:
  ". .     .     .     .         ."
  ". label label state icon      ."
  ". .     .     .     .         .";
}
.Action.Has-Icon.Has-Label.Has-State:not(.Has-Accessory) > [data-field^="Accessory"] {display:none;}

.Action.Has-Icon.Has-Label.Has-Accessory {
  grid-template-areas:
  ". .     .     .     .         ."
  ". icon  label label accessory ."
  ". .     .     .     .         .";
}
.Action.Action-Align-Right.Has-Icon.Has-Label.Has-Accessory {
  grid-template-areas:
  ". .     .     .     .         ."
  ". label label icon accessory  ."
  ". .     .     .     .         .";
}
.Action.Has-Icon.Has-Label.Has-Accessory:not(.Has-State) > [data-field^="State"] {display:none;}

.Action.Has-Icon.Has-Label {
  grid-template-areas:
  ". .     .     .     .         ."
  ". icon  .     label label     ."
  ". .     .     .     .         .";
}
.Action.Action-Align-Right.Has-Icon.Has-Label {
  grid-template-areas:
  ". .     .     .     .         ."
  ". label label .     icon      ."
  ". .     .     .     .         .";
}

.Action.Has-Icon.Has-Label:not(.Has-State) > [data-field^="State"],
.Action.Has-Icon.Has-Label:not(.Has-Accessory) > [data-field^="Accessory"] {display:none;}

.Action.Has-Icon.Has-Accessory {
  grid-template-areas:
  ". .     .     .     .         ."
  ". icon  icon  icon  accessory ."
  ". .     .     .     .         .";
}
.Action.Has-Icon.Has-Accessory:not(.Has-Label)  > [data-field^="Label"],
.Action.Has-Icon.Has-Accessory:not(.Has-Label)  > [data-field^="State"] {display:none;} /* If Label is hidden also hide State */

.Action.Has-Icon {
  grid-template-areas:
  ". .     .     .     .         ."
  ". icon  icon  icon  icon      ."
  ". .     .     .     .         .";
}
.Action.Has-Icon:not(.Has-Label) > [data-field^="Label"],
.Action.Has-Icon:not(.Has-Label)  > [data-field^="State"],
.Action.Has-Icon:not(.Has-Accessory)  > [data-field^="Accessory"] {display:none;}  /* If Label is hidden also hide State */

.Action.Has-Label.Has-State.Has-Accessory {
  grid-template-areas:
  ". .     .     .     .         ."
  ". label label state accessory ."
  ". .     .     .     .         .";
}
.Action.Has-Label.Has-State.Has-Accessory:not(.Has-Icon)  > [data-field^="Icon"] {display:none;}

.Action.Has-Label.Has-State {
  grid-template-areas:
  ". .     .     .     .         ."
  ". label label state state     ."
  ". .     .     .     .         .";
}
.Action.Has-Label.Has-State:not(.Has-Icon) > [data-field^="Icon"],
.Action.Has-Label.Has-State:not(.Has-Accessory) > [data-field^="Accessory"] {display:none;}

.Action.Has-Label.Has-Accessory {
  grid-template-areas:
  ". .     .     .     .         ."
  ". label label label accessory ."
  ". .     .     .     .         .";
}
.Action.Has-Label.Has-Accessory:not(.Has-Icon) > [data-field^="Icon"],
.Action.Has-Label.Has-Accessory:not(.Has-State) > [data-field^="State"] {display:none;}

.Action.Has-Label {
  grid-template-areas:
  ". .     .     .     .         ."
  ". label label label label     ."
  ". .     .     .     .         .";
}
.Action.Has-Label:not(.Has-Icon) > [data-field^="Icon"],
.Action.Has-Label:not(.Has-State) > [data-field^="State"],
.Action.Has-Label:not(.Has-Accessory) > [data-field^="Accessory"] {display:none;}

.XS.Action,
.SM.Action {grid-template-columns:minmax(var(--Action-Left-Padding),1fr) minmax(var(--Accessory-Width),max-content) minmax(min-content,max-content) minmax(min-content,max-content) minmax(var(--Accessory-Width),max-content) minmax(var(--Action-Right-Padding),1fr); grid-template-rows: minmax(var(--Action-Top-Padding),max-content) minmax(min-content,max-content) minmax(var(--Action-Row-Gap), max-content) minmax(min-content,max-content) minmax(var(--Action-Bottom-Padding),1fr);}

.SM.Action.Has-Icon.Has-Label.Has-Accessory {
  grid-template-areas:
  ". .     .     .     .         ."
  ". .     icon  icon  accessory ."
  ". .     .     .     .         ."
  ". label label label label     ."
  ". .     .     .     .         ."!important;
}

.SM.Action.Has-Icon.Has-Label {
  grid-template-areas:
  ". .     .     .     .         ."
  ". .     icon  icon  .         ."
  ". .     .     .     .         ."
  ". label label label label     ."
  ". .     .     .     .         ."!important;
}
.SM.Action.Has-Icon.Has-Label:not(.Has-Accessory) {--Accessory-Width:0px;}

.SM.Action.Has-Icon.Has-Accessory {
  grid-template-areas:
  ". .     .     .     .         ."
  ". .     icon  icon  accessory ."
  ". .     .     .     .         ."
  ". .     .     .     .         ."
  ". .     .     .     .         ."!important;
}

.XS.Action.Has-Accessory {
  grid-template-areas:
  ". .     .     .     .         ."
  ". .     icon  icon  accessory ."
  ". .     icon  icon  accessory ."
  ". .     .     .     .         ."!important;
}
.XS.Action.Has-Accessory > [data-field^="Label"],
.XS.Action.Has-Accessory > [data-field^="State"] {display:none;}

.XS.Action {
  grid-template-areas:
  ". .     .     .     .         ."
  ". .     icon  icon  .         ."
  ". .     icon  icon  .         ."
  ". .     .     .     .         ."!important;
}
.XS.Action:not(.Has-Accessory) {--Accessory-Width:0px;}


/* --- Default Action Properties --- */
.Action {
  --Highlight-Radius: var(--Button-Highlight-Radius);
  --Highlight-Margin: 0;
  --Focus-Margin:0px;
  --Focus-Radius:var(--Button-Highlight-Radius);
  --Glyph-Scale:1;
  --Action-Top-Padding:var(--Spacing-Narrow);
  --Action-Right-Padding:var(--Spacing-Narrow);
  --Action-Bottom-Padding:var(--Spacing-Narrow);
  --Action-Left-Padding:var(--Spacing-Narrow);
  --Action-Col-Gap:var(--Spacing-Narrow);
  --Action-Row-Gap:0;
}
.SM.Action {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Very-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Very-Narrow); --Action-Col-Gap:0px;}

.XS.Action {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Very-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Very-Narrow); --Action-Col-Gap:0; --Action-Row-Gap:0;}

/* ----- Action Shape Custom ----- */
/*
@Name:Action Custom
@Description: Rectangular button with user defined clip path. Covers entire area.
*/

.Action-Shape-Custom  {
  --Highlight-Radius:0px;
  --Highlight-Margin: 0;
  --Focus-Margin:0px;
  --Focus-Radius:4px;
  --Glyph-Scale:0.8;
  --Action-Top-Padding:var(--Spacing-Narrow);
  --Action-Right-Padding:var(--Spacing-Narrow);
  --Action-Bottom-Padding:var(--Spacing-Narrow);
  --Action-Left-Padding:var(--Spacing-Very-Narrow);
  --Action-Col-Gap:var(--Spacing-Narrow);
  --Action-Row-Gap:0;

  --Custom-Button-Shape:path('M0.5,1 C0.5,1,0,0.7,0,0.3 A0.25,0.25,1,1,1,0.5,0.3 A0.25,0.25,1,1,1,1,0.3 C1,0.7,0.5,1,0.5,1 Z');
}
.Action.Action-Shape-Custom:after {grid-area:2 / 2 / 3 / 3; clip-path: var(--Custom-Button-Shape);}
.Action.Action-Shape-Custom:before {grid-area:2 / 2 / 3 / 3;}

.SM.Action-Shape-Custom {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Very-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Very-Narrow); --Action-Col-Gap:0px; --Action-Row-Gap:var(--Spacing-Very-Narrow);}
.SM.Action.Action-Shape-Custom:after {grid-area:2 / 3 / 3 / 5;}
.SM.Action.Action-Shape-Custom:before {grid-area:2 / 3 / 3 / 5;}

.XS.Action-Shape-Custom {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Narrow); --Action-Col-Gap:0; --Action-Row-Gap:0;}
.XS.Action.Action-Shape-Custom:after {grid-area:2 / 3 / 3 / 5;}
.XS.Action.Action-Shape-Custom:before {grid-area:2 / 3 / 3 / 5;}


/* ----- Action Shape Lozenge ----- */
/*
@Name:Action Lozenge
@Description: Lozenge shaped button. Covers entire area, unless SM in which case label is outside.
*/
.Action.Action-Shape-Lozenge  {
  --Highlight-Radius:1000px;
  --Highlight-Margin: 0;
  --Focus-Margin:0px;
  --Focus-Radius:1000px;
  --Glyph-Scale:1;
  --Action-Top-Padding:var(--Spacing-Narrow);
  --Action-Right-Padding:var(--Spacing-Narrow);
  --Action-Bottom-Padding:var(--Spacing-Narrow);
  --Action-Left-Padding:var(--Spacing-Narrow);
  --Action-Col-Gap:var(--Spacing-Narrow);
  --Action-Row-Gap:0;
}
.XS.Action.Action-Shape-Lozenge {--Icon-Width:20px;}
.SM.Action.Action-Shape-Lozenge {--Icon-Width:20px;}
.MD.Action.Action-Shape-Lozenge {--Icon-Width:20px;}
.LG.Action.Action-Shape-Lozenge {--Icon-Width:25px;}
.XL.Action.Action-Shape-Lozenge {--Icon-Width:30px;}

.SM.Action.Action-Shape-Lozenge {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Very-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Very-Narrow); --Action-Col-Gap:0px; --Action-Row-Gap:var(--Spacing-Very-Narrow);}
.SM.Action.Action-Shape-Lozenge > [data-field^="Label"] {color:var(--On-Surface-Primary);}
.SM.Action.Action-Shape-Lozenge.Is-Selected > [data-field^="Label"] {color:var(--On-Surface-Primary-Selected);}
.SM.Action.Action.Action-Shape-Lozenge:after {grid-area:1 / 1 / 4 / -1; width: calc( var(--Icon-Width) * 2.5 ); justify-self: center;}
.SM.Action.Action.Action-Shape-Lozenge:before {grid-area:1 / 1 / 4 / -1; width: calc( var(--Icon-Width) * 2.5 ); justify-self: center;}

.XS.Action.Action-Shape-Lozenge {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Narrow); --Action-Col-Gap:0; --Action-Row-Gap:0;}

.LG.Action.Action-Shape-Lozenge {--Action-Top-Padding:var(--Spacing-Regular); --Action-Right-Padding:var(--Spacing-Regular); --Action-Bottom-Padding:var(--Spacing-Regular); --Action-Left-Padding:var(--Spacing-Regular);}
.XL.Action.Action-Shape-Lozenge {--Action-Top-Padding:var(--Spacing-Regular); --Action-Right-Padding:var(--Spacing-Wide); --Action-Bottom-Padding:var(--Spacing-Regular); --Action-Left-Padding:var(--Spacing-Wide);  --Action-Col-Gap:var(--Spacing-Regular);}

/* ----- Action Shape Circle ----- */
/*
@Name:Action Circle
@Description: Circle shaped button. Icon area.
*/
.Action.Action-Shape-Circle  {
  --Highlight-Radius:1000px;
  --Highlight-Margin: 0;
  --Focus-Margin:0px;
  --Focus-Radius:1000px;
  --Glyph-Scale:0.8;
  --Action-Top-Padding:var(--Spacing-Narrow);
  --Action-Right-Padding:var(--Spacing-Narrow);
  --Action-Bottom-Padding:var(--Spacing-Narrow);
  --Action-Left-Padding:0;
  --Action-Col-Gap:var(--Spacing-Narrow);
  --Action-Row-Gap:0;
}
.Action.Action-Shape-Circle > [data-field^="Label"] {color:var(--On-Surface-Primary);}
.Action.Action-Shape-Circle.Is-Selected > [data-field^="Label"] {color:var(--On-Surface-Primary-Selected);}
.Action.Action-Shape-Circle > [data-field^="State"] {color:var(--On-Surface-Secondary);}
.Action.Action-Shape-Circle.Is-Selected > [data-field^="State"] {color:var(--On-Surface-Secondary-Selected);}
.Action.Action-Shape-Circle > [data-field^="Accessory"] svg {fill:var(--On-Surface-Accent);}
.Action.Action-Shape-Circle.Is-Selected > [data-field^="Accessory"] svg {fill:var(--On-Surface-Accent-Selected);}
.Action.Action-Shape-Circle:after {grid-area:icon; width: var(--Icon-Width); height: var(--Icon-Width); justify-self: center;}
.Action.Action-Shape-Circle:before {grid-area:icon; width: var(--Icon-Width); height: var(--Icon-Width); justify-self: center;}

.XS.Action.Action-Shape-Circle {justify-self: start; --Action-Top-Padding:var(--Spacing-Narrow); --Action-Right-Padding:var(--Spacing-Narrow); --Action-Bottom-Padding:var(--Spacing-Narrow); --Action-Left-Padding:var(--Spacing-Narrow); --Action-Col-Gap:0; --Action-Row-Gap:0;}
.XS.Action.Action-Shape-Circle:after {grid-area:1/1/-1/-1; justify-self: stretch; align-self: stretch; width:auto; height:auto;}
.SM.Action.Action-Shape-Circle {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Very-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Very-Narrow); --Action-Col-Gap:0px; --Action-Row-Gap:var(--Spacing-Very-Narrow); --Icon-Width:40px; --Glyph-Scale:0.5;}
.MD.Action.Action-Shape-Circle:after {grid-area:1/1/4/4; justify-self: stretch; width:auto; height:auto;}
.LG.Action.Action-Shape-Circle:after {grid-area:1/1/4/4; justify-self: stretch; width:auto; height:auto;}
.XL.Action.Action-Shape-Circle:after {grid-area:1/1/4/4; justify-self: stretch; width:auto; height:auto;}

/* ----- Action Shape None ----- */
/*
@Name:Action None
@Description: Icon and Label have no background.
*/
.Action.Action-Shape-None  {
  --Highlight-Radius:1000px;
  --Highlight-Margin: 0;
  --Focus-Margin:0px;
  --Focus-Radius:1000px;
  --Glyph-Scale:1;
  --Action-Top-Padding:var(--Spacing-Narrow);
  --Action-Right-Padding:0;
  --Action-Bottom-Padding:var(--Spacing-Narrow);
  --Action-Left-Padding:0;
  --Action-Col-Gap:var(--Spacing-Narrow);
  --Action-Row-Gap:0;
}
.Action.Action-Shape-None > [data-field^="Icon"] svg {fill:var(--On-Surface-Accent);}
.Action.Action-Shape-None.Is-Selected > [data-field^="Icon"] svg {fill:var(--On-Surface-Accent-Selected);}
.Action.Action-Shape-None > [data-field^="Label"] {color:var(--On-Surface-Primary);}
.Action.Action-Shape-None.Is-Selected > [data-field^="Label"] {color:var(--On-Surface-Primary-Selected);}
.Action.Action-Shape-None > [data-field^="State"] {color:var(--On-Surface-Secondary);}
.Action.Action-Shape-None.Is-Selected > [data-field^="State"] {color:var(--On-Surface-Secondary-Selected);}
.Action.Action-Shape-None > [data-field^="Accessory"] svg {fill:var(--On-Surface-Accent);}
.Action.Action-Shape-None.Is-Selected > [data-field^="Accessory"] svg {fill:var(--On-Surface-Accent-Selected);}
.Action.Action-Shape-None:after {grid-area:1 / 1 / -1 / -1; background:transparent;}
.Action.Action-Shape-None:before {grid-area:1 / 1 / -1 / -1;}

.SM.Action.Action-Shape-None {--Action-Top-Padding:var(--Spacing-Narrow); --Action-Right-Padding:var(--Spacing-Very-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Very-Narrow); --Action-Col-Gap:0px;}

.XS.Action.Action-Shape-None {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Narrow); --Action-Col-Gap:0; --Action-Row-Gap:0;}

/* ----- Action Shape Line ----- */
/*
@Name:Action Line
@Description: Icon and Label have no background. A line is drawn on one side.
*/
.Action.Action-Shape-Line  {
  --Highlight-Radius:3px;
  --Highlight-Margin: 0;
  --Focus-Margin:0px;
  --Focus-Radius:3px;
  --Glyph-Scale:0.9;
  --Action-Top-Padding:minmax(var(--Spacing-Narrow), var(--Button-) );
  --Action-Right-Padding:var(--Spacing-Regular);
  --Action-Bottom-Padding:var(--Spacing-Narrow);
  --Action-Left-Padding:var(--Spacing-Regular);
  --Action-Col-Gap:var(--Spacing-Narrow);
  --Action-Row-Gap:var(--Spacing-Very-Narrow);
}
.Action.Action-Shape-Line > [data-field^="Icon"] svg {fill:var(--On-Surface-Accent);}
.Action.Action-Shape-Line.Is-Selected > [data-field^="Icon"] svg {fill:var(--On-Surface-Accent-Selected);}
.Action.Action-Shape-Line > [data-field^="Label"] {color:var(--On-Surface-Primary);}
.Action.Action-Shape-Line.Is-Selected > [data-field^="Label"] {color:var(--On-Surface-Primary-Selected);}
.Action.Action-Shape-Line > [data-field^="State"] {color:var(--On-Surface-Secondary);}
.Action.Action-Shape-Line.Is-Selected > [data-field^="State"] {color:var(--On-Surface-Secondary-Selected);}
.Action.Action-Shape-Line > [data-field^="Accessory"] svg {fill:var(--On-Surface-Accent);}
.Action.Action-Shape-Line.Is-Selected > [data-field^="Accessory"] svg {fill:var(--On-Surface-Accent-Selected);}
.Action.Action-Shape-Line:before {grid-area:1 / 1 / -1 / -1;}

.SM.Action.Action-Shape-Line {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Very-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Very-Narrow); --Action-Col-Gap:0px; --Action-Row-Gap:var(--Spacing-Very-Narrow);}

.XS.Action.Action-Shape-Line {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Narrow); --Action-Col-Gap:0; --Action-Row-Gap:0;}

/* Line Positions - Define at #Template div level so as to allow definition by Inside and Outside */
#Template {
  --Action-Line-Top: 1 / 1 / 2 / -1; 
  --Action-Line-Right: 1 / -2 / -1 / -1; 
  --Action-Line-Bottom: -2 / 1 / -1 / -1;
  --Action-Line-Left: 1 / 1 / -1 / 2;
}

.Action.Action-Shape-Line:after {grid-area:var(--Action-Line-Bottom);}
.Action.Action-Shape-Line.Action-Line-Position-Inside:after {grid-area:var(--Action-Line-Inside); align-self:var(--Action-Line-Inside-Align,stretch); justify-self:var(--Action-Line-Inside-Justify,stretch); height:var(--Action-Line-Height,auto); width:var(--Action-Line-Width,auto);}
.Action.Action-Shape-Line.Action-Line-Position-Outside:after {grid-area:var(--Action-Line-Outside); align-self:var(--Action-Line-Outside-Align,stretch); justify-self:var(--Action-Line-Outside-Justify,stretch); height:var(--Action-Line-Height,100%); width:var(--Action-Line-Width,100%);}
.Action.Action-Shape-Line.Action-Line-Position-Top:after {grid-area:var(--Action-Line-Top); align-self:start; height:var(--Button-Line-Thickness);}
.Action.Action-Shape-Line.Action-Line-Position-Right:after {grid-area:var(--Action-Line-Right); justify-self:end; width:var(--Button-Line-Thickness);}
.Action.Action-Shape-Line.Action-Line-Position-Bottom:after {grid-area:var(--Action-Line-Bottom); align-self:end; height:var(--Button-Line-Thickness);}
.Action.Action-Shape-Line.Action-Line-Position-Left:after {grid-area:var(--Action-Line-Left); justify-self:start; width:var(--Button-Line-Thickness);}


/* ----- Action Shape Switch ----- */
/*
@Name:Action Switch
@Description: Switch shaped button. Icon area.
*/
.Action.Action-Shape-Switch  {
  --Highlight-Radius:1000px;
  --Highlight-Margin: 0;
  --Focus-Margin:0px;
  --Focus-Radius:1000px;
  --Glyph-Scale:0.85;
  --Action-Top-Padding:0;
  --Action-Right-Padding:0;
  --Action-Bottom-Padding:0;
  --Action-Left-Padding:0;
  --Action-Col-Gap:var(--Spacing-Narrow);
  --Action-Row-Gap:0;
}
.Action.Action-Shape-Switch > [data-field^="Label"] {color:var(--On-Surface-Primary);}
.Action.Action-Shape-Switch.Is-Selected > [data-field^="Label"] {color:var(--On-Surface-Primary-Selected);}
.Action.Action-Shape-Switch > [data-field^="State"] {color:var(--On-Surface-Secondary);}
.Action.Action-Shape-Switch.Is-Selected > [data-field^="State"] {color:var(--On-Surface-Secondary-Selected);}
.Action.Action-Shape-Switch > [data-field^="Accessory"] svg {fill:var(--On-Surface-Accent);}
.Action.Action-Shape-Switch.Is-Selected > [data-field^="Accessory"] svg {fill:var(--On-Surface-Accent-Selected);}
.Action.Action-Shape-Switch:after {grid-area:icon;}
.Action.Action-Shape-Switch:before {grid-area:icon;}

.SM.Action.Action-Shape-Switch {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Very-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Very-Narrow); --Action-Col-Gap:0px; --Action-Row-Gap:var(--Spacing-Very-Narrow);}

.XS.Action.Action-Shape-Switch {--Action-Top-Padding:var(--Spacing-Very-Narrow); --Action-Right-Padding:var(--Spacing-Narrow); --Action-Bottom-Padding:var(--Spacing-Very-Narrow); --Action-Left-Padding:var(--Spacing-Narrow); --Action-Col-Gap:0; --Action-Row-Gap:0;}

/* --- Icon --- */
.Action.Action-Shape-Switch > [data-field^="Icon"] {width:calc( var(--Icon-Width) * 1.5 ); height:var(--Icon-Width); align-self: center;}
.Action.Action-Shape-Switch > [data-field^="Icon"] svg {height:var(--Icon-Width); transform: scale( var(--Glyph-Scale) ) translateX( calc( var(--Icon-Width) * -0.25) );}
.Action.Action-Importance-Minor.Action-Shape-Switch > [data-field^="Icon"] svg {fill:var( --On-Surface-Secondary);}
.Action.Action-Shape-Switch.Is-Selected > [data-field^="Icon"] svg {transform: scale( var(--Glyph-Scale) ) translateX( calc( var(--Icon-Width) * 0.25) );}
.Action.Action-Importance-Minor.Action-Shape-Switch.Is-Selected > [data-field^="Icon"] svg {fill:var( --On-Surface-Accent-Selected);}

/* --- Accessory --- */
.Action.Action-Shape-Switch > [data-field^="Accessory"] {display:none; --Accessory-Width:0;}

/* --- Highlight --- */
.Action.Action-Shape-Switch:before {height:var(--Icon-Width); align-self: center;} 
.Action.Action-Shape-Switch:after {height:var(--Icon-Width); align-self: center;}
.Action.Action-Importance-Minor.Action-Shape-Switch:after {background:var(--Backdrop-Selected);}