/** Fix for overlay going off-screen on wide and regular.
    Using fixed position of 20px from top, instead of --Overlay-Origin-Y */
.Overlay-1.wide.Overlay-Origin-Top-Left,
.Overlay-1.wide.Overlay-Origin-Top-Right,
.Overlay-1.wide.Overlay-Origin-Bottom-Left,
.Overlay-1.wide.Overlay-Origin-Bottom-Right,
.Overlay-1.regular.Overlay-Origin-Top-Left,
.Overlay-1.regular.Overlay-Origin-Top-Right,
.Overlay-1.regular.Overlay-Origin-Bottom-Left,
.Overlay-1.regular.Overlay-Origin-Bottom-Right {
    grid-template-rows:[backdrop-start] 20px [main-start profile-start] var(--Profile-Icon-Width) [profile-end] auto [main-end] 1fr [backdrop-end];
}

/** .Overlay-Full when Overlay should take the whole screen */
.Overlay-1.Overlay-Full.regular.Overlay-Origin-Top-Left,
.Overlay-1.Overlay-Full.regular.Overlay-Origin-Top-Right,
.Overlay-1.Overlay-Full.regular.Overlay-Origin-Bottom-Left,
.Overlay-1.Overlay-Full.regular.Overlay-Origin-Bottom-Right,
.Overlay-1.Overlay-Full.wide.Overlay-Origin-Top-Left,
.Overlay-1.Overlay-Full.wide.Overlay-Origin-Top-Right,
.Overlay-1.Overlay-Full.wide.Overlay-Origin-Bottom-Left,
.Overlay-1.Overlay-Full.wide.Overlay-Origin-Bottom-Right {
    grid-template-columns: [backdrop-start] 1fr [main-start] 20fr [profile-start] var(--Profile-Icon-Width) [profile-end main-end] 1fr [backdrop-end];
    grid-template-rows:[backdrop-start] 20px [main-start profile-start] var(--Profile-Icon-Width) [profile-end] 1fr [main-end] 20px [backdrop-end];
    --Nav-Width-Inset: 0px;
}
.Overlay-1.Overlay-Full.regular main,
.Overlay-1.Overlay-Full.wide main
{ height: auto; }

/** .Overlay-Long when Overlay should take the whole screen, even on narrow displays. */
.Overlay-1.Overlay-Long.narrow main,
.Overlay-1.Overlay-Long.regular main,
.Overlay-1.Overlay-Long.wide main
{ height: 95vh; }

/** Option colors */
.Option-Color-Blue { --Option-Color:rgb(27,141,195); }
.Option-Color-Orange { --Option-Color:rgb(233,113,12); }
.Option-Color-Green { --Option-Color:rgb(44,160,11); }
.Option-Color-Red { --Option-Color:rgb(208,53,19); }
.Option-Color-Yellow { --Option-Color:rgb(240,173,2); }
.Option-Color-Turquoise { --Option-Color:rgb(37,196,184); }
.Option-Color-Purple { --Option-Color:rgb(160,11,136); }
.Option-Color-Grey, .Option-Color-Gray { --Option-Color:rgb(175,175,175); }

.Option-Badge p {
    background: var(--Option-Color,#777);
    color:var(--Overlay-Primary);
    padding:2px;
    border-radius: 30px;
}
.Option-Badge p:empty { display:none; }
.Workflow-List-1 [data-field="Workflow Detail"] .Option-Badge p:empty { display:none; }


.Label-Badge p {
    /* x-background: var(--Option-Color,#777); */
    /* color:var(--Surface-Primary); */
    padding:2px;
    border-radius: 30px;
}
.Label-Badge p:empty { display:none; }
.Workflow-List-1 [data-field="Workflow Detail"] .Label-Badge p:empty { display:none; }
.Label-Badge [data-field="Label"] { display: block; }


/** Action colors */
.Action-Importance-Major.Action-Color-Blue { --Backdrop:rgb(27,141,195); }
.Action-Importance-Major.Action-Color-Orange { --Backdrop:rgb(233,113,12); }
.Action-Importance-Major.Action-Color-Green { --Backdrop:rgb(44,160,11); }
.Action-Importance-Major.Action-Color-Red { --Backdrop:rgb(208,53,19); }
.Action-Importance-Major.Action-Color-Yellow { --Backdrop:rgb(240,173,2); }
.Action-Importance-Major.Action-Color-Turquoise { --Backdrop:rgb(37,196,184); }
.Action-Importance-Major.Action-Color-Purple { --Backdrop:rgb(160,11,136); }
.Action-Importance-Major.Action-Color-Grey, .Action-Importance-Major.Action-Color-Gray { --Backdrop:rgb(175,175,175); } /* Fun note: "gray" (which is the american spelling) is more commonly accepted in browsers (e.g. early IE). Prefer american spelling in class names/settings. */

/** Collection filter dropdown (when replacing Slider for > 20 items) - this allows left-aligned actions to be full-width. */
.Action.Action-Fill-Width {
    grid-template-columns: var(--Action-Left-Padding) minmax(min-content,max-content) minmax(min-content,max-content) 1fr 1fr var(--Action-Right-Padding);
}
.Action.Action-Fill-Width [data-field="Label"], .Action.Action-Fill-Width [data-field="Label Selected"] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* this doesn't work in flex */
}

.slide-filter.Slider > .Action-Fill-Width {
    margin: 5px var(--Page-Gutter);
}

.Detail-D0 > .Card { display: block; }
.Detail-D0 > .Card > [data-field] {
    display: block;
    margin: var(--Spacing-Narrow) var(--Page-Gutter);
}
.Detail-D0 > .Card > [data-field].collection { margin:0;  }

.Detail-D0 > .Card > [data-field]:before {
    display: block; content:attr(data-field);
    font: var(--Caption);
    color: var(--Surface-Primary);
}
.Detail-D0 > .Card > [data-field].collection:before { display: none; content:'';  }
.Detail-D0 > .Card > [data-field="List Title"],
.Detail-D0 > .Card > [data-field="List Detail"],
.Detail-D0 > .Card > [data-field="Workflow Title"],
.Detail-D0 > .Card > [data-field="Workflow Detail"]
{ display:none; }


/** Checkbox field */
.Checkbox-L1 {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    gap: 0px 0px;
    grid-template-areas:
      "hint more"
      "input input";
}
.Checkbox-L1 > p {
    font: var(--Caption);
    text-transform: var(--Caption-Case);
    color: var(--Surface-Secondary);
    grid-area: hint;
 }
.Checkbox-L1 > .Checkbox-Action {
    margin: 10px 0;
    grid-area: input;
}
.Checkbox-L1 > .Hint-Action {
    grid-area: more;
}

.Workflow-Footer {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr;
    gap: var(--Spacing-Regular);
    grid-template-areas: "left center right";
    padding: var(--Spacing-Regular) var(--Spacing-Wide);
    background: linear-gradient(180deg, rgb(235, 235, 235) 0%,rgb(235, 235, 235) 1px,rgb(255, 255, 255) 1px,rgb(255, 255, 255) 100%);
}
.Footer-Left-Section { grid-area: left; }
.Footer-Center-Section {
    grid-area: center; font: var(--Caption);
}
.Footer-Align-Right {
    display:flex;justify-content:flex-end;align-items:center;
}
.Footer-Right-Section { grid-area: right; }
.Footer-Column-Group {
    /*
    display: grid;
    grid-auto-flow: column;
    grid-gap: var(--Spacing-Narrow);
    */
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

/* Web serve - skip first animation */
body .Stack-Init.Stack-Active.Stack-No-Anim,
body .Stack-Init.Stack-Active.Stack-No-Anim > article > header > p,
body .Stack-Init.Stack-Active.Stack-No-Anim > article > section,
body .Stack-Init.Stack-Active.Stack-No-Anim > article > header > .Action,
body .Stack-Init.Stack-Active.Stack-No-Anim > article > [data-field="Cover"] {
    animation:none;transition-property: none;animation-delay: 0;animation-duration: 0;
}

/* Link styles */
#App a {
    color: inherit;
    text-decoration: inherit;
}

/* Sync Status */
.Sync-Status { color:#aaa; }
.Sync-Status.Sync-Status-Error {
    background: rgb(222, 81, 81); color:#fff;
}
.Sync-Status.Sync-Status-Warning {
    background: rgb(240, 230, 141); color:#333;
}
.Sync-Status p:empty { display: none; }
.Sync-Status p { padding:10px; font:var(--Caption); }