 /* --- App, Editor & Toolbar --- */

 /* Panels Layout P1 */
 /*
 @Name: Panels Layout P1
 @Summary: App Panel on Left, Editor Panel on Right
 */
.Panels-P1 {display:grid; position:relative; height:100%; grid-template-columns:[app-start] 1fr [app-end editor-start] 0px [editor-end]; grid-template-rows:[app-start editor-start] 1fr [app-end editor-end]; box-sizing: border-box; margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; -webkit-tap-highlight-color: transparent; -webkit-text-size-adjust:none; overflow:hidden;}
 .Editing .Panels-P1 {padding-top:var(--Inset-Top); grid-template-columns:[app-start] 1fr [app-end editor-start] auto [editor-end];}

 /* App Panel */
 .Panels-P1 > #App {grid-area:app;display:block;}

 /* Editor Panel */
 .Panels-P1 > #Editor {grid-area:editor; display:flex; align-items: center; justify-content: center;  width:0; padding-left:10px;}

 /* Handle */
 .Panels-P1 > #Handle {grid-area:editor; display:none; align-items:center; justify-content:center; position:absolute; z-index:1; pointer-events:none;}
 .Panels-P1 > #Handle:before {position:relative; display:block; content:""; border-radius:2px; background:rgba(128,128,128,0.4); opacity:0; transition-duration:0.2s; width:4px; height:4px; border:1px solid rgba(255,255,255,0.2);}
 .Panels-P1 > #Handle {position:absolute; z-index:1; top:0px; left:-5px; width:20px; height:100%; pointer-events:none; cursor:ew-resize;}
 .Panels-P1 > #Handle:before {height:40px;}
 .Editing .Panels-P1 > #Handle,
 .editing .Panels-P1 > #Handle {display:flex; pointer-events:auto;}
 .Editing .Panels-P1 > #Handle:before,
 .editing .Panels-P1 > #Handle:before {opacity:1;}
 .Editing .Panels-P1 > #Handle:active:before,
 .editing .Panels-P1 > #Handle:active:before {height:80%; background:rgba(128,128,128,1);}


/** Editor's width is set using a CSS var. */
.Panels-P1 > #Editor { width: var(--Editor-Width); }
/** When page is in Single-Panel mode and Editing, make the editor full-screen. */
.Editing.Editing-Single-Panel .Panels-P1 > #App { display: none; }
.Editing.Editing-Single-Panel .Panels-P1 > #Handle { display: none; }
.Editing.Editing-Single-Panel .Panels-P1 > #Editor { width: 100%; grid-area: app; padding-left: 0; }

/* Prevent Editor from jittering during slow layout recalc. */
.Panels-P1 > #Editor { min-height:100vh;height:100vh; }