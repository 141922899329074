/* --- Device Environment Variables --- */


/*
@Name: Default
@Summary: Shop listing above square picture post. Followed by title and description with right aligned buy button.

html.Editing,
html.editing  {--Screen-Width:100%; --Screen-Height:100%; --Screen-Radius:0px; --Bezel-Image-Width:100%; --Bezel-Image-Height:100%; --Bezel-Image:none; --Bezel-Shadow-Inset:0px; --Bezel-Shadow:0px 0px 20px 0px rgba(0,0,0,0.8); --Bezel-Shadow-Radius:0px; --Bezel-Background:rgba(0,0,0,0.4);}
*/

 /* --- Device Enviroment Variables --- */
.No-Device {--Rotate-Increment:0deg; --Screen-Radius:0px; --Panel-Shadow:none; --Bezel-Image-Width:100%; --Bezel-Image-Height:100%; --Bezel-Image:none; --Bezel-Shadow-Inset:0; --Bezel-Shadow:none; --Bezel-Shadow-Radius:0px; --Bezel-Background:none;}
.Simulate.No-Device {--Screen-Radius:15px; --Panel-Shadow:0px 5px 10px rgba(0,0,0,0.4); --Bezel-Shadow-Radius:15px;}

.Simulate.iPhoneXS {--Rotate-Increment:90deg; --Screen-Width:375; --Screen-Height:812; --Screen-Radius:35px; --Panel-Shadow:none; --Bezel-Image-Width:435; --Bezel-Image-Height:866; --Bezel-Image:url("../images/iPhoneXS.svg") 0% 0% / cover no-repeat; --Bezel-Shadow-Inset:20; --Bezel-Shadow:0px 0px 20px 0px rgba(0,0,0,0.8); --Bezel-Shadow-Radius:60px; --Bezel-Background:rgba(0,0,0,0.4);}
.iPhoneXS.Rotation-None {--Inset-Top:30px; --Inset-Bottom:30px; --Inset-Left:0px; --Inset-Right:0px; }
.iPhoneXS.Rotation-L {--Inset-Top:0px; --Inset-Bottom:30px; --Inset-Left:30px; --Inset-Right:0px;}
.iPhoneXS.Rotation-R {--Inset-Top:0px; --Inset-Bottom:30px;  --Inset-Left:0px; --Inset-Right:40px;}

.Simulate.iPad11Inch {--Rotate-Increment:90deg; --Screen-Width:1194; --Screen-Height:834; --Screen-Radius:20px; --Panel-Shadow:none; --Bezel-Image-Width:1302; --Bezel-Image-Height:935; --Bezel-Image:url("../images/iPad11Inch.svg") 0% 0% / cover no-repeat; --Bezel-Shadow-Inset:20; --Bezel-Shadow:0px 0px 20px 0px rgba(0,0,0,0.8); --Bezel-Shadow-Radius:60px; --Bezel-Background:rgba(0,0,0,0.4);}
 .iPad11Inch.Rotation-None {--Inset-Top:24px; --Inset-Bottom:20px; --Inset-Left:0px; --Inset-Right:0px; }
 .iPad11Inch.Rotation-L {--Inset-Top:24px; --Inset-Bottom:20px; --Inset-Left:0px; --Inset-Right:0px;}
 .iPad11Inch.Rotation-R {--Inset-Top:24px; --Inset-Bottom:20px;  --Inset-Left:0px; --Inset-Right:0px;}

.Simulate.MacBookAir {--Rotate-Increment:0deg; --Screen-Width:1280; --Screen-Height:800; --Screen-Radius:0px; --Panel-Shadow:none; --Bezel-Image-Width:1515; --Bezel-Image-Height:972; --Bezel-Image:url("../images/MacBookAir.svg") 0% 0% / cover no-repeat; --Bezel-Shadow-Inset:120; --Bezel-Shadow:none; --Bezel-Shadow-Radius:0px; --Bezel-Background:none;}
 .MacBookAir.Rotation-None {--Inset-Top:0px; --Inset-Bottom:0px; --Inset-Left:0px; --Inset-Right:0px; }
 .MacBookAir.Rotation-L {--Inset-Top:0px; --Inset-Bottom:0px; --Inset-Left:0px; --Inset-Right:0px;}
 .MacBookAir.Rotation-R {--Inset-Top:0px; --Inset-Bottom:0px;  --Inset-Left:0px; --Inset-Right:0px;}


 .Simulate.iPhoneSE {--Rotate-Increment:90deg; --Screen-Width:375; --Screen-Height:667; --Screen-Radius:0px; --Panel-Shadow:none; --Bezel-Image-Width:440; --Bezel-Image-Height:891; --Bezel-Image:url("../images/iPhoneSE.svg") 0% 0% / cover no-repeat;  --Bezel-Shadow-Inset:20; --Bezel-Shadow:0px 0px 20px 0px rgba(0,0,0,0.8); --Bezel-Shadow-Radius:60px; --Bezel-Background:rgba(0,0,0,0.4);}
 .iPhoneSE.Rotation-None {--Inset-Top:20px; --Inset-Bottom:0px; --Inset-Left:0px; --Inset-Right:0px; }
 .iPhoneSE.Rotation-L {--Inset-Top:20px; --Inset-Bottom:0px; --Inset-Left:0px; --Inset-Right:0px;}
 .iPhoneSE.Rotation-R {--Inset-Top:20px; --Inset-Bottom:0px;  --Inset-Left:0px; --Inset-Right:0px;}

 
/*  Transform  */
.Transform {width:100%; height:100%; display:flex; align-items: center; justify-content:center; transition-property: transform; --Device-Turn-Speed:0.5s; transition-duration:var(--Device-Turn-Speed); --Device-Fit-Scale:90; --Device-Fit-Transform:1;}


/* No Device */
#App.Simulate.No-Device .Transform #Template{box-shadow: var(--Panel-Shadow);}

/* Landscape Simulator Views */

/* Portrait Devices Rotated To Landscape - Scale To Width */
#App.Simulate.View-Display-Landscape.iPhoneXS.Rotation-L .Transform,
#App.Simulate.View-Display-Landscape.iPhoneXS.Rotation-R .Transform,
#App.Simulate.View-Display-Landscape.iPhoneSE.Rotation-L .Transform,
#App.Simulate.View-Display-Landscape.iPhoneSE.Rotation-R .Transform {--Device-Fit-Transform:calc( ( var(--Offset-Width) * var(--Device-Fit-Scale) ) / ( var(--Bezel-Image-Height) * 100 ) );}
/* Landscape Devices - Scale To Width */
#App.Simulate.View-Display-Landscape.iPad11Inch.Rotation-None .Transform,
#App.Simulate.View-Display-Landscape.MacBookAir .Transform {--Device-Fit-Transform:calc( ( var(--Offset-Width) * var(--Device-Fit-Scale) ) / ( var(--Bezel-Image-Width) * 100 ) );}
/* Portrait Devices - Scale To Height */
#App.Simulate.View-Display-Landscape.iPhoneXS.Rotation-None .Transform,
#App.Simulate.View-Display-Landscape.iPhoneSE.Rotation-None .Transform {--Device-Fit-Transform:calc( ( var(--Offset-Height) * var(--Device-Fit-Scale) ) / ( var(--Bezel-Image-Height) * 100 ) );}
/* Landscape Devices Rotated To Portrait - Scale To Height */
#App.Simulate.View-Display-Landscape.iPad11Inch.Rotation-L .Transform,
#App.Simulate.View-Display-Landscape.iPad11Inch.Rotation-R .Transform {--Device-Fit-Transform:calc( ( var(--Offset-Height) * var(--Device-Fit-Scale) ) / ( var(--Bezel-Image-Height) * 100 ) );}

/* Portrait Simulator Views */

/* Portrait Devices Rotated To Landscape - Scale To Width */
#App.Simulate.View-Display-Portrait.iPhoneXS.Rotation-L .Transform,
#App.Simulate.View-Display-Portrait.iPhoneXS.Rotation-R .Transform,
#App.Simulate.View-Display-Portrait.iPhoneSE.Rotation-L .Transform,
#App.Simulate.View-Display-Portrait.iPhoneSE.Rotation-R .Transform {--Device-Fit-Transform:calc( ( var(--Offset-Width) * var(--Device-Fit-Scale) ) / ( var(--Bezel-Image-Height) * 100 ) );}
/* Landscape Devices - Scale To Width */
#App.Simulate.View-Display-Portrait.iPad11Inch.Rotation-None .Transform,
#App.Simulate.View-Display-Portrait.MacBookAir .Transform {--Device-Fit-Transform:calc( ( var(--Offset-Width) * var(--Device-Fit-Scale) ) / ( var(--Bezel-Image-Width) * 100 ) );}
/* Portrait Devices - Scale To Height */
#App.Simulate.View-Display-Portrait.iPhoneXS.Rotation-None .Transform,
#App.Simulate.View-Display-Portrait.iPhoneSE.Rotation-None .Transform {--Device-Fit-Transform:calc( ( var(--Offset-Height) * var(--Device-Fit-Scale) ) / ( var(--Bezel-Image-Height) * 100 ) );}
/* Landscape Devices Rotated To Portrait - Scale To Height */
#App.Simulate.View-Display-Portrait.iPad11Inch.Rotation-L .Transform,
#App.Simulate.View-Display-Portrait.iPad11Inch.Rotation-R .Transform {--Device-Fit-Transform:calc( ( var(--Offset-Height) * var(--Device-Fit-Scale) ) / ( var(--Bezel-Image-Width) * 100 ) );}


#App.Simulate.Rotation-None .Transform {transform:scale( var(--Device-Fit-Transform) ) rotate(0deg);}
#App.Simulate.Rotation-L .Transform {transform:scale( var(--Device-Fit-Transform) ) rotate( calc( var(--Rotate-Increment,0deg) * -1 ) );}
#App.Simulate.Rotation-R .Transform {transform:scale( var(--Device-Fit-Transform) ) rotate( calc( var(--Rotate-Increment,0deg) * 1 ) );}


/* Template */
#App #Template {position:absolute; z-index:1; border-radius:var(--Screen-Radius); overflow:hidden;}
#App #Template {width:calc( var(--Screen-Width) * 1px ); height:calc( var(--Screen-Height) * 1px ); transform:scale( var(--Template-Scale,1) ) rotate(0deg);}
#App.Simulate:not(.No-Device):not(.MacBookAir).Rotation-L #Template {width:calc( var(--Screen-Height) * 1px ); height:calc( var(--Screen-Width) * 1px ); transform:scale( var(--Template-Scale,1) ) rotate( calc( var(--Rotate-Increment,0deg) * 1 ) );}
#App.Simulate:not(.No-Device):not(.MacBookAir).Rotation-R #Template {width:calc( var(--Screen-Height) * 1px) ; height:calc( var(--Screen-Width) * 1px ); transform:scale( var(--Template-Scale,1) ) rotate( calc( var(--Rotate-Increment,0deg) * -1 ) );}
#App.No-Device #Template {width:100%; height:100%;}


/* Overlay */
#App .Overlay {position:absolute; z-index:2; border-radius:var(--Screen-Radius); overflow:hidden;}
#App .Overlay {width:calc( var(--Screen-Width) * 1px ); height:calc( var(--Screen-Height) * 1px ); transform:rotate(0deg);}
#App.Simulate:not(.MacBookAir).Rotation-L .Overlay {width:calc( var(--Screen-Height) * 1px ); height:calc( var(--Screen-Width) * 1px ); transform:rotate( calc( var(--Rotate-Increment,0deg) * 1 ) );}
#App.Simulate:not(.MacBookAir).Rotation-R .Overlay {width:calc( var(--Screen-Height) * 1px) ; height:calc( var(--Screen-Width) * 1px ); transform:rotate( calc( var(--Rotate-Increment,0deg) * -1 ) );}
#App.No-Device .Overlay {width:100%; height:100%;}


/* Bezel */
#App #Device {position:absolute; z-index:3; pointer-events:none; width:calc( var(--Bezel-Image-Width) * 1px); height:calc( var(--Bezel-Image-Height) * 1px); background:var(--Bezel-Image); transition-duration:var(--Device-Turn-Speed); transition-property: transform,opacity;}
#App.Hide-Bezel #Device {opacity:0;}

/* Bezel Shadow */
#App #Device-Shadow {position:absolute; z-index:0; pointer-events:none; width:calc( ( var(--Bezel-Image-Width) - var(--Bezel-Shadow-Inset) ) * 1px ); height:calc( ( var(--Bezel-Image-Height) - var(--Bezel-Shadow-Inset)  ) * 1px ); border-radius:var(--Bezel-Shadow-Radius); box-shadow:var(--Bezel-Shadow); background:var(--Bezel-Background); transition-duration:var(--Device-Turn-Speed); transition-property: transform,opacity;}
#App.Rotation-None #Device-Shadow {transform:translateX(0px) translateY(10px);}
#App.Rotation-L #Device-Shadow {transform:translateX(-10px) translateY(0px);}
#App.Rotation-R #Device-Shadow {transform:translateX(10px) translateY(0px);}

