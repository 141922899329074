
.menupopover .content .menuitem {
	height: 45px;
	max-height: 45px;
	background: #fff;
	display: flex;
	margin: 1px 12px;
}
.menupopover .content .menuitem:active {
    background: #ccc;
}

.menupopover .content .menuitem:first-child {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.menupopover .content .menuitem:last-child {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	margin-bottom:12px;
}

.menupopover .content .menuitem .title {
	flex:1; display:flex;align-items:center; padding-left: 15px;
	font-family: Lato;
	font-size: 14px;
}
.menupopover .content .menuitem .icon {
	flex:1;
	max-width: 45px;
	display:flex;justify-content:center;align-items:center;
}

.menupopover .content .menuitem .icon * {
	max-width: 50%;
	max-height: 50%;
}


.menupopover .heading {
	display: flex;
}

.menupopover .heading .title {
	flex:1; display:flex;align-items:center; padding-left: 15px; height: 40px;
	font-family: Lato;
	font-size: 14px;
}

.menupopover .heading .close {
	flex:1;
	max-width: 45px;
	display:flex;justify-content:center;align-items:center;
}

.menupopover .heading .close * { max-width: 20px; max-height: 20px; }
.menupopover .heading .close svg { fill:#aaa; }
