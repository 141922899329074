
.image-block {
     min-height:100px; display: grid;
     grid-template-columns: [main-start] 1fr [main-end];
     grid-template-rows: [main-start] 1fr [main-end];

}
.image-block .placeholder {
    grid-area: main;
    min-height:100px; display: flex; justify-content:center;align-items:center;
}
.image-block .placeholder .content {  display:flex; }
.image-block .placeholder .content .icon { margin-right:5px; }
.image-block .placeholder .content .icon svg { width:20px;}

.image-block .image {
    grid-area: main;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-block .image img {
    height: 100%;
    max-width: 80%;
    width: 80%;
}