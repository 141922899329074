/* Visual tweaks to Buy overlay. */
.Overlay-1 .Field-L1, .Pay-F1 .Field-L1 { padding: 5px; }
.Overlay-1 .Field-L1 [data-field="Field"], .Pay-F1 .Field-L1 [data-field="Field"] {
    border: 1px solid #aaa; padding: 10px;
}

.ProductVariant-L1 { display:flex; padding:5px; align-items:center; }
.ProductVariant-L1 > .Operator-Check { order:0; min-width:40px; max-width: 40px; padding:10px; }
.ProductVariant-L1 > [data-field="Title"] { flex:1; order:1; display:block;  }
.ProductVariant-L1 > [data-field="Price"] { flex:1; order:2; max-width:80px; display:block; }

.Print-Receipt-Preview {
    transform: scaleY(1.3);
    transform-origin: top;
    margin:20px;
    padding:3ch;
    box-shadow:0px 10px 20px rgba(0,0,0,0.2);
    max-width:48ch;
    min-width:48ch;
    background: linear-gradient(0deg, rgba(232,232,232,1) 0%, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 90%, rgba(232,232,232,1) 100%);
    font-family: "Courier New", Courier; font-size: 12px;
    box-sizing: content-box;
}

.Print-Receipt-Text {
white-space: break-spaces; font-family: "Courier New", Courier; font-size: 12px;
}
.Print-Receipt-Text-Double {
white-space: break-spaces; font-family: "Courier New", Courier; font-size: 24px;
}

/* New "Purchase Variant" type: */
._PurchaseVariant-L1, ._purchasevariant-L1 {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "check title price"
    ". hint hint";
}
._PurchaseVariant-L1 > .Operator-Check, ._purchasevariant-L1 > .Operator-Check  { grid-area: check; min-width:40px; max-width: 40px; padding:10px; }
._PurchaseVariant-L1 > [data-field="Title"], ._purchasevariant-L1 > [data-field="Title"] { grid-area: title; display:block;  }
._PurchaseVariant-L1 > [data-field="Price"], ._purchasevariant-L1 > [data-field="Price"] { grid-area: price; max-width:80px; display:block; }
._PurchaseVariant-L1 > [data-field="Hint"], ._purchasevariant-L1 > [data-field="Hint"] { grid-area: hint; display:block; font: var(--Caption);
  color: var(--Surface-Secondary); }

/* "Purchase Variant" form: */
._PurchaseVariant-F1 {
    display: grid;
    grid-template-columns: 120px minmax(120px, 1fr) 70px auto auto;
    grid-template-rows: auto auto;
    gap: 4px 4px;
    grid-template-areas:
      "picture title price menu status"
      "other other other other other";
    padding: 5px 10px;
    border-bottom: 2px dotted #ccc;
    margin-bottom: 10px;
  }
  ._PurchaseVariant-F1 > [data-field="Title"] { display:grid; grid-area: title; }
  ._PurchaseVariant-F1 > [data-field="Price"] { display:grid; grid-area: price; }
  ._PurchaseVariant-F1 > [data-field="Picture"] { display:grid; max-width:120px; grid-area: picture; }
  ._PurchaseVariant-F1 > .Operator-Menu { display:grid; grid-area: menu; }
  ._PurchaseVariant-F1 > .Import-Status { display:block; grid-area: status; max-width:80px;}
  ._PurchaseVariant-F1 > div { grid-column: 1 / span 5; }

  /*
@Name: Plan Extended Listing 1
@Summary: Shop listing above square picture post. Followed by title and description with right aligned buy button.
*/
._Plan-E1 {display:grid; grid-template-columns: [shop-start title-start picture-start description-start manage-start] 1fr [title-end buy-start] 170px [buy-end description-end picture-end title-end shop-end manage-end]; grid-template-rows:[shop-start] auto [shop-end picture-start] auto [picture-end title-start buy-start] 40px [buy-end title-end description-start] 1fr [description-end manage-start] auto [manage-end]; grid-gap: var(--Spacing-Regular) var(--Spacing-Narrow); border-bottom: var(--Divider-Narrow);}
._Plan-E1 > [data-field="Title"] {grid-area: title; display:flex; font: var(--Subheading); text-transform: var(--Subheading-Case); color: var(--Surface-Primary); align-items:center;}
._Plan-E1 > [data-field="Picture"] {--Picture-Aspect-Ratio:2/1; grid-area:picture; align-self: flex-start; display:block;}
._Plan-E1 > [data-field="Description"] {grid-area:description; display:block; font: var(--Footnote); color: var(--Surface-Secondary);}
._Plan-E1 > [data-field="Shop"] {grid-area:shop; display:block;}
._Plan-E1 > [data-field="Buy"] {grid-area:buy; display:block;}
._Plan-E1 > [data-field="Buy"] .Action {width:100%; height:100%; font:var(--Body);}
._Plan-E1 > [data-field="Buy"] .Action  > [data-field^="Label"] {font:var(--Body); text-transform:uppercase;}

._Plan-E1 > [data-field="Open"] {grid-area:buy; display:block;}
._Plan-E1 > [data-field="Open"] .Action {width:100%; height:100%; font:var(--Body);}
._Plan-E1 > [data-field="Open"] .Action  > [data-field^="Label"] {font:var(--Body); text-transform:uppercase;}

._Plan-E1 > [data-field="Manage"] {grid-area:manage; display:block;}
._Plan-E1 > [data-field="Manage"] .Action {width:100%; height:100%; font:var(--Body);}
._Plan-E1 > [data-field="Manage"] .Action  > [data-field^="Label"] {font:var(--Body); text-transform:uppercase;}

/** Note: field cluster button to be replaced with Action */
.Field-Group-1 {
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: auto auto auto auto;
  gap: 5px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "title acc"
    "hint acc"
    "icon acc"
    "status acc";
  padding: 10px;
  border-radius: 20px;
  background: rgb(239, 245, 246);
  stroke: var(--Active-Primary);
  fill: var(--Active-Primary);
}

.Field-Group-1 > .Title { grid-area: title; font:var(--Body); color: var(--Active-Primary); }
.Field-Group-1 > .Hint { grid-area: hint; font:var(--Caption); color: var(--Surface-Secondary); }
.Field-Group-1 > .Status { grid-area: status; font:var(--Caption); color: var(--Active-Primary); }
.Field-Group-1 > .Icon { grid-area: icon; }
.Field-Group-1 > .Accessory {
  grid-area: acc;
  justify-content: center;
  display: flex;
  align-items: center;
}
.Field-Group-1 > .Icon > * { max-width: 30px; max-height: 30px; }
.Field-Group-1 > .Accessory > * {
  max-width: 30px;
  max-height: 30px;
  width: 100%;
}
