.Block-Row {--Icon-Scale:0.7; direction:var(--Direction); display:grid; grid-gap:3px 0px;}
.Block-Row:after {background:var(--Scheme-Backdrop); border-radius:5px; margin:-4px;}
.Block-Row.Is-Selected:after {background:var(--Scheme-Backdrop-Selected);}

.Block-Row > .Block-Indicator { grid-area:indicator; padding-top: 6px; }
.Block-Row > .Block-Content { grid-area:content; }

.Block-Row > .Operator-Grab {grid-area:grab; display:grid; z-index:2; align-self:start; justify-self:center; position:relative;}
.Block-Row > .Operator-Check {grid-area:check; z-index:2; align-self:start; justify-self:center; position:relative;}
.Block-Row > .Operator-Check .Glyph svg {fill:var(--Scheme-Accent);}
.Block-Row > .Operator-Check .Glyph.Selected svg {fill:var(--Scheme-Accent-Selected);}

/* .Block-Row.Is-Responder { } */

.Block-Row {
    grid-template-columns: [focus-start highlight-start icon-start check-start] 30px [check-end icon-end] var(--Spacing-Narrow) [indicator-start] auto [indicator-end content-start] 1fr [content-end] var(--Spacing-Regular) [menu-start grab-start] 30px [grab-end menu-end highlight-end focus-end];
    grid-template-rows: [focus-start highlight-start icon-start check-start menu-start grab-start title-start audiences-start] auto [indicator-start content-start] 1fr [indicator-end content-end grab-end menu-end check-end icon-end highlight-end focus-end];
}

.Block-Row.Block-Type-h1 .marktextfield { font-weight: bold; font-size: larger; }
.Block-Row.Block-Type-h2 .marktextfield { font-weight: bold; }

.Block-Row.Block-Type-h1 .richtext { font-weight: bold; font-size: larger; }
.Block-Row.Block-Type-h2 .richtext { font-weight: bold; }


.Block-Row.Block-Type-uli.Indent-0 .Block-Indicator,
.Block-Row.Block-Type-oli.Indent-0 .Block-Indicator { width: 15px; }

.Block-Row.Block-Type-uli.Indent-1 .Block-Indicator,
.Block-Row.Block-Type-oli.Indent-1 .Block-Indicator { width: 35px; padding-left: 20px; }

.Block-Row.Block-Type-uli.Indent-2 .Block-Indicator,
.Block-Row.Block-Type-oli.Indent-2 .Block-Indicator { width: 55px; padding-left: 40px; }

.Block-Row.Block-Type-uli.Indent-3 .Block-Indicator,
.Block-Row.Block-Type-oli.Indent-3 .Block-Indicator { width: 75px; padding-left: 60px; }

.Block-Row.Indent-0 .Block-Indicator { width: 0px; }
.Block-Row.Indent-1 .Block-Indicator { width: 15px; }
.Block-Row.Indent-2 .Block-Indicator { width: 35px; }
.Block-Row.Indent-3 .Block-Indicator { width: 55px; }

.Block-Row.Block-Type-img .Block-Indicator { background: #e1dfdf; }
.Block-Row.Block-Type-img.Is-Responder .Block-Indicator { background: #fff; }
