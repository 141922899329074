/****** TEMP HACKS ********/

.carousel-layer {position: inherit; top:auto; bottom:auto; left:auto; right:auto;}
.datatoolbar {padding: 10px var(--Page-Gutter);}
.deck .card .card-panel .panel-title {padding: 20px 10px 15px var(--Page-Gutter);}
.typeselector {padding: 0 var(--Page-Gutter);}



/* Post Record Color Scheme Variants */
.Post-Record.Color-Scheme-1 {
  --Scheme-Backdrop:var(--Surface-Primary);
  --Scheme-Backdrop-Selected:var(--Surface-Primary);
  --Scheme-Accent:var(--Active-Primary);
  --Scheme-Accent-Selected:var(--Active-Primary);
  --Scheme-Surface:var(--Surface-Secondary);
  --Scheme-Surface-Selected:var(--Surface-Primary);
  --Highlight-Hovered:brightness(120%);
  --Highlight-Pressed:brightness(140%);
}
.Post-Record.Color-Scheme-2 {
  --Scheme-Backdrop:var(--Background-Secondary);
  --Scheme-Backdrop-Selected:var(--Active-Primary);
  --Scheme-Accent:var(--Active-Primary);
  --Scheme-Accent-Selected:var(--Active-Primary);
  --Scheme-Surface:var(--Surface-Primary);
  --Scheme-Surface-Selected:var(--Active-Primary);
  --Highlight-Hovered:brightness(95%);
  --Highlight-Pressed:brightness(80%);
}

.Post-Record.Color-Scheme-3 {
  --Scheme-Backdrop:var(--Background-Primary);
  --Scheme-Backdrop-Selected:var(--Background-Secondary);
  --Scheme-Accent:var(--Active-Primary);
  --Scheme-Accent-Selected:var(--Active-Primary);
  --Scheme-Surface:var(--Surface-Primary);
  --Scheme-Surface-Selected:var(--Surface-Primary);
  --Highlight-Hovered:brightness(90%);
  --Highlight-Pressed:brightness(80%);
}

.Post-Record.Color-Scheme-4 {
  --Scheme-Backdrop:var(--Background-Primary);
  --Scheme-Backdrop-Selected:var(--Background-Primary);
  --Scheme-Accent:var(--Active-Primary);
  --Scheme-Accent-Selected:var(--Active-Primary);
  --Scheme-Surface:var(--Surface-Secondary);
  --Scheme-Surface-Selected:var(--Surface-Secondary);
  --Highlight-Hovered:brightness(95%);
  --Highlight-Pressed:brightness(85%);
}

/* ----- Post-Record Properties ----- */
.Post-Record {--Glyph-Scale:1;}

/* ----- Post-Record L1 ----- */
/*
@Name:Post Record L1
@Description: Short height toolbar style lozenge icon above text label. Exapnds at wider sizes to full width lozenge shape with icon and label horizontally arranged.
*/
.Post-Record-L1 {--Glyph-Scale:0.7; --Highlight-Radius: 5px; --Highlight-Margin:-3px; --Focus-Margin:-3px; --Focus-Radius:5px; direction:var(--Direction); display:grid; grid-gap:3px 0px;}
.Post-Record-L1:after {background:var(--Scheme-Backdrop);}
.Post-Record-L1.Is-Selected:after {background:var(--Scheme-Backdrop-Selected);}
.Post-Record-L1 > [data-field="Title"] {grid-area:title; display:block; font:var(--Body); color:var(--Surface-Primary); align-self:center; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow-y: hidden; overflow-x:hidden; white-space: nowrap; text-overflow: ellipsis; }
.Post-Record-L1 > [data-field="Type"] {grid-area:icon; --Glyph-Scale:0.8; display:block; align-self:start; justify-self:center; color:var(--Surface-Primary); transition-duration:var(--Slow); transform: translateX(-3px);}
.Post-Record-L1 > [data-field="Type"] svg {fill:var(--Surface-Primary);}
.Is-Checking .Post-Record-L1 > [data-field="Type"] > svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) ); opacity:0;}
.Post-Record-L1 > [data-field="Details"] {grid-area:details; display:flex; font:var(--Caption); color:var(--Surface-Secondary); align-self:center;}
.Post-Record-L1 > [data-field="Subtitle"] {grid-area:subtitle; display:flex; font:var(--Caption); color:var(--Surface-Secondary);}
.Post-Record-L1 > [data-field="Audiences"] {grid-area:audiences; align-self: end; display:block; font:var(--Footnote); color:var(--Surface-Secondary); min-width:100px;}
.Post-Record-L1 > .Operator-Menu {grid-area:menu; display:grid; z-index:2; align-self:start; justify-self:center; position:relative;}
.Post-Record-L1 > .Operator-Grab {grid-area:grab; display:grid; z-index:2; align-self:start; justify-self:center; position:relative;}
.Post-Record-L1 > .Operator-Check {grid-area:check; z-index:2; align-self:start; justify-self:center; position:relative;}
.Post-Record-L1 > .Operator-Check .Glyph svg {fill:var(--Scheme-Accent);}
.Post-Record-L1 > .Operator-Check .Glyph.Selected svg {fill:var(--Scheme-Accent-Selected);}


/* XS Action L1 */

/* SM Action L1 */
.SM.Post-Record-L1 {grid-template-columns:[focus-start highlight-start icon-start check-start] 25px [check-end icon-end] var(--Spacing-Narrow) [title-start audiences-start details-start subtitle-start] 1fr [title-end details-end audiences-end subtitle-end] var(--Spacing-Regular) [menu-start grab-start] 25px [grab-end menu-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start icon-start check-start menu-start grab-start title-start] auto [title-end icon-end details-start] auto [details-end subtitle-start] auto [subtitle-end audiences-start] 1fr [audiences-end grab-end menu-end check-end highlight-end focus-end];}


/* MD Action L1 (default) */
.MD.Post-Record-L1, .Post-Record-L1 {grid-template-columns:[focus-start highlight-start icon-start check-start] 25px [check-end icon-end] var(--Spacing-Narrow) [title-start audiences-start subtitle-start] 1fr [title-end] var(--Spacing-Narrow) [details-start] auto [details-end audiences-end subtitle-end] var(--Spacing-Regular) [menu-start grab-start] 25px [grab-end menu-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start icon-start check-start menu-start grab-start title-start details-start] auto [title-end icon-end details-end audiences-start] 1fr [audiences-end subtitle-start] auto [subtitle-end grab-end menu-end check-end highlight-end focus-end];}




/* LG Action L1 */

/* XL Action L1 */
