/*
@Name: Search 1
@Summary: Rounded search field with inset clear button. Submit button appears when keyboard navigating.
*/
.Search-L1 {display:grid; grid-template-columns:[input-start] 1fr [reset-start] 25px [reset-end input-end submit-start] max-content [submit-end]; grid-template-rows:[input-start reset-start submit-start] auto [submit-end reset-end input-end];}
.Search-L1 [type="submit"].Action {clip:rect(0 0 0 0); position:absolute; margin-left:var(--Spacing-Narrow);}
.Is-Keyboard-Navigating .Search-L1 [type="submit"].Action {clip:auto; position:relative;}
.Search-L1 [type="reset"].Action {grid-area:reset; z-index:2; align-self:center; transition-duration: var(--Nominal);}
.Search-L1 > .Field {grid-area:input;}
.Search-L1 > .Field > * {font:var(--Caption);}
.Search-L1 > .Field ~ [type=reset] {opacity:1; transform:scale(0.5);}
.Search-L1 > .Field.Is-Placeholder ~ [type=reset] {opacity:0;}



/*
@Name: Search 2
@Summary: Rounded search field with inset clear button, submit button visible to right.
*/
.Search-L2 {display:grid; grid-template-columns:[input-start] 1fr [reset-start] 30px [reset-end input-end submit-start] max-content [submit-end]; grid-template-rows:[input-start reset-start submit-start] auto [submit-end reset-end input-end];}
.Search-L2 [type="submit"].Action {clip:auto; margin-left:var(--Spacing-Narrow);}
.Search-L2 [type="reset"].Action {grid-area:reset; z-index:2; align-self:center;}
.Search-L2 > .Field {grid-area:input;}
