
/** Styles within the Panel's bar-tools container */
.bar-tools .record-share-button {
    margin-top: 10px;
}

.record-share-button {
    display: inline-block;
    background: #eee;
    color: #000;
    font-family: Lato, Arial;
    font-size: 12px;
    padding: 8px 20px;
    border-radius: 35px;
    cursor: pointer;
}


.record-share-label { padding: 1px 1px 2px 10px;
    font-size: 14px;
    font-weight: bold;
    color: #4a4a4a;
    font-family: Lato, Arial; }

.record-share-hint { padding: 2px 10px 4px 10px;
    font-size: 14px;
    color: #999;
    font-family: Lato; }

.record-share-defaccess { font-family: Lato;
    padding: 12px 10px;
    color: #666;
    padding-top: 0px; }