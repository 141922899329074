.Field-L0:before { display: none; }
.Field-L0 > [data-field="Label"] { display: none; }
.Field-L0 > [data-field="Field"] { display: block; }

p.Hint {
    padding: 5px var(--Page-Gutter);
    color: var(--Surface-Secondary);
    font: var(--Footnote);
}

/*** Import Status ***/
.Post-Record-L1 .Import-Status {
    grid-area: details;
    background: #666;
    color: #fff;
    padding: 5px;
    font: 14px Arial;
}
.Post-Record-L1 .Import-Status-New { background:#5c7ad5; }
.Post-Record-L1 .Import-Status-Updated { background:#d55c73; }
.Post-Record-L1 .Import-Status-Reordered { background:#666; }

.List-Dropdown {
    box-shadow: 0 4px 10px #e8e8e8;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    --Page-Gutter: 0;
}
.List-Dropdown > .Is-Spinner {
    margin:20px;
}
/*.List-Separated > div > ul > li {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 8px;
}
.List-Separated > div > ul > li:last-child {
    border-bottom: 0;
}*/
.List-Separated-Outer > div > ul > li:first-child {
    border-top: 1px solid #e8e8e8;
}
.List-Separated-Outer > div > ul > li:last-child {
    border-bottom: 1px solid #e8e8e8;
}
.List-Separated > div > ul > li:before {
    grid-area: highlight;
    content: "";
    transform: translateY(7px);
    background: linear-gradient(0deg, rgb(235, 235, 235) 0%,rgb(235, 235, 235) 1px,rgb(255, 255, 255) 1px,rgb(255, 255, 255) 100%);
}
.List-Separated > div > ul > li:last-child:before {
    display: none;
}
.Collection-Cursor {
    align-items: center; display: flex; justify-content: center;grid-column: span var(--Number-Of-Columns);
}
.Placeholder-Post {
    padding: var(--Spacing-Narrow) var(--Page-Gutter);
    font:var(--Caption);
    color: var(--Surface-Primary);
}
.Tools-Compact {
    display: flex;
    gap: 10px;
}
.Tools-Compact form {
    flex: 1;
}

.Import-Status {
    background: #666;
    color: #fff;
    padding: 5px;
    font: 14px Arial;
}

.Import-Status-New { background:#5c7ad5; }
.Import-Status-Updated { background:#d55c73; }
.Import-Status-Changes { background:#d5885c; }
.Import-Status-Reordered { background:#666; }

.edit-contents > .Import-Status { display: block; }

/* Basic form with all data fields visible and some spacing. */
.Basic-F1 { font: var(--Body); color: var(--Surface-Primary); }
.Basic-F1 > [data-field] { display: grid; }
.Basic-F1 > * { margin: var(--Spacing-Regular) var(--Page-Gutter); }
.Basic-F1 > p { font:var(--Byline); }
.Basic-F1 > .collection { margin: 0; }
.Basic-F1 > .No-Basic-Gutter { margin: var(--Spacing-Regular) 0; }

.Text-Body { font: var(--Body); color: var(--Surface-Primary); }
.Text-Body h1 { font: var(--Heading); }
.Text-Body h2 { font: var(--Subheading); }

.PG0 { --Page-Gutter: 0; }
.PG7 { --Page-Gutter: 7px; }
.Header-L2.PG0 + p.Hint { --Page-Gutter: 0; }

/** Field-Form display */
.Field-Form > .Card > .Field-L1,
.Field-Form > .Field-L1,
.Field-Form > .Card > [data-format="action"],
.Field-Form > [data-format="action"] { margin: var(--Spacing-Wide) var(--Page-Gutter); }
.Field-Form > .Card > .Field-L7,
.Field-Form > .Field-L7 { margin: var(--Spacing-Narrow) var(--Page-Gutter); }

/** Counteracting [data-field] { display:none; } */
.Field-Form > .Card > .collection,
.Field-Form > .collection,
.Field-Form > .Card > [data-format="action"],
.Field-Form > [data-format="action"] { display: block; }
