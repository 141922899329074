
.Is-Spinner svg { height:50px; width:50px; animation: spin 3s linear 0s infinite normal both running; fill:rgba(128,128,128,0.3); }
.Is-Spinner.Centered { display:flex; align-items:center; justify-content:center; height:100%;  }

/** Prevent from displaying more than 1 spinner when collections loading on after another */
.collection.Is-Loading + .collection.Is-Loading > .Is-Spinner { display: none; }


.Notification.Notification-Updating { animation: scale-fade-in var(--Slow,0.2s) ease 0s 1 normal both running; }
.Notification.Notification-Settled { animation: none; }

/** Hide badge when notifications are empty */
.Action [data-field="Badge"]:not([data-notifications]) {display:block; opacity:0; transform:scale(0.5);}

/** Adjust card padding to include notification bar height. */
section .Card { padding-bottom: var(--Notify-Bar-Height); }
