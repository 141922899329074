/* Form Color Scheme Variants */

.Field.Color-Scheme-1 {
  --Field-Backdrop:var(--Background-Primary);
  --Field-Border:1px solid var(--Background-Secondary);
  --Overlay-Backdrop:var(--Overlay-Secondary);
  --Field-Label:var(--Surface-Secondary);
  --Field-Content:var(--Surface-Primary);
  --Field-Hint:var(--Surface-Secondary);
  --Highlight-Hovered:brightness(90%);
  --Highlight-Pressed:brightness(80%);
}

.Field.Color-Scheme-2 {
  --Field-Backdrop:var(--Background-Primary);
  --Field-Border:1px solid var(--Surface-Secondary);
  --Overlay-Backdrop:var(--Overlay-Secondary);
  --Field-Label:var(--Surface-Primary);
  --Field-Content:var(--Surface-Primary);
  --Field-Hint:var(--Surface-Secondary);
  --Highlight-Hovered:brightness(90%);
  --Highlight-Pressed:brightness(80%);
}

/* ----- Field Variations ----- */
.Field {--Glyph-Scale:1;}
.Field.Is-Invalid > [data-field^="Hint"]:not(:empty) {background:var(--Alert); padding:0 7px 0; border-radius:1000px; color:var(--Alert-Overlay);}
.Field [data-field^="Hint"] a.Info {display: inline-block; text-decoration:none; border:var(--Divider-Regular); border-radius:50px; padding:0px 3.5px 3px 3px; font-size:15px; color:var(--Surface-Secondary); position:relative; top:-1px; line-height:13px; margin:-0 0 -10px 5px;}

/* ----- Code Styling ----- */
.Field > [data-field^="Field"] .Code {font:var(--Monospace);}

/* ----- Font Setup ----- */
.Field > [data-field="Font"]:before {content:"AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz"; white-space: nowrap;}
.Field > [data-field="Font"].Typeface-1 {font:80px/80px "Typeface 1";}
.Field > [data-field="Font"].Typeface-2 {font:80px/80px "Typeface 2";}
.Field > [data-field="Font"].Typeface-3 {font:80px/80px "Typeface 3";}

/* ----- Badging ----- */
.Field [data-field^="Badge"] {grid-area:highlight; align-self: start; justify-self:end; z-index:2; display:flex; background:var(--Badge-Background); color:var(--Badge-Overlay); pointer-events: none; font:var(--Footnote); border-radius: 1000px; justify-content: center; align-items: center; padding:3px 6px 2px 6px; position: relative; left:5px; top:-5px; box-shadow:var(--Badge-Shadow); min-width:11px; min-height:11px; opacity:1; transform:scale(1); transition-duration:var(--Very-Slow); transition-timing-function:cubic-bezier(0.13, 2.36, 0.48, 0.74); white-space: nowrap;}
.Field [data-field^="Badge"][data-notifications="0"] {opacity:0; transform:scale(0.5);}
.Field [data-field^="Badge"]:before {content:attr(data-notifications);}
.Field [data-field^="Badge"] span {display:none;}

/* ----- Field L1 ----- */
/*
@Name:Field L1
@Description: Label and Hint above Editable Text Field.
*/
.Field-L1 {direction:var(--Direction); --Highlight-Radius: 5px; --Highlight-Margin: -5px; --Focus-Margin:-1px -5px -1px; --Focus-Radius:5px; display:grid; grid-gap:var(--Spacing-Narrow) var(--Spacing-Regular); grid-template-columns: [focus-start highlight-start field-start label-start] fit-content(50%) [label-end hint-start] 1fr [hint-end hintaction-start] auto [hintaction-end field-end highlight-end focus-end]; grid-template-rows:[label-start hint-start hintaction-start] auto [hint-end hintaction-end label-end focus-start highlight-start field-start] 1fr [field-end highlight-end focus-end];}
.Field-L1:after {border:none;}
.Field-L1 > [data-field^="Label"] {grid-area:label; display:block; color:var(--Field-Label); font:var(--Caption); align-self:end; justify-self:start;}
.Field-L1 > [data-field^="Field"] {grid-area:field; display:flex; justify-self:stretch; align-items: stretch;  align-self:center; justify-self:stretch; text-align: start; background:var(--Field-Backdrop); border-radius:var(--Highlight-Radius);}
.Field-L1 > [data-field^="Field"] > * {display:block; font:var(--Body); color:var(--Field-Content); width:100%; border:0; background:transparent;}

.Field-L1.Is-Placeholder > [data-field^="Field"] {color:var(--Field-Hint);}
.Field-L1 > [data-field^="Hint"] {grid-area:hint; display:block; color:var(--Field-Hint); font:var(--Footnote); align-self:end; justify-self:start; }
.Field-L1 > [data-field="Hint Action"] {grid-area:hintaction;}

/* ----- Field L2 ----- */
/*
@Name:Field L2
@Description: Label and Hint above Editable Text Field. Text field has border and padding.
*/
.Field-L2 {direction:var(--Direction); --Highlight-Radius: 5px; --Highlight-Margin: 0px; --Focus-Margin:0px; --Focus-Radius:5px; display:grid; grid-gap:var(--Spacing-Narrow) var(--Spacing-Regular); grid-template-columns: [focus-start highlight-start field-start label-start] fit-content(50%) [label-end hint-start] 1fr [hint-end field-end highlight-end focus-end]; grid-template-rows:[label-start hint-start] auto [hint-end label-end focus-start highlight-start field-start] 1fr [field-end highlight-end focus-end];}
.Field-L2:after {border:var(--Field-Border); z-index:1;}
.Field-L2 > [data-field^="Label"] {grid-area:label; display:block; color:var(--Field-Label); font:var(--Caption); align-self:end; justify-self:start;}
.Field-L2 > [data-field^="Field"] {grid-area:field; display:flex; justify-self:stretch; align-items: stretch;  color:var(--Field-Content); font:var(--Body); align-self:center; justify-self:stretch;  text-align: start; background:var(--Field-Backdrop); border-radius:var(--Highlight-Radius);}
.Field-L2 > [data-field^="Field"] > * {display:block; padding:var(--Spacing-Narrow) var(--Spacing-Regular); font:var(--Body); color:var(--Field-Content); width:100%; border:0; background:transparent;}
.Field-L2.Is-Placeholder > [data-field^="Field"] {color:var(--Field-Hint);}
.Field-L2 > [data-field^="Hint"] {grid-area:hint; display:block; color:var(--Field-Hint); font:var(--Footnote); align-self:end; justify-self:start; }


/* ----- Field L3 ----- */
/*
@Name:Field L3
@Description: Label and Hint above Full Bleed Picture and Filename over middle.
*/
.Field-L3 {direction:var(--Direction); --Highlight-Radius: 5px; --Highlight-Margin: -5px; --Focus-Margin:0px; --Focus-Radius:5px; display:grid; grid-gap:var(--Spacing-Narrow) var(--Spacing-Regular); grid-template-columns: [focus-start highlight-start picture-start field-start label-start] fit-content(50%) [label-end hint-start] 1fr [hint-end field-end picture-end highlight-end focus-end]; grid-template-rows:[label-start hint-start] auto [hint-end label-end focus-start highlight-start picture-start field-start] 1fr [field-end picture-end highlight-end focus-end];}
.Field-L3:after {border:1px solid var(--Field-Border);}
.Field-L3 > [data-field^="Label"] {grid-area:label; display:block; color:var(--Field-Label); font:var(--Caption); align-self:end; justify-self:start;}
.Field-L3 > [data-field^="Field"] {grid-area:field; padding:var(--Spacing-Narrow) var(--Spacing-Regular); display:block; color:var(--Field-Content); font:var(--Body); align-self:center; justify-self:center; text-align: start; background:var(--Overlay-Backdrop); border-radius:1000px; width: 60%; white-space: nowrap; text-overflow: ellipsis; overflow:hidden;}
.Field-L3.Is-Placeholder > [data-field^="Field"] {color:var(--Field-Hint);}
.Field-L3 > [data-field^="Hint"] {grid-area:hint; display:block; color:var(--Field-Hint); font:var(--Footnote); align-self:end; justify-self:start; border:1px solid var(--Field-Surface);}
.Field-L3 > [data-field="Picture"] {grid-area:picture; z-index:0; --Picture-Aspect-Ratio:4/1; display:block;}
.Field-L3 > [data-field="Font"] {grid-area:picture; z-index:0; min-height:80px; display:block; overflow-y:hidden;}


/* ----- Field L4 ----- */
/*
@Name:Field L4
@Description: No Label or Hint just rounded Text Field. Suitable for Search fields.
*/
.Field-L4 {direction:var(--Direction); --Icon-Indent:30px; --Highlight-Radius:1000px; --Highlight-Margin: 0px; --Focus-Margin:0px; --Focus-Radius:1000px; display:grid; grid-gap:var(--Spacing-Narrow) 0; grid-template-columns: [focus-start highlight-start field-start icon-start] var(--Icon-Indent) [icon-end] 1fr [field-end highlight-end focus-end]; grid-template-rows:[focus-start highlight-start icon-start field-start] 1fr [field-end icon-end highlight-end focus-end];}
.Field-L4:after {border:var(--Field-Border); z-index:1;}
.Field-L4 > .Glyph {display:block; max-width:15px; align-self:center; justify-self:center; grid-area:icon; pointer-events:none; z-index:2; margin-left:var(--Spacing-Narrow);}
.Field-L4 > .Glyph svg {fill:var(--Field-Content);}
.Field-L4 > [data-field^="Field"] {grid-area:field; align-self:center; display:flex; justify-self:stretch; align-items: stretch; justify-content: stretch; text-align: start; background:var(--Field-Backdrop); border-radius:var(--Highlight-Radius);}
.Field-L4 > [data-field^="Field"] > * {display:block; padding:var(--Spacing-Narrow) var(--Spacing-Regular) var(--Spacing-Narrow) var(--Icon-Indent); font:var(--Body); color:var(--Field-Content); width:calc(100% - ( var(--Spacing-Regular) * 2) ); border:0; background:transparent;}
.Field-L4 > [data-field^="Label"],
.Field-L4 > [data-field^="Hint"] {clip:rect(0 0 0 0); height:0; margin:-1px; overflow:hidden; position:absolute; width:0;}

.Field-L4.Is-Placeholder > [data-field^="Field"] {color:var(--Field-Hint);}


/* ----- Field L5 ----- */
/*
@Name:Field L5
@Description: Label and Hint above Editable Text Field with left aligned icon.
*/
.Field-L5 {direction:var(--Direction); --Icon-Indent:30px; --Highlight-Radius: 5px; --Highlight-Margin: 0px; --Focus-Margin:0px; --Focus-Radius:5px; display:grid; grid-gap:var(--Spacing-Narrow) var(--Spacing-Regular); grid-template-columns: [focus-start highlight-start label-start icon-start field-start] var(--Icon-Indent) [icon-end] max-content [label-end hint-start] 1fr [hint-end field-end highlight-end focus-end]; grid-template-rows:[label-start hint-start] auto [hint-end label-end focus-start highlight-start icon-start field-start] 1fr [field-end icon-end highlight-end focus-end];}
.Field-L5:after {border:var(--Field-Border); z-index:1;}
.Field-L5 > .Glyph {grid-area:icon; display:block; max-width:15px; align-self:center; justify-self:center; pointer-events:none; z-index:2;}
.Field-L5 > .Glyph svg {fill:var(--Field-Content);}
.Field-L5 > [data-field^="Label"] {grid-area:label; display:block; color:var(--Field-Label); font:var(--Caption); align-self:end; justify-self:start;}
.Field-L5 > [data-field^="Field"] {grid-area:field; display:flex; justify-self:stretch; align-items: stretch; color:var(--Field-Content); font:var(--Body); align-self:center; justify-self:stretch; text-align: start; background:var(--Field-Backdrop); border-radius:var(--Highlight-Radius);}
.Field-L5 > [data-field^="Field"] > * {display:block; padding:var(--Spacing-Narrow) var(--Spacing-Regular) var(--Spacing-Narrow) var(--Icon-Indent); font:var(--Body); color:var(--Field-Content); width:100%; border:0; background:transparent;}

.Field-L5.Is-Placeholder > [data-field^="Field"] {color:var(--Field-Hint);}
.Field-L5 > [data-field^="Hint"] {grid-area:hint; display:block; color:var(--Field-Hint); font:var(--Footnote); align-self:end; justify-self:start; }

/* ----- Field L6 ----- */
/*
@Name:Field L6
@Description: Label and Hint below Full Bleed Picture no filename.
*/
.Field-L6 {direction:var(--Direction); --Highlight-Radius: 5px; --Highlight-Margin: -5px; --Focus-Margin:0px; --Focus-Radius:5px; display:grid; grid-gap:var(--Spacing-Narrow) var(--Spacing-Regular); grid-template-columns:[focus-start highlight-start picture-start label-start hint-start] 1fr [label-end hint-end picture-end highlight-end focus-end]; grid-template-rows:[label-start] auto [label-end focus-start highlight-start picture-start] 1fr [picture-end highlight-end focus-end hint-start] auto [hint-end];}
.Field-L6 > [data-field^="Label"] {grid-area:label; display:block; color:var(--Field-Label); font:var(--Caption); align-self:end; justify-self:start;}
.Field-L6 > [data-field^="Hint"] {grid-area:hint; display:block; color:var(--Field-Hint); font:var(--Footnote); align-self:end; justify-self:start; border:1px solid var(--Field-Surface);}
.Field-L6 > [data-field="Picture"] {grid-area:picture; z-index:0; display:block;}


/* ----- Field L7 ----- */
/*
@Name:Field L7
@Description: Label and Hint above Editable Text Field.
*/
.Field-L7 {direction:var(--Direction); --Highlight-Radius: 5px; --Highlight-Margin: -5px; --Focus-Margin:-1px -5px -1px; --Focus-Radius:5px; display:grid; grid-gap:var(--Spacing-Narrow) var(--Spacing-Regular);
  grid-template-columns: [focus-start highlight-start hint-start label-start] 1fr [label-end field-start] 3fr [hint-end field-end hintaction-start] auto [hintaction-end highlight-end focus-end];
  grid-template-rows: [label-start field-start] auto [hint-start hintaction-start label-end focus-start highlight-start field-end] 1fr [field-end highlight-end focus-end hint-end hintaction-end];
}
.Field-L7:after {border:none;}
.Field-L7 > [data-field^="Label"] {grid-area:label; display:block; color:var(--Field-Label); font:var(--Caption); align-self:end; justify-self:start;}
.Field-L7 > [data-field^="Field"] {grid-area:field; display:flex; justify-self:stretch; align-items: stretch;  align-self:center; justify-self:stretch; text-align: start; background:var(--Field-Backdrop); border-radius:var(--Highlight-Radius);}
.Field-L7 > [data-field^="Field"] > * {display:block; font:var(--Body); color:var(--Field-Content); width:100%; border:0; background:transparent;}

.Field-L7.Is-Placeholder > [data-field^="Field"] {color:var(--Field-Hint);}
.Field-L7 > [data-field^="Hint"] {grid-area:hint; display:block; color:var(--Field-Hint); font:var(--Footnote); align-self:end; justify-self:start; }
.Field-L7 > [data-field="Hint Action"] {grid-area:hintaction;}



/* ********** Preview Devices *********** */
.Devices {position:relative; --Preview-Aspect-Ratio:3/1; padding-bottom:calc(100% / ( var( --Preview-Aspect-Ratio, 1 ) ) );}

.Scale-To-Fit { transform:scale( calc( var(--Offset-Width,1) / var(--Element-Width,1) ) ); transform-origin:0 0; margin:0 var(--Page-Gutter);}

.Device-Preview {position:absolute; left:0; top:0;  width:var(--Bezel-Image-Width); height:var(--Bezel-Image-Height); transform-origin: 0 0;}
.Device-Preview svg {position:absolute; z-index:2; left:0; top:0; width:100%; height:100%; pointer-events: none;}
.Device-Preview iframe {position:absolute; z-index:1; left:var(--Screen-Inset-X); top:var(--Screen-Inset-Y); width:var(--Screen-Width); height:var(--Screen-Height);}
.Device-Preview.iPhoneXS {--Scale-Relative:0.751; left:2522px; top:297px; transform:scale( var(--Scale-Relative) ); z-index:3;}
.Device-Preview.iPad11Inch {--Scale-Relative:0.814; left:1452px; top:186px; transform:scale( var(--Scale-Relative) ); z-index:2;}
.Device-Preview.MacBookAir {--Scale-Relative:1; left:0; top:0px; z-index:1;}


/* ********** TextButtonGroup *********** */

/*
@Name: TextButtonGroup L1
@Summary: Text data with an icon and up to two buttons below. Submit button appears when keyboard navigating.
*/
.TextButtonGroup-L1 {display:grid; grid-template-columns:[icon-start button-1-start] 30px [icon-end title-start] max-content [button-1-end button-2-start] auto [button-2-end button-3-start] auto [button-3-end] 1fr [title-end]; grid-template-rows:[icon-start title-start] auto [title-end icon-end button-1-start button-2-start button-3-start] auto [button-3-end button-2-end button-1-end]; grid-gap:var(--Spacing-Narrow) var(--Spacing-Regular); padding:0 var(--Page-Gutter);}
.TextButtonGroup-L1 [data-field^="Title"] {grid-area:title; font:var(--Subheading); color:var(--Surface-Primary); display:flex; align-items: center;
padding: var(--Spacing-Narrow) 0; position:relative; top:-2px;}
.TextButtonGroup-L1 [data-field^="Icon"] {grid-area:icon; display: block;}
.TextButtonGroup-L1 .Action {grid-area:button-1;}
.TextButtonGroup-L1 .Action + .Action {grid-area:button-2;}
.TextButtonGroup-L1 .Action + .Action + .Action {grid-area:button-3;}

/* LG Action L1 */
.LG.TextButtonGroup-L1 {display:grid; grid-template-columns:[icon-start] 30px [icon-end title-start] auto [title-end button-1-start] auto [button-1-end button-2-start] auto [button-2-end button-3-start] 1fr [button-3-end]; grid-template-rows:[icon-start title-start button-1-start button-2-start button-3-start] auto [button-3-end button-2-end button-1-end title-end icon-end]; align-items: center;}
/* XL Action L1 */
.XL.TextButtonGroup-L1 {display:grid; grid-template-columns:[icon-start] 30px [icon-end title-start] auto [title-end button-1-start] auto [button-1-end button-2-start] auto [button-2-end button-3-start] 1fr [button-3-end]; grid-template-rows:[icon-start title-start button-1-start button-2-start button-3-start] auto [button-3-end button-2-end button-1-end title-end icon-end]; align-items: center;}

/* ********** Stepper Control *********** */

/*
@Name: Stepper L1
@Summary: Plus and Minus buttons aside a text input.
*/
.Stepper-L1 {display:grid; grid-template-columns:[icon-start button-1-start] 30px [icon-end title-start] max-content [button-1-end button-2-start] auto [button-2-end button-3-start] auto [button-3-end] 1fr [title-end]; grid-template-rows:[icon-start title-start] auto [title-end icon-end button-1-start button-2-start button-3-start] auto [button-3-end button-2-end button-1-end]; grid-gap:var(--Spacing-Narrow) var(--Spacing-Regular); padding:0 var(--Page-Gutter);}
.Stepper-L1 [data-field^="Input"] {grid-area:title; font:var(--Subheading); color:var(--Surface-Primary); display:flex; align-items: center;
padding: var(--Spacing-Narrow) 0; position:relative; top:-2px;}
.Stepper-L1 [data-field^="Icon"] {grid-area:icon; display: block;}
.Stepper-L1 .Action {grid-area:minus-button;}
.Stepper-L1 .Action + .Action {grid-area:plus-button;}


/* ********** Address *********** */

/* Punctuation */
.Address [data-field^="Address"]:after,
.Address [data-field^="City"]:after,
.Address [data-field^="State"]:after {content:","; margin:0 var(--Spacing-Narrow) 0 0;}
.Address [data-field^="Country"]:after {margin:0 var(--Spacing-Narrow) 0 0;}

/*
@Name: Address L1
@Summary: Bold name line. With emphasis on horizontal render. Map image on right.
*/
.Address-L1 {display:grid; grid-template-columns: [name-start company-start address-start city-start state-start country-start] auto [country-end postcode-start] 1fr [postcode-end city-end state-end address-end company-end name-end] var(--Spacing-Regular) [map-start] 40% [map-end]; grid-template-rows:[map-start name-start] auto [name-end company-start] auto [company-end address-start] auto [address-end city-start] auto [city-end state-start] auto [state-end country-start postcode-start] 1fr [postcode-end country-end map-end]; padding:0 var(--Page-Gutter);}
.Address-L1.Alignment-RTL {grid-template-columns:[map-start] 40% [map-end] var(--Spacing-Regular) [name-start company-start address-start city-start state-start country-start] auto [country-end postcode-start] 1fr [postcode-end city-end state-end address-end company-end name-end];}
.Address-L1 [data-field^="Full Name"] {grid-area:name; display:flex; align-items:center; font:bold var(--Body); color:var(--Surface-Primary); padding:0 0 var(--Spacing-Narrow);}
.Address-L1 [data-field^="Company"] {grid-area:company; display:flex; align-items:center; font:var(--Body); color:var(--Surface-Secondary); padding:0 0 var(--Spacing-Narrow);}
.Address-L1 [data-field^="Address"] {grid-area:address; grid-row:address;  display:flex; align-items:center; font:var(--Body); color:var(--Surface-Secondary); padding:0 0 var(--Spacing-Narrow);}
.Address-L1 [data-field^="City"] {grid-area:city; display:flex; align-items:flex-start; font:var(--Body); color:var(--Surface-Secondary); padding:0 0 var(--Spacing-Narrow);}
.Address-L1 [data-field^="State"] {grid-area:state; display:flex; align-items:flex-start; font:var(--Body); color:var(--Surface-Secondary); padding:0 0 var(--Spacing-Narrow);}
.Address-L1 [data-field^="Country"] {grid-area:country; display:flex; align-items:flex-start; font:var(--Body); color:var(--Surface-Secondary); padding:0 0 var(--Spacing-Narrow);}
.Address-L1 [data-field^="Post"],
.Address-L1 [data-field^="Zip"] {grid-area:postcode; display:flex; align-items:flex-start; font:var(--Body); color:var(--Surface-Secondary); padding:0 0 var(--Spacing-Narrow);}
.Address-L1 [data-field^="Map"] {grid-area:map; display:block; align-self:start; margin:0 0 var(--Spacing-Narrow); max-height:150px;}
.Address.Address-L1.Alignment-RTL [data-field^="Post"],
.Address.Address-L1.Alignment-RTL [data-field^="Zip"] {grid-area:country;}
.Address.Address-L1.Alignment-RTL [data-field^="Country"] {grid-area:postcode;}

/* XL */
.XL.Address-L1 {--Map-Aspect-Ratio:16/9; grid-template-columns:[name-start company-start address-start city-start] auto [city-end state-start] auto [state-end country-start] auto [country-end postcode-start] 1fr [postcode-end address-end company-end name-end] var(--Spacing-Regular) [map-start] 30% [map-end]; grid-template-rows:[map-start name-start] auto [name-end company-start] auto [company-end address-start] auto [address-end city-start state-start country-start postcode-start] 1fr [postcode-end country-end state-end city-end map-end];}
.XL.Address-L1.Alignment-RTL {grid-template-columns:[map-start] 30% [map-end] var(--Spacing-Regular) [name-start company-start address-start city-start] auto [city-end state-start] auto [state-end country-start] auto [country-end postcode-start] 1fr [postcode-end address-end company-end name-end];}
.XL.Address-L1 [data-field^="Map"] {max-height:none;}


/* LG */
.LG.Address-L1 {--Map-Aspect-Ratio:4/3; grid-template-columns: [name-start company-start address-start city-start] auto [city-end state-start] auto [state-end country-start] auto [country-end postcode-start] 1fr [postcode-end address-end company-end name-end] var(--Spacing-Regular) [map-start] 35% [map-end]; grid-template-rows:[map-start name-start] auto [name-end company-start] auto [company-end address-start] auto [address-end city-start state-start country-start postcode-start] 1fr [postcode-end country-end state-end city-end map-end];}
.LG.Address-L1.Alignment-RTL {grid-template-columns:[map-start] 35% [map-end] var(--Spacing-Regular) [name-start company-start address-start city-start] auto [city-end state-start] auto [state-end country-start] auto [country-end postcode-start] 1fr [postcode-end address-end company-end name-end];}
.LG.Address-L1 [data-field^="Map"] {max-height:none;}

/* MD */
.MD.Address-L1 {--Map-Aspect-Ratio:1/1;}
.MD.Address-L1 [data-field^="Map"] {max-height:none;}

/* SM */
.SM.Address-L1 [data-field^="Map"] {--Map-Aspect-Ratio:1/1; max-height:none;}
.SM.Address-L1 [data-field^="Full Name"] {font:bold var(--Caption);}
.SM.Address-L1 [data-field^="Company"] {font:var(--Caption);}
.SM.Address-L1 [data-field^="Address"] {font:var(--Caption);}
.SM.Address-L1 [data-field^="City"] {font:var(--Caption);}
.SM.Address-L1 [data-field^="State"] {font:var(--Caption);}
.SM.Address-L1 [data-field^="Country"] {font:var(--Caption);}
.SM.Address-L1 [data-field^="Post"],
.SM.Address-L1 [data-field^="Zip"] {font:var(--Caption);}

/* XS */
.XS.Address-L1 [data-field^="Map"] {--Map-Aspect-Ratio:1/1; max-height:none;}
.XS.Address-L1 [data-field^="Full Name"] {font:bold var(--Footnote);}
.XS.Address-L1 [data-field^="Company"] {font:var(--Footnote);}
.XS.Address-L1 [data-field^="Address"] {font:var(--Footnote);}
.XS.Address-L1 [data-field^="City"] {font:var(--Footnote);}
.XS.Address-L1 [data-field^="State"] {font:var(--Footnote);}
.XS.Address-L1 [data-field^="Country"] {font:var(--Footnote);}
.XS.Address-L1 [data-field^="Post"],
.XS.Address-L1 [data-field^="Zip"] {font:var(--Footnote);}

.Chooser-L1 { --Glyph-Scale:0.5; --Focus-Radius: 1000px; --Focus-Highlight-Width: 1px; --Focus-Shade-Width: 5px; --Focus-Margin: 0px; display:grid; grid-template-columns: [focus-start highlight-start title-start picture-start audiences-start] 1fr [audiences-end picture-end title-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start picture-start] min-content [picture-end highlight-end focus-end title-start] auto [title-end audiences-start] auto [audiences-end]; grid-gap: var(--Spacing-Narrow) var(--Spacing-Wide); align-items:flex-start;}


.Editor-L1 > [data-field="Title"] {grid-area:title; display:block; font:var(--Caption); color: var(--Surface-Primary); text-align: center;}
.Editor-L1 > [data-field="Picture"] {--Picture-Aspect-Ratio:1/1; overflow: hidden; grid-area:picture; align-self:center; justify-self:center; border-radius:1000px; display:block;}
.Editor-L1 > [data-field="Icon"] {grid-area:picture; align-self:center; justify-self:center; z-index:1; max-width:35px; border-radius:1000px; display:block; background:var(--Background-Secondary);}
.Editor-L1 > [data-field="Audiences"] {display:block; grid-area:audiences;}


.Editor-L2 { --Glyph-Scale:0.5; --Focus-Radius: 1000px; --Focus-Highlight-Width: 1px; --Focus-Shade-Width: 5px; --Focus-Margin: 0px; display:grid; grid-template-columns: [focus-start highlight-start title-start picture-start] 1fr [picture-end title-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start picture-start] min-content [picture-end highlight-end focus-end title-start] auto [title-end]; grid-gap: var(--Spacing-Narrow) var(--Spacing-Wide); align-items:flex-start;}
.Editor-L2 > [data-field="Title"] {grid-area:title; display:block; font:var(--Caption); color: var(--Surface-Primary); text-align: center;}
.Editor-L2 > [data-field="Picture"] {--Picture-Aspect-Ratio:1/1; overflow: hidden; grid-area:picture; align-self:center; justify-self:center; border-radius:1000px; display:block;}

.Editor-L3 { --Glyph-Scale:0.5; --Focus-Radius: var(--Image-Radius); --Focus-Highlight-Width: 1px; --Focus-Shade-Width: 5px; --Focus-Margin: 0px; display:grid; grid-template-columns: [focus-start highlight-start title-start picture-start] 1fr [picture-end title-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start picture-start] min-content [picture-end highlight-end focus-end title-start] auto [title-end]; grid-gap: var(--Spacing-Narrow) var(--Spacing-Wide); align-items:flex-start;}
.Editor-L3 > [data-field="Title"] {grid-area:title; display:block; font:var(--Caption); color: var(--Surface-Primary); text-align: left;}
.Editor-L3 > [data-field="Picture"] {--Picture-Aspect-Ratio:4/3; overflow:hidden; grid-area:picture; align-self:center; justify-self:center; border-radius:5px; display:block;}
.Editor-L3 > [data-field="Icon"] {grid-area:picture; align-self:center; justify-self:center; z-index:1; max-width:35px; border-radius:1000px; display:block; background:var(--Background-Secondary);}

.Editor-L4 { --Glyph-Scale:0.5; --Focus-Radius:var(--Image-Radius); --Focus-Highlight-Width: 1px; --Focus-Shade-Width: 5px; --Focus-Margin: 0px; display:grid; grid-template-columns: [focus-start highlight-start title-start picture-start] 1fr [picture-end title-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start picture-start] min-content [picture-end highlight-end focus-end title-start] auto [title-end]; grid-gap: var(--Spacing-Narrow) var(--Spacing-Wide); align-items:flex-start;}
.Editor-L4 > [data-field="Title"] {grid-area:title; display:block; font:var(--Caption); color: var(--Surface-Primary); text-align: left;}
.Editor-L4 > [data-field="Picture"] {--Picture-Aspect-Ratio:4/3; overflow:hidden; grid-area:picture; align-self:center; justify-self:center; border-radius:5px; display:block;}

.Editor-L5 { --Glyph-Scale:0.5; --Focus-Radius:var(--Image-Radius); --Focus-Highlight-Width: 1px; --Focus-Shade-Width: 5px; --Focus-Margin: 0px; display:grid; grid-template-columns: [focus-start highlight-start title-start picture-start] 1fr [picture-end title-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start picture-start] min-content [picture-end highlight-end focus-end title-start] auto [title-end]; grid-gap: var(--Spacing-Narrow) var(--Spacing-Wide); align-items:flex-start;}
.Editor-L5 > [data-field="Title"] {grid-area:title; display:block; font:var(--Caption); color: var(--Surface-Primary); text-align: center;}
.Editor-L5 > [data-field="Picture"] {--Picture-Aspect-Ratio:1/1; overflow:hidden; grid-area:picture; align-self:center; justify-self:center; border-radius:5px; display:block;}
.Editor-L5 > [data-field="Icon"] {grid-area:picture; align-self:center; justify-self:center; z-index:1; max-width:35px; border-radius:1000px; display:block; background:var(--Background-Secondary);}

.Editor-L6 { --Glyph-Scale:0.5; --Focus-Radius:var(--Image-Radius); --Focus-Highlight-Width: 1px; --Focus-Shade-Width: 5px; --Focus-Margin: 0px; display:grid; grid-template-columns: [focus-start highlight-start title-start picture-start] 1fr [picture-end title-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start picture-start] min-content [picture-end highlight-end focus-end title-start] auto [title-end]; grid-gap: var(--Spacing-Narrow) var(--Spacing-Wide); align-items:flex-start;}
.Editor-L6 > [data-field="Title"] {grid-area:title; display:block; font:var(--Caption); color: var(--Surface-Primary); text-align: center;}
.Editor-L6 > [data-field="Picture"] {--Picture-Aspect-Ratio:1/1; overflow:hidden; grid-area:picture; align-self:center; justify-self:center; border-radius:5px; display:block;}

.Editor-L7 { --Glyph-Scale:0.5; --Focus-Radius:var(--Image-Radius); --Focus-Highlight-Width: 1px; --Focus-Shade-Width: 5px; --Focus-Margin: 0px; display:grid; grid-template-columns: [focus-start highlight-start title-start picture-start] 1fr [picture-end title-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start picture-start] min-content [picture-end highlight-end focus-end title-start] auto [title-end]; grid-gap: var(--Spacing-Narrow) var(--Spacing-Wide); align-items:flex-start;}
.Editor-L7 > [data-field="Title"] {grid-area:title; display:block; font:var(--Caption); color: var(--Surface-Primary); text-align: left;}
.Editor-L7 > [data-field="Picture"] {--Picture-Aspect-Ratio:16/9; overflow:hidden; grid-area:picture; align-self:center; justify-self:center; border-radius:5px; display:block;}
.Editor-L7 > [data-field="Icon"] {grid-area:picture; align-self:center; justify-self:center; z-index:1; max-width:35px; border-radius:1000px; display:block; background:var(--Background-Secondary);}

.Editor-L8 { --Glyph-Scale:0.5; --Focus-Radius:var(--Image-Radius); --Focus-Highlight-Width: 1px; --Focus-Shade-Width: 5px; --Focus-Margin: 0px; display:grid; grid-template-columns: [focus-start highlight-start title-start picture-start] 1fr [picture-end title-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start picture-start] min-content [picture-end highlight-end focus-end title-start] auto [title-end]; grid-gap: var(--Spacing-Narrow) var(--Spacing-Wide); align-items:flex-start;}
.Editor-L8 > [data-field="Title"] {grid-area:title; display:block; font:var(--Caption); color: var(--Surface-Primary); text-align: left;}
.Editor-L8 > [data-field="Picture"] {--Picture-Aspect-Ratio:16/9; overflow:hidden; grid-area:picture; align-self:center; justify-self:center; border-radius:5px; display:block;}

.Editor-L9 { --Glyph-Scale:0.5; --Focus-Radius: 20px; --Focus-Highlight-Width: 1px; --Focus-Shade-Width: 5px; --Focus-Margin: 0px; display:grid; grid-template-columns: [focus-start highlight-start title-start picture-start audiences-start] 1fr [audiences-end picture-end title-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start picture-start] min-content [picture-end highlight-end focus-end title-start] auto [title-end audiences-start] auto [audiences-end]; grid-gap: var(--Spacing-Narrow) var(--Spacing-Wide); align-items:flex-start;}
.Editor-L9 > [data-field="Title"] {grid-area:title; display:block; font:var(--Caption); color: var(--Surface-Primary); text-align: center;}
.Editor-L9 > [data-field="Picture"] {--Picture-Aspect-Ratio:1/1; overflow: hidden; grid-area:picture; align-self:center; justify-self:center; border-radius:20px; display:block;}
.Editor-L9 > [data-field="Icon"] {grid-area:picture; align-self:center; justify-self:center; z-index:1; max-width:35px; border-radius:1000px; display:block; background:var(--Background-Secondary);}

.Editor-L10 { --Glyph-Scale:0.75; --Focus-Radius: 20px; --Focus-Highlight-Width:1px; --Focus-Shade-Width: 5px; --Focus-Margin: 0px; display:grid; grid-template-columns: [focus-start highlight-start title-start picture-start audiences-start] 1fr [audiences-end picture-end title-end highlight-end focus-end]; grid-template-rows: [focus-start highlight-start picture-start] min-content [picture-end highlight-end focus-end title-start] auto [title-end audiences-start] auto [audiences-end]; grid-gap: var(--Spacing-Narrow) var(--Spacing-Wide); align-items:flex-start;}
.Editor-L10 > [data-field="Title"] {grid-area:title; display:block; font:var(--Caption); color: var(--Surface-Primary); text-align: center;}
.Editor-L10 > [data-field="Picture"] {--Picture-Aspect-Ratio:1/1; overflow: hidden; grid-area:picture; align-self:center; justify-self:center; border-radius:20px; display:block;}
.Editor-L10 > [data-field="Icon"] {grid-area:picture; align-self:center; justify-self:center; z-index:1; max-width:55px; border-radius:20px; display:block;}
