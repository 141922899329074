.generic-dropzone { position: relative; }

.generic-dropzone .dropzone-before { display: none; position: absolute; top:0; left:0; width: 100%; height: 50%; z-index: 100; margin-top: -1px;}
.generic-dropzone .dropzone-after { display: none; position: absolute; top:50%; left:0; width: 100%; height: 50%; z-index: 100; margin-top: 1px; }

.is-dragging .generic-dropzone .dropzone-before { display: block; }
.is-dragging .generic-dropzone .dropzone-after { display: block; }
.generic-dropzone .dropzone-before.dropzone-active { border-top: 2px solid red; }
.generic-dropzone .dropzone-after.dropzone-active { border-bottom: 2px solid red;  }
.is-dragging .generic-dropzone.dragged-item .dropzone-before { display: none;  }
.is-dragging .generic-dropzone.dragged-item .dropzone-after { display: none; }

.generic-handles .drag-handle { position:absolute;top:0px;left:0px;bottom:0px;right:0px; border: 2px solid #aaa; display: none; opacity:0.3; }
.Is-Reordering .generic-handles .drag-handle { display: block; z-index: 100; }

.generic-hint {
    padding: 8px;
    font-size: 12px;
    color: #444;
    font-family: Lato, Arial;
}
/*
Post-Record adjustments: (maybe apply it based on the group?)

.generic-dropzone .dropzone-before {
    position: absolute;
    top: calc( 0px - var(--Row-Gap) );
    left: calc( 0px - var(--Row-Gap) );
    bottom: 50%;
    z-index: 100;
    margin-top: -1px;
    right: calc( 0px - var(--Row-Gap) );
}

*/