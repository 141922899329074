.edit-field { padding:5px 0; position: relative; }
.edit-field > .label { display:inline-block; padding:1px 1px 2px 10px; font-size: 14px; font-weight:bold; color:#4a4a4a; font-family:Lato, Arial; }
.edit-field > .hint { display:inline-block; padding:1px 1px 2px 10px; font-size: 12px; color:#9b9b9b; font-family:Lato, Arial; }
.edit-field > .input { display:block; min-height:25px; }

.Toolbar-Editing {
    left: 25px;
    z-index: 10;
    display: block;
    /* Adjust margin to match height, when position:sticky; to prevent scroll content resize. */
    margin-bottom: -30px;
    position:absolute;
    top:0px;
}

.Block-Row .Block-Add-Action { display: none; position: absolute; bottom: -20px; }
.Block-Row.Is-Responder .Block-Add-Action { display: grid; }
.Block-Row.Is-Responder + .add-button { display: none; }
.Is-Reordering .Block-Row.Is-Interactive.Is-Responder > .Operator-Grab {
    opacity: 0.6;
}
.Is-Reordering .Block-Row.Is-Interactive > .Operator-Grab {
    transform: translateX(0px) scale(0.5);
    opacity: 0;
    pointer-events: auto;
}

/** Scroll top is before the block, so toolbar should attach to the block's start range. */
.Toolbar-Position-Before { position:absolute; top:var(--BlockYStart); }
/** Scroll top inside the block's range, so toolbar should dock to the top of the scroll container. */
.Toolbar-Position-Inside { position:sticky; top:0px; }
/** Scroll top is after the block, so toolbar should attach to the block's end range. */
.Toolbar-Position-After { position:absolute; top:var(--BlockYEnd); }
/** Block position is unknown. */
/*.Toolbar-Position-Unknown { }*/
