.PayOptions-F1 { display:flex; flex-direction: column; }
.PayOptions-F1 > * { display:block; margin:10px 10px 0px 10px; }
.PayOptions-F1 > [data-field="Title"] {display:flex; font: var(--Subheading); text-transform: var(--Subheading-Case); color: var(--Surface-Primary);}
.PayOptions-F1 > [data-field="Disclaimer"] { display:block; font: var(--Footnote); }
.PayOptions-F1 > data[data-field] { display:block; }

/*** Card Payment Form ***/
.Pay-F1 { display:flex; flex-direction: column; align-items: center; }
.Pay-F1 > * { width: 90%; max-width: 400px; }
.Pay-F1 [data-field="Card"] { display:block; }
/* Email as text */
.Pay-F1 data[data-field="Email"] { display:block; }
.Pay-F1 data[data-field="Email"] { margin:0 var(--Page-Gutter) var(--Spacing-Regular); }
.Pay-F1 data[data-field="Email"] p { font:var(--Body); }
/* Email as input field */
.Pay-F1 div[data-field="Email"] { display:grid; }
.Pay-F1 div[data-field="Email"] { margin:0 var(--Page-Gutter) var(--Spacing-Regular); }
.Pay-F1 [data-field="Confirm"] { display:block; }
.Pay-F1 [data-field="Confirm"] { margin:0 var(--Page-Gutter) var(--Spacing-Regular); }
.Pay-F1 [data-field="TOS"] { display:block; }
.Pay-F1 [data-field="TOS"] { margin:0 var(--Page-Gutter) var(--Spacing-Regular); }
.Pay-F1 [data-field="Resend"] { display:block; }
.Pay-F1 [data-field="Resend"] { margin:0 var(--Page-Gutter) var(--Spacing-Regular); }
.Pay-F1 [data-field="Cancel"] { display:block; }
.Pay-F1 [data-field="Cancel"] { margin:0 var(--Page-Gutter) var(--Spacing-Regular); }
.Pay-F1 [data-field="Register"] { display:block; }
.Pay-F1 [data-field="Register"] { margin:0 var(--Page-Gutter) var(--Spacing-Regular); }
.Pay-F1 [data-field="Register"] hr {
  margin-bottom: 15px;
  border-top: 1px solid #ddd;
  width: 95%;
  border-bottom: 0;
}
.Pay-F1 [data-field="Details"] { display:block; }
.Pay-F1 [data-field="Details"] { margin:0 var(--Page-Gutter) var(--Spacing-Regular); }
.Pay-F1 [data-field="Details"] p { font:var(--Body); }
.Pay-F1 > [data-field="Disclaimer"] { display:block; font: var(--Footnote); margin:0 var(--Page-Gutter) var(--Spacing-Regular); }
.Pay-F1 data[data-field="Logo"] { display:block; text-align: center; }
.Pay-F1 data[data-field="Logo"] > * { height:90px; margin:10px 0; }

.Arrangement-1 > article > section.Frame-F1 { overflow-y: hidden; }
.Frame-F1 { overflow-y: hidden; position: relative; }
.Frame-F1 > div { position: absolute; top:0; left:0; width: 100%; height: 100%; }
.Frame-F1 > div > iframe { position: absolute; top:0; left:0; width: 100%; height: 100%; }

/* Purchase Variant Form */
.Purchase-F1 {display:grid; grid-gap:0; padding-top:var(--Spacing-Wide); grid-template-columns: [deliver-start] var(--Page-Gutter) [title-start variants-start addons-start quantity-start notes-start platform-pay-start confirm-start cancel-start addprofile-start addpurch-start buynotes-start other-start] 1fr [other-end buynotes-end addprofile-end addpurch-end cancel-end confirm-end platform-pay-end notes-end quantity-end variants-end addons-end title-end] var(--Page-Gutter) [deliver-end]; grid-template-rows:[title-start] auto [title-end deliver-start] auto [deliver-end buynotes-start] auto [buynotes-end variants-start] auto [variants-end addons-start] auto [addons-end quantity-start] auto [quantity-end addprofile-start] auto [addprofile-end addpurch-start] auto [addpurch-end notes-start] auto [notes-end platform-pay-start] auto [platform-pay-end other-start] auto [other-end confirm-start] auto [confirm-end cancel-start] auto [cancel-end] 1fr;}
.Purchase-F1 > [data-field="Title"] {grid-area: title; display:flex; font: var(--Subheading); text-transform: var(--Subheading-Case); color: var(--Surface-Primary);}
.Purchase-F1 [data-field="Variants"] {grid-area: variants; padding-top:var(--Spacing-Narrow); display:block; font:var(--Body); color:var(--Surface-Primary);}
.Purchase-F1 [data-field="Addons"] {grid-area: addons; padding-top:var(--Spacing-Narrow); display:block; font:var(--Body); color:var(--Surface-Primary);}
.Purchase-F1 [data-field="Quantity"] {grid-area: quantity; display:block;}
.Purchase-F1 [data-field="Deliver"] {grid-area: deliver; display:block;}
.Purchase-F1 [data-field="Notes"] {grid-area: notes; display:grid; padding:var(--Spacing-Regular) 0;}
.Purchase-F1 [data-field="Payment Notes"] {grid-area: buynotes; display:grid; padding:var(--Spacing-Regular) 0; font:var(--Caption); }
.Purchase-F1 [data-field="Other"] {grid-area: other; display:block; }
.Purchase-F1 [data-field="Platforms Pay"] {grid-area: platform-pay; display:block; }
.Purchase-F1 [data-field="Confirm"] {grid-area: confirm; display:block; padding-top:var(--Spacing-Wide);}
.Purchase-F1 [data-field="Cancel"] {grid-area: cancel; padding-top:var(--Spacing-Regular); display:block; }



/*** Info Dialog Form ***/
.Info-F1 { display:flex; flex-direction: column; }
.Info-F1 [data-field="Title"] { display:block; }
.Info-F1 [data-field="Confirm"] { display:block; }

/*** Cash Collection Key Pad Form ***/
.Cash-Pad { display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px; }
.Cash-Pad .Cash-Key {
  color:var(--Active-Primary);
  background:var(--Active-Secondary);
  text-align:center;
  padding:15px 10px;
  border-radius:10px;
  font:var(--Body);
}
.Cash-Pad .Cash-Key:active {
  color:var(--Active-Secondary);
  background:var(--Active-Primary);
}
