.main-carousel > .carousel-layer .deck {max-width:auto;}
  
  .deck {position: absolute; width:100%!important; top:0; left: 0; right: 0;  bottom: 0;}
  .deck .card { position: absolute; top: 0; left: 0; right: 0; bottom: 0;
     transform-origin: 50% 0 0; box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
     border-radius: 15px; background: #fff;
  }

  .modal-deck-overlay { background: rgba(0,0,0,0.4);position: fixed;top:0;left:0;right:0;bottom:0; }
  .modal-deck { margin:65px auto; }

  .deck .card.incoming { transform: translateY(100vh) !important; opacity: 0; }

  .deck.loaded .card { -webkit-transition-duration: 450ms;
    -webkit-transition-property:transform,opacity,margin,left,right,top,bottom,-webkit-filter; }

  .deck .card .card-panel .panel-title {font:10px Lato, Arial; color: #666;
    -webkit-transition-property:transform; -webkit-transition-duration: 450ms;
    padding: 20px 10px 15px 10px; font-size: 20px;
    transform-origin: 0 0; transform: translateY(-5px) scale(0.6);
    text-overflow: ellipsis; overflow: hidden; white-space: nowrap;
    cursor: pointer;
  }
  .deck .card.top .card-panel .panel-title { transform: scale(1);}
  .deck .card .card-panel > .contents { overflow-y: scroll; -webkit-overflow-scrolling: touch; top: 60px; bottom: 50px; position: absolute; right: 0; left: 0; }
  .deck .card .card-panel > .panel-status { height:60px; top:0px; right:0px; min-width:20%; position: absolute; }

  .deck .card .panel-bar {position: fixed;
    height: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-top: 2px solid #e1e1e1;
    border-radius: 0 0 15px 15px; }

    .deck .card .panel-bar .edit-bar { position: absolute; text-align: right;
        height: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        background: #3993e5;
        border-radius: 0 0 15px 15px;
        opacity: 1;
        -webkit-transition-duration: 300ms;
        -webkit-transition-property:transform,opacity;
    }
    .deck .card .panel-bar .edit-bar.hidden {
        opacity: 0; transform: translateY(-5px);
    }

    .panel-bar .bar-button { display: inline-block;
        padding: 10px 14px;
        border-radius: 18px;
        margin:0 5px;
        font-size: 16px;
        font-family: Lato, Arial;
        cursor: default;
        background: #eee; color:#000;
     }
    .panel-bar .bar-button:active { background: #ccc; color:#000; }
    .panel-bar .bar-button.primary { background: #3993e5; color:#fff;}
    .panel-bar .bar-button.primary:active { background: #8dc8e5; }
    .pull-right { float:right; }