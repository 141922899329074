/* --- Search Button Layout --- */
#Search-Button {grid-area:search; z-index:4; align-self: center; justify-self:center; margin-top:var(--Inset-Top);}
#Search-Button > [data-field="Icon"] {--Glyph-Scale:0.55;}

/* --- Hide Search Button --- */
#Template.Hide-Search {--Search-Icon-Width:0px;}
#Template.Hide-Search #Search-Button {display:none;}

/* --- T1 Search Popover --- */
main#Search {grid-area:main; display: grid; transition-duration:0.2s; grid-template-columns:[query-start results-start] 1fr [query-end] 100px [results-end]; grid-template-rows:[query-start] var(--Header-Height) [query-end results-start] 1fr [results-end]; z-index:1; transform:translateX(15px);}
main#Search form {grid-area:query; transform: translateX(0px); display:flex; align-items: center; justify-content:flex-start; background:var(--Background-Primary); transition-duration:0.2s; padding:0 0 0 var(--Spacing-Wide); margin:var(--Inset-Top) 0 0 0;}
main#Search aside {grid-area: results; background:var(--Background-Primary); transform: translateY(0px); transition-duration:0.4s; }
label[for="search-query"] {position:absolute; width:20px; height:20px;}
input#search-query {border-width:0; font:var(--Body); color:var(--Surface-Primary); background:none; height:100%; width: 100%; padding:0 0 0 25px;}
label[for="search-query"] svg {fill:var(--Surface-Primary);}
main#Search[aria-hidden="true"] {opacity:0; pointer-events: none;}
main#Search[aria-hidden="true"] form {transform: translateX(30px);}
main#Search[aria-hidden="true"] aside {transform: translateY(15px);}

/* Editor Toggle */
a.toggle-editor > *:nth-child(-n+2) {display:block!important;}
a.toggle-editor > *:nth-child(n+3) {display:none!important;}
.editing a.toggle-editor > *:nth-child(-n+2) {display:none!important;}
.editing a.toggle-editor > *:nth-child(n+3) {display:block!important;}

/* Theme Toggle */
a.toggle-theme > *:nth-child(-n+2) {display:block!important;}
a.toggle-theme > *:nth-child(n+3) {display:none!important;}
.dark a.toggle-theme > *:nth-child(-n+2) {display:none!important;}
.dark a.toggle-theme > *:nth-child(n+3) {display:block!important;}
