/* --------------------------------- Template 1 ------------------------------ */
/*
@Name: Template 1
@Summary: App style layout. Left to Right Columned pages.
*/

.Template-1 {display:grid; grid-area:template; width:100%; height:100%; --Max-Content-Width:750px;}
.Template-1.wide {--Page-Gutter:25px;}
.Template-1.regular  {--Page-Gutter:20px;}
.Template-1.narrow {--Page-Gutter:15px;}
.Template-1.thin {--Page-Gutter:10px;}
.Template-1.short {--Page-Gutter:15px;}

/* Hide Content When Rendering */
.Template-1:not(.wide):not(.regular):not(.narrow):not(.thin):not(.short) > * {display:none;}
.Template-1.wide > *,
.Template-1.regular > *,
.Template-1.narrow > *,
.Template-1.thin > *,
.Template-1.short > * {--Scale-Position:1; animation:scale-fade-in var(--Slow) ease var(--Quick) 1 normal both running;}

/* --- Wide --- */
.Template-1.wide {--Header-Height:60px; --Logo-Height:var(--Header-Height); --Nav-Width:180px; --Nav-Width-Inset:var(--Nav-Width); --Nav-Height:100%; --Nav-Height-Inset:0px; --Nav-Background-Angle:-90deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(35px + var(--Page-Gutter)); --Action-Line-Inside:var(--Action-Line-Right); --Action-Line-Outside:var(--Action-Line-Left); --Action-Line-Width:var(--Button-Line-Thickness); --Action-Line-Inside-Justify:end;  --Action-Line-Outside-Justify:start;}
.Template-1.wide {position:relative; grid-template-columns:[banner-start logo-start navigation-start main-start] var(--Nav-Width) [navigation-end logo-end topnav-start] 1fr [topnav-end search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end banner-end]; grid-template-rows:[banner-start navigation-start logo-start search-start profile-start main-start topnav-start] var(--Header-Height) [topnav-end logo-end search-end profile-end banner-end] 1fr [main-end navigation-end];}
.Template-1.wide #Navigation {padding-top:calc( var(--Logo-Height) + var(--Inset-Top) );}
.Template-1.wide #Navigation > div { padding:var(--Spacing-Wide) 0 0 var(--Inset-Left);}

/* --- Regular --- */
.Template-1.regular {--Header-Height:60px; --Logo-Height:var(--Header-Height); --Nav-Width:calc(80px + var(--Inset-Left)); --Nav-Width-Inset:var(--Nav-Width); --Nav-Height:100%; --Nav-Height-Inset:0px; --Nav-Background-Angle:-90deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(35px + var(--Page-Gutter)); --Action-Line-Inside:var(--Action-Line-Right); --Action-Line-Outside:var(--Action-Line-Left); --Action-Line-Width:var(--Button-Line-Thickness); --Action-Line-Inside-Justify:end;  --Action-Line-Outside-Justify:start;}
.Landscape-L .Template-1.regular, .Landscape-R .Template-1.regular {--Header-Height:50px;}
.Template-1.regular {position:relative;
    grid-template-columns:[logo-start banner-start navigation-start main-start] auto [logo-end navigation-end topnav-start] 1fr [topnav-end search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end banner-end];
    grid-template-rows:[banner-start navigation-start logo-start search-start profile-start main-start topnav-start] var(--Header-Height) [logo-end search-end profile-end banner-end topnav-end] 1fr [main-end navigation-end];
}
.Template-1.regular #Navigation {padding-top:calc( var(--Logo-Height) + var(--Inset-Top) ); width:var(--Nav-Width);}
.Template-1.regular #Navigation > div { padding:var(--Spacing-Wide) 0 0 var(--Inset-Left);}

/* --- Narrow --- */
.Template-1.narrow {--Header-Height:calc(60px + var(--Inset-Top)); --Nav-Width:100%;  --Nav-Width-Inset:0px; --Nav-Height:calc(65px + var(--Inset-Bottom));--Nav-Height-Inset:0px; --Search-Icon-Width:40px; --Nav-Background-Angle:180deg; --Profile-Icon-Width:calc(35px + var(--Page-Gutter)); --Action-Line-Inside:var(--Action-Line-Top); --Action-Line-Outside:var(--Action-Line-Bottom); --Action-Line-Height:var(--Button-Line-Thickness); --Action-Line-Inside-Align:end;  --Action-Line-Outside-Align:start;}
.Template-1.narrow {position:relative; grid-template-columns:[banner-start navigation-start main-start topnav-start] 1fr [topnav-end search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end navigation-end banner-end]; grid-template-rows:[banner-start search-start profile-start main-start topnav-start] var(--Header-Height) [search-end profile-end banner-end topnav-end] 1fr [navigation-start] var(--Nav-Height) [navigation-end main-end];}
.Template-1.narrow #Logo {display:none;}
.Template-1.narrow #Navigation > div {padding-bottom: var(--Inset-Bottom);}
.Template-1.narrow article {border-bottom:var(--Nav-Height) solid transparent;}

/* --- Thin / Short --- */
.Template-1.thin, .short .Template-1 {--Header-Height:40px; --Nav-Height:40px; --Nav-Width:40px; --Nav-Width-Inset:0px; --Search-Icon-Width:30px; --Profile-Icon-Width:30px; --Action-Line-Inside:var(--Action-Line-Right); --Action-Line-Outside:var(--Action-Line-Left);}
.Template-1.thin, .short .Template-1 {position:relative; grid-template-columns:[main-start banner-start] 1fr [navigation-start] var(--Nav-Width) [navigation-end main-end banner-end]; grid-template-rows:[main-start navigation-start banner-start] var(--Header-Height) [navigation-end banner-end] 1fr [main-end];}
.Template-1.thin #Logo, .short .Template-1 #Logo {display:none;}
.Template-1.thin #Search-Button, .short .Template-1 #Search-Button {display:none;}
.Template-1.thin #Profile-Button, .short .Template-1 #Profile-Button {display:none;}
.Template-1.thin main, .short .Template-1 main {height:100vh; overflow-y:scroll; overflow-x:hidden; -webkit-overflow-scrolling:touch;}


/* --- Base Layout ---  */
.Template-1 nav {grid-area:navigation; position:relative; z-index:3;}
.Template-1 main {grid-area:main; --Panel-Radius:0px;}
.Template-1 #Logo {grid-area:logo; z-index:4; padding:var(--Inset-Top) var(--Spacing-Regular) 0 calc( var(--Inset-Left) + var(--Spacing-Regular) );}
.Template-1 #Heading-Nav {grid-area:topnav;z-index:4; margin-left: auto; margin-right: 10px; width: 250px;}
#Heading-Nav > .Group-Type-Fit {margin-left: auto; width: 100px; margin-top: 15px;}

/* --- Notifications ---  */
.Template-1 .Notifications {grid-area: main; z-index: 4; align-self: end;}
.Template-1.narrow .Notifications {margin: 0 var(--Spacing-Narrow) calc( var(--Nav-Height) + var(--Spacing-Regular) );}
.Template-1.regular .Notifications {margin: 0 var(--Spacing-Regular) var(--Spacing-Wide)  calc( var(--Nav-Width) + var(--Spacing-Regular) );}
.Template-1.wide .Notifications {margin: 0 var(--Spacing-Regular) var(--Spacing-Wide)  calc( var(--Nav-Width) + var(--Spacing-Regular) );}

/* --------------------------------- Template 2 ------------------------------ */
/*
@Name: Template 2
@Summary: Top nav with centered pages, suitable for websites.
*/

.Template-2 {display:grid; grid-area:template; width:100%; height:100%; --Max-Content-Width:750px;}
.Template-2 main {--Inset-Top:0; --Header-Height:40px;}
.Template-2.wide {--Page-Gutter:25px;}
.Template-2.regular  {--Page-Gutter:20px;}
.Template-2.narrow {--Page-Gutter:15px;}
.Template-2.thin {--Page-Gutter:10px;}
.Template-2.short {--Page-Gutter:15px;}



/* --- Wide --- */
.Template-2.wide {--Header-Height:calc(40px + var(--Inset-Top));  --Nav-Width:100%; --Nav-Width-Inset:0px; --Nav-Height:40px; --Nav-Background-Angle:0deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(35px + var(--Page-Gutter));}
.Template-2.wide {position:relative; grid-template-columns:[banner-start logo-start navigation-start main-start] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end navigation-end logo-end banner-end]; grid-template-rows:[logo-start search-start profile-start] var(--Header-Height) [logo-end search-end profile-end banner-start navigation-start ] var(--Nav-Height) [navigation-end banner-end main-start] 1fr [main-end];}
.Template-2.wide #Navigation {--Action-Line-Inside:var(--Action-Line-Bottom); --Action-Line-Outside:var(--Action-Line-Top); display:flex; align-items:center;}
.Template-2.wide #Navigation > div {width:100%;}


/* --- Regular --- */
.Template-2.regular {--Header-Height:calc(40px + var(--Inset-Top));  --Nav-Width:100%; --Nav-Width-Inset:0px; --Nav-Height:40px; --Nav-Background-Angle:0deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(35px + var(--Page-Gutter));}
.Template-2.regular {position:relative; grid-template-columns:[banner-start logo-start navigation-start main-start] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end navigation-end logo-end banner-end]; grid-template-rows:[logo-start search-start profile-start] var(--Header-Height) [logo-end search-end profile-end banner-start navigation-start ] var(--Nav-Height) [navigation-end banner-end main-start] 1fr [main-end];}
.Template-2.regular #Navigation {--Action-Line-Inside:var(--Action-Line-Bottom); --Action-Line-Outside:var(--Action-Line-Top); display:flex; align-items:center;}
.Template-2.regular #Navigation > div {width:100%;}


/* --- Narrow --- */
.Template-2.narrow {--Header-Height:calc(40px + var(--Inset-Top));  --Nav-Width:100%; --Nav-Width-Inset:0px; --Nav-Height:40px; --Nav-Background-Angle:0deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(35px + var(--Page-Gutter));}
.Template-2.narrow {position:relative; grid-template-columns:[banner-start logo-start navigation-start main-start] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end navigation-end logo-end banner-end]; grid-template-rows:[logo-start search-start profile-start] var(--Header-Height) [logo-end search-end profile-end banner-start navigation-start ] var(--Nav-Height) [navigation-end banner-end main-start] 1fr [main-end];}
.Template-2.narrow #Navigation {--Action-Line-Inside:var(--Action-Line-Bottom); --Action-Line-Outside:var(--Action-Line-Top); display:flex; align-items:center;}
.Template-2.narrow #Navigation > div {width:100%;}

/* --- Thin / Short ---
.Template-2.thin, .short .Template-2 {--Header-Height:40px; --Nav-Height:40px; --Nav-Width:40px; --Search-Icon-Width:30px; --Profile-Icon-Width:30px;}
.Template-2.thin, .short .Template-2 {position:relative; grid-template-columns:[main-start banner-start] 1fr [navigation-start] var(--Nav-Width) [navigation-end main-end banner-end]; grid-template-rows:[main-start navigation-start banner-start] var(--Header-Height) [navigation-end banner-end] 1fr [main-end];}
.Template-2.thin #App-Logo, .short .Template-2 #App-Logo {display:none;}
.Template-2.thin #Search-Button, .short .Template-2 #Search-Button {display:none;}
.Template-2.thin #Profile-Button, .short .Template-2 #Profile-Button {display:none;}
.Template-2.thin main, .Template-2.short main {height:100vh; overflow-y:scroll; overflow-x:hidden; -webkit-overflow-scrolling:touch;}
 */

/* --- Base Layout --- */
.Template-2 #Logo {grid-area:logo; z-index:4; padding: var(--Inset-Top) calc( var(--Inset-Right) + var(--Page-Gutter) ) 0 calc( var(--Inset-Left) + var(--Page-Gutter) );}
.Template-2 #Logo #Logo-Button {object-position:0% 50%;}
.Template-2 nav {grid-area:navigation; position:relative; z-index:3;}
.Template-2 main {grid-area:main; z-index:0; overflow:hidden; transition-duration:0.4s, 0.4s; transition-property:opacity, transform; pointer-events: none; opacity:0; transform:translateY(15px); width:100%; height:100%;}
.Template-2 main[aria-hidden="false"] {z-index:1; opacity:1; transform:translateY(0px); transition-delay:0.2s, 0.2s; pointer-events: auto;}
.Template-2 main.Arrangement-3 article.Layer-Root,
.Template-2 main.Arrangement-4 article.Layer-Root {--Panel-Radius:0px;}

/* --- Notifications ---  */
.Template-2 .Notifications {grid-area: main; z-index: 4; align-self: end;}
.Template-2.narrow .Notifications {margin: 0 var(--Page-Gutter) calc( var(--Nav-Height) + var(--Spacing-Regular) );}
.Template-2.regular .Notifications {margin: 0 var(--Page-Gutter) var(--Spacing-Wide)  calc( var(--Nav-Width) + var(--Spacing-Regular) );}
.Template-2.wide .Notifications {margin: 0 var(--Page-Gutter) var(--Spacing-Wide)  calc( var(--Nav-Width) + var(--Spacing-Regular) );}

/* --- Overrides For Arrangement 4 --- */
.Template-2 .Arrangement-4 > article.Layer-Root {--Header-Height:0px;}

/* --------------------------------- Template 3 ------------------------------ */
/*
@Name: Template 3
@Summary: Card stack with top nav. Used with the editor.
*/

.Template-3 {display:grid; width:100%; height:100%;}
.Template-3.wide {--Page-Gutter:25px;}
.Template-3.regular  {--Page-Gutter:20px;}
.Template-3.narrow {--Page-Gutter:15px;}
.Template-3.thin {--Page-Gutter:10px;}
.Template-3.short {--Page-Gutter:15px;}

.Template-3 {--Header-Height:50px; --Nav-Height:55px; --Nav-Width:100%; --Nav-Width-Inset:0px; --Nav-Height-Inset:calc(var(--Nav-Height) + var(--Inset-Top)); --Nav-Background-Angle:0deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(35px + var(--Page-Gutter));}
.Template-3 {position:relative; grid-template-columns: [banner-start navigation-start main-start] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end navigation-end  banner-end];
grid-template-rows: [banner-start navigation-start search-start profile-start main-start] var(--Nav-Height-Inset) [search-end profile-end navigation-end banner-end] 1fr [main-end];}
.Template-3 #Navigation {--Action-Line-Inside:var(--Action-Line-Bottom); --Action-Line-Outside:var(--Action-Line-Top);}
.Template-3 #Logo {display:none;}
.Template-3 #Close-Button {margin-top: var(--Spacing-Narrow);}

/* --- Base Layout ---  */
.Template-3 nav {grid-area:navigation; position:relative; z-index:3; padding-right: calc( var(--Search-Icon-Width) + var(--Profile-Icon-Width) ); padding-top: var(--Spacing-Narrow);}
.Template-3 nav > div {padding-top: var(--Inset-Top); width:100%;}
.Template-3 main {grid-area:main;}

/* --- Overrides For Arrangement 3 --- */
.Template-3 .Arrangement-3 > article.Layer-Root {--Header-Height:0px;}

/* --- Notifications ---  */
.Template-3 .Notifications {grid-area: main; z-index: 4; align-self: end;}
.Template-3.narrow .Notifications {margin: 0 var(--Page-Gutter) var(--Spacing-Regular);}
.Template-3.regular .Notifications {margin: 0 var(--Page-Gutter) var(--Spacing-Regular);}
.Template-3.wide .Notifications {margin: 0 var(--Page-Gutter) var(--Spacing-Regular);}


/* --------------------------------- Template 4 ------------------------------ */
/*
@Name: Template 4
@Summary: Website style layout. Header bar and full height content.
*/

/* ********* TODO *********

- Wide and Regular layouts

*/

.Template-4 {display:grid; grid-area:template; width:100%; height:100%; --Max-Content-Width:750px;}
.Template-4.wide {--Page-Gutter:25px;}
.Template-4.regular  {--Page-Gutter:20px;}
.Template-4.narrow {--Page-Gutter:15px;}
.Template-4.thin {--Page-Gutter:10px;}
.Template-4.short {--Page-Gutter:15px;}

/* --- Wide --- */
.Template-4.wide {--Header-Height:calc(60px + var(--Inset-Top,0)); --Nav-Width:calc(40px + var(--Inset-Left)); --Nav-Width-Inset:var(--Nav-Width); --Nav-Height:calc(40px + var(--Inset-Top,0) ); --Search-Icon-Width:40px; --Nav-Background-Angle:0deg; --Profile-Icon-Width:calc(35px + var(--Page-Gutter));}
.Template-4.wide {position:relative; grid-template-columns:[banner-start navigation-start logo-start main-start] var(--Profile-Icon-Width) [logo-end navigation-end] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end banner-end]; grid-template-rows:[banner-start navigation-start logo-start search-start profile-start main-start] var(--Header-Height) [search-end profile-end logo-end navigation-end banner-end] 1fr [main-end];}
.Template-4.wide #Logo {padding-top:var(--Inset-Top);}
.Template-4.wide #Navigation {opacity:0; --Action-Line-Inside:var(--Action-Line-Bottom); --Action-Line-Outside:var(--Action-Line-Top);}
.Template-4.wide #Navigation > div {padding-top: var(--Inset-Top);}
.Template-4.wide article {--Nav-Width-Inset:0;}
.Template-4.wide article header {--Nav-Width-Inset:var(--Nav-Width); border-left:var(--Nav-Width-Inset) solid transparent;}

/* --- Regular --- */
.Template-4.regular {--Header-Height:calc(60px + var(--Inset-Top,0)); --Nav-Width:calc(40px + var(--Inset-Left)); --Nav-Width-Inset:var(--Nav-Width); --Nav-Height:calc(40px + var(--Inset-Top,0) ); --Search-Icon-Width:40px; --Nav-Background-Angle:0deg; --Profile-Icon-Width:calc(35px + var(--Page-Gutter));}
.Template-4.regular {position:relative; grid-template-columns:[banner-start navigation-start logo-start main-start] var(--Profile-Icon-Width) [logo-end navigation-end] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end banner-end]; grid-template-rows:[banner-start navigation-start logo-start search-start profile-start main-start] var(--Header-Height) [search-end profile-end logo-end navigation-end banner-end] 1fr [main-end];}
.Template-4.regular #Logo {padding-top:var(--Inset-Top);}
.Template-4.regular #Navigation {opacity:0; --Action-Line-Inside:var(--Action-Line-Bottom); --Action-Line-Outside:var(--Action-Line-Top);}
.Template-4.regular #Navigation > div {padding-top: var(--Inset-Top);}
.Template-4.regular article {--Nav-Width-Inset:0;}
.Template-4.regular article header {--Nav-Width-Inset:var(--Nav-Width); border-left:var(--Nav-Width-Inset) solid transparent;}

/* --- Narrow --- */
.Template-4.narrow {--Header-Height:calc(60px + var(--Inset-Top,0)); --Nav-Width:calc(40px + var(--Inset-Left)); --Nav-Width-Inset:var(--Nav-Width); --Nav-Height:calc(40px + var(--Inset-Top,0) ); --Search-Icon-Width:40px; --Nav-Background-Angle:0deg; --Profile-Icon-Width:calc(35px + var(--Page-Gutter));}
.Template-4.narrow {position:relative; grid-template-columns:[banner-start navigation-start logo-start main-start] var(--Profile-Icon-Width) [logo-end navigation-end] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end banner-end]; grid-template-rows:[banner-start navigation-start logo-start search-start profile-start main-start] var(--Header-Height) [search-end profile-end logo-end navigation-end banner-end] 1fr [main-end];}
.Template-4.narrow #Logo {padding-top:var(--Inset-Top);}
.Template-4.narrow #Navigation {opacity:0; --Action-Line-Inside:var(--Action-Line-Bottom); --Action-Line-Outside:var(--Action-Line-Top);}
.Template-4.narrow #Navigation > div {padding-top: var(--Inset-Top);}
.Template-4.narrow article {--Nav-Width-Inset:0;}
.Template-4.narrow article header {--Nav-Width-Inset:var(--Nav-Width); border-left:var(--Nav-Width-Inset) solid transparent;}

/* --- Thin / Short --- */
.Template-4.thin, .short .Template-4 {--Header-Height:40px; --Nav-Height:40px; --Nav-Width:40px; --Nav-Width-Inset:0px; --Search-Icon-Width:30px; --Profile-Icon-Width:30px;}
.Template-4.thin, .short .Template-4 {position:relative; grid-template-columns:[main-start banner-start] 1fr [navigation-start] var(--Nav-Width) [navigation-end main-end banner-end]; grid-template-rows:[main-start navigation-start banner-start] var(--Header-Height) [navigation-end banner-end] 1fr [main-end];}
.Template-4.thin #Logo, .short .Template-4 #Logo {display:none;}
.Template-4.thin #Search-Button, .short .Template-4 #Search-Button {display:none;}
.Template-4.thin #Profile-Button, .short .Template-4 #Profile-Button {display:none;}
.Template-4.thin main, .short .Template-4 main {height:100vh; overflow-y:scroll; overflow-x:hidden; -webkit-overflow-scrolling:touch;}


/* --- Base Layout ---  */
.Template-4 nav {grid-area:navigation; position:relative; z-index:3;}
.Template-4 main {grid-area:main; --Panel-Radius:0px;}
.Template-4 #Logo {grid-area:logo; z-index:4; padding-top:var(--Inset-Top); padding-left:var(--Inset-Left); background: var(--Background-Primary);}

/* --- Notifications ---  */
.Template-4 .Notifications {grid-area: main; z-index: 4; align-self: end;}
.Template-4.narrow .Notifications {margin: 0 var(--Page-Gutter) calc( var(--Nav-Height) + var(--Spacing-Regular) );}
.Template-4.regular .Notifications {margin: 0 var(--Page-Gutter) var(--Spacing-Wide)  calc( var(--Nav-Width) + var(--Spacing-Regular) );}
.Template-4.wide .Notifications {margin: 0 var(--Page-Gutter) var(--Spacing-Wide)  calc( var(--Nav-Width) + var(--Spacing-Regular) );}

/* --------------------------------- Template 5 ------------------------------ */
/*
@Name: Template 5
@Summary: Top nav (suitable for transparent gradient) which flows over main area.
*/

.Template-5 {display:grid; grid-area:template; width:100%; height:100%; --Max-Content-Width:750px;}
.Template-5 main {--Inset-Top:0; --Header-Height:40px;}
.Template-5.wide {--Page-Gutter:25px;  --Max-Content-Width:calc(100% * 0.7);}
.Template-5.regular  {--Page-Gutter:20px;}
.Template-5.narrow {--Page-Gutter:15px;}
.Template-5.thin {--Page-Gutter:10px;}
.Template-5.short {--Page-Gutter:15px;}



/* --- Wide --- */
.Template-5.wide {--Header-Height:calc(40px + var(--Inset-Top));  --Nav-Width:100%; --Nav-Width-Inset:0px; --Nav-Height:40px; --Nav-Height-Inset:0px; --Nav-Background-Angle:0deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(35px + var(--Page-Gutter));}
.Template-5.wide {position:relative; grid-template-columns:[banner-start logo-start navigation-start main-start] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end navigation-end logo-end banner-end]; grid-template-rows:[logo-start search-start profile-start] var(--Header-Height) [logo-end search-end profile-end banner-start navigation-start main-start] var(--Nav-Height) [navigation-end banner-end] 1fr [main-end];}
.Template-5.wide #Navigation {--Action-Line-Inside:var(--Action-Line-Bottom); --Action-Line-Outside:var(--Action-Line-Top); display:flex; align-items:center;}
.Template-5.wide #Navigation > div {width:100%;}


/* --- Regular --- */
.Template-5.regular {--Header-Height:calc(40px + var(--Inset-Top));  --Nav-Width:100%; --Nav-Width-Inset:0px; --Nav-Height:40px; --Nav-Height-Inset:0px; --Nav-Background-Angle:0deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(35px + var(--Page-Gutter));}
.Template-5.regular {position:relative; grid-template-columns:[banner-start logo-start navigation-start main-start] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end navigation-end logo-end banner-end]; grid-template-rows:[logo-start search-start profile-start] var(--Header-Height) [logo-end search-end profile-end banner-start navigation-start main-start] var(--Nav-Height) [navigation-end banner-end] 1fr [main-end];}
.Template-5.regular #Navigation {--Action-Line-Inside:var(--Action-Line-Bottom); --Action-Line-Outside:var(--Action-Line-Top); display:flex; align-items:center;}
.Template-5.regular #Navigation > div {width:100%;}


/* --- Narrow --- */
.Template-5.narrow {--Header-Height:calc(40px + var(--Inset-Top));  --Nav-Width:100%; --Nav-Width-Inset:0px; --Nav-Height:40px; --Nav-Height-Inset:0px; --Nav-Background-Angle:0deg; --Search-Icon-Width:40px; --Profile-Icon-Width:calc(35px + var(--Page-Gutter));}
.Template-5.narrow {position:relative; grid-template-columns:[banner-start logo-start navigation-start main-start] 1fr [search-start] var(--Search-Icon-Width) [search-end profile-start] var(--Profile-Icon-Width) [profile-end main-end navigation-end logo-end banner-end]; grid-template-rows:[logo-start search-start profile-start] var(--Header-Height) [logo-end search-end profile-end banner-start navigation-start main-start] var(--Nav-Height) [navigation-end banner-end] 1fr [main-end];}
.Template-5.narrow #Navigation {--Action-Line-Inside:var(--Action-Line-Bottom); --Action-Line-Outside:var(--Action-Line-Top); display:flex; align-items:center;}
.Template-5.narrow #Navigation > div {width:100%;}

/* --- Thin / Short ---
.Template-5.thin, .short .Template-5 {--Header-Height:40px; --Nav-Height:40px; --Nav-Width:40px; --Search-Icon-Width:30px; --Profile-Icon-Width:30px;}
.Template-5.thin, .short .Template-5 {position:relative; grid-template-columns:[main-start banner-start] 1fr [navigation-start] var(--Nav-Width) [navigation-end main-end banner-end]; grid-template-rows:[main-start navigation-start banner-start] var(--Header-Height) [navigation-end banner-end] 1fr [main-end];}
.Template-5.thin #App-Logo, .short .Template-5 #App-Logo {display:none;}
.Template-5.thin #Search-Button, .short .Template-5 #Search-Button {display:none;}
.Template-5.thin #Profile-Button, .short .Template-5 #Profile-Button {display:none;}
.Template-5.thin main, .Template-5.short main {height:100vh; overflow-y:scroll; overflow-x:hidden; -webkit-overflow-scrolling:touch;}
 */

/* --- Base Layout --- */
.Template-5 #Logo {grid-area:logo; z-index:4; padding: var(--Inset-Top) calc( var(--Inset-Right) + var(--Page-Gutter) ) 0 calc( var(--Inset-Left) + var(--Page-Gutter) );}
.Template-5 #Logo #Logo-Button {object-position:0% 50%;}
.Template-5 nav {grid-area:navigation; position:relative; z-index:3;}
.Template-5 main {grid-area:main; z-index:0; overflow:hidden; transition-duration:0.4s, 0.4s; transition-property:opacity, transform; pointer-events: none; opacity:0; transform:translateY(15px); width:100%; height:100%;}
.Template-5 main[aria-hidden="false"] {z-index:1; opacity:1; transform:translateY(0px); transition-delay:0.2s, 0.2s; pointer-events: auto;}
.Template-5 main.Arrangement-3 article.Layer-Root,
.Template-5 main.Arrangement-4 article.Layer-Root {--Panel-Radius:0px;}
.Template-5 main article > header {margin-top:var(--Nav-Height);}

/* --- Notifications ---  */
.Template-5 .Notifications {grid-area: main; z-index: 4; align-self: end;}
.Template-5.narrow .Notifications {margin: 0 var(--Page-Gutter) calc( var(--Nav-Height) + var(--Spacing-Regular) );}
.Template-5.regular .Notifications {margin: 0 var(--Page-Gutter) var(--Spacing-Wide)  calc( var(--Nav-Width) + var(--Spacing-Regular) );}
.Template-5.wide .Notifications {margin: 0 var(--Page-Gutter) var(--Spacing-Wide)  calc( var(--Nav-Width) + var(--Spacing-Regular) );}

/* --- Overrides For Arrangement 4 --- */
.Template-5 .Arrangement-4 > article.Layer-Root {--Header-Height:0px;}