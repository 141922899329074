/* Reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, section, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, t d, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, button, dialog {box-sizing: border-box; margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; -webkit-tap-highlight-color: transparent; -webkit-text-size-adjust:none;}

/* Remove Focus Outline */
:focus {outline:none;}
::-moz-focus-inner {border:0;}

html, body {width:100%; height:100%;}

/* --- Base Font Sizing --- */
@supports (font: -apple-system-body) and (-webkit-touch-callout: none) { html {font: -apple-system-body;} } /* Use iOS Dynamic Type Setting if available */

.text-xx-small {font-size:81.25%} /* 13pt */
.text-x-small {font-size:87.5%} /* 14pt */
.text-small {font-size:93.75%} /* 15pt */
.text-medium {font-size:100%;} /* 16pt */
.text-large {font-size:112.5%} /* 18pt */
.text-x-large {font-size:125%} /* 20pt */
.text-xx-large {font-size:137.5%} /* 22pt */

/* --- Fonts Variables - Sizes based on 16px base font (1em). 1px  size equal to 1 divided by 16 or  0.0625em  */
:root {
    /* Text Sizes */
    --px1:0.0625em;
    --px2:0.125em;
    --px3:0.1875em;
    --px4:0.25em;
    --px5:0.3125em;
    --px6:0.375em;
    --px7:0.4375em;
    --px8:0.5em;
    --px9:0.5625em;
    --px10:0.625em;
    --px11:0.6875em;
    --px12:0.75em;
    --px13:0.8125em;
    --px14:0.875em;
    --px15:0.9375em;
    --px16:1em;
    --px17:1.0625em;
    --px18:1.125em;
    --px19:1.1875em;
    --px20:1.25em;
    --px21:1.3125em;
    --px22:1.375em;
    --px23:1.4375em;
    --px24:1.5em;
    --px25:1.5625em;
    --px26:1.625em;
    --px27:1.6875em;
    --px28:1.75em;
    --px29:1.8125em;
    --px30:1.875em;
    --px35:2.1875em;
    --px40:2.5em;
    --px45:2.8125em;
    --px50:3.125em;

    /* Easing Functions */
    --EaseOutBack:cubic-bezier(0.34, 1.56, 0.64, 1);
    --EaseInBack:cubic-bezier(0.36, 0, 0.66, -0.56);

    /* Meaning Colors */
    --Meaning-Success:rgb(30, 150, 60);
    --Meaning-Danger:rgb(210, 40, 70);
    --Meaning-Warning:rgb(240, 180, 20);
    --Meaning-Info:rgb(0, 90, 210);
}

/* Animation Speed Variables */
@media (prefers-reduced-motion) {
    :root {
        --Very-Quick:0s;
        --Quick:0s;
        --Nominal:0s;
        --Slow:0s;
        --Very-Slow:0s;
    }
}

.Reduce-Motion {
    --Very-Quick:0s;
    --Quick:0s;
    --Nominal:0s;
    --Slow:0s;
    --Very-Slow:0s;
}

/* ----- Alignment Variants ----- */
.Alignment-LTR {--Direction:ltr;}
.Alignment-RTL {--Direction:rtl;}

/* --- Text selection --- */
#App, #App * {-webkit-touch-callout: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
#App *.Text-Selectable, #App .richtext, #App .Is-Richtext, #App input {-webkit-touch-callout: auto; -webkit-user-select: auto; -moz-user-select: auto; -ms-user-select: auto; user-select: auto;}

/* --- Button Default ---
button {background:none; border:none; cursor:pointer;}
*/

/* --- Default Environment Insets --- */
html {--Inset-Top:0px; --Inset-Right:0px; --Inset-Bottom:0px; --Inset-Left:0px;}

/* --- Animations --- */
@keyframes horizontal-fade-in {
    from {opacity:var(--Fade-Opacity,0); transform:translateX(var(--Horizontal-Position,-15px));}
    to {opacity:1; transform:translateX(0px);}
}

@keyframes horizontal-fade-out {
    from {opacity:1; transform:translateX(0px);}
    to {opacity:var(--Fade-Opacity,0); transform:translateX(var(--Horizontal-Position,-15px));}
}

@keyframes vertical-fade-in {
    from {opacity:var(--Fade-Opacity,0); transform:translateY(var(--Vertical-Position,-15px));}
    to {opacity:1; transform:translateY(0px);}
}

@keyframes vertical-fade-out {
    from {opacity:1; transform:translateY(0px);}
    to {opacity:var(--Fade-Opacity,0); transform:translateY(var(--Vertical-Position,-15px));}
}

@keyframes scale-fade-in {
    from {opacity:var(--Fade-Opacity,0); transform:scale(var(--Scale-Position,0.25));}
    to {opacity:1; transform:scale(1);}
}

@keyframes scale-fade-out {
    from {opacity:1; transform:scale(1);}
    to {opacity:var(--Fade-Opacity,0); transform:scale(var(--Scale-Position,0.25));}
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

@keyframes slide-in-left {
    0% {transform:translateX(-50px); opacity:0;}
}

@keyframes slide-in-right {
    0% {transform:translateX(50px); opacity:0;}
}

@keyframes scale-pulse {
    0% {transform:scale(1);}
    25% {transform:scale(2); animation-timing-function: ease-out;}
    50% {transform:scale(1); animation-timing-function: ease-in;}
}

@keyframes scale-ripple {
    0% {transform:scale(1); opacity:0;}
    20% {transform:scale(1.02); opacity:1;}
    100% {transform:scale(1.1); opacity:0; animation-timing-function: ease-out;}

}

/* --- Reflow Animations --- */
@keyframes appear-xs {
    from {opacity:0; transform:scale(0.9);}
    to {opacity:1; transform:scale(1);}
}
@keyframes appear-sm {
    from {opacity:0; transform:scale(0.9);}
    to {opacity:1; transform:scale(1);}
}
@keyframes appear-md {
    from {opacity:0; transform:scale(0.9);}
    to {opacity:1; transform:scale(1);}
}
@keyframes appear-lg {
    from {opacity:0; transform:scale(0.9);}
    to {opacity:1; transform:scale(1);}
}
@keyframes appear-xl {
    from {opacity:0; transform:scale(0.9);}
    to {opacity:1; transform:scale(1);}
}

@keyframes slide-in-thin {
    from {opacity:0; transform:translateY(-10px);}
    to {opacity:1; transform:translateY(0px);}
}
@keyframes slide-in-narrow {
    from {opacity:0; transform:translateY(-10px);}
    to {opacity:1; transform:translateY(0px);}
}
@keyframes slide-in-regular {
    from {opacity:0; transform:translateY(-10px);}
    to {opacity:1; transform:translateY(0px);}
}
@keyframes slide-in-wide {
    from {opacity:0; transform:translateY(-10px);}
    to {opacity:1; transform:translateY(0px);}
}

/* Major Reflow Reveal */
.reflow .thin .major-reveal {animation:slide-in-thin 0.1s ease-out 0.45s 1 backwards;}
.reflow .narrow .major-reveal {animation:slide-in-narrow 0.1s ease-out 0.45s 1 backwards;}
.reflow .regular .major-reveal {animation:slide-in-regular 0.1s ease-out 0.45s 1 backwards;}
.reflow .wide .major-reveal {animation:slide-in-wide 0.1s ease-out 0.45s 1 backwards;}

/* Minor Reflow Reveal */
.reflow.XS.minor-reveal, .reflow .XS .minor-reveal {animation:appear-xs 0.1s ease-out 0.45s 1 backwards;}
.reflow.SM.minor-reveal, .reflow .SM .minor-reveal {animation:appear-sm 0.1s ease-out 0.45s 1 backwards;}
.reflow.MD.minor-reveal, .reflow .MD .minor-reveal {animation:appear-md 0.1s ease-out 0.45s 1 backwards;}
.reflow.LG.minor-reveal, .reflow .LG .minor-reveal {animation:appear-lg 0.1s ease-out 0.45s 1 backwards;}
.reflow.XL.minor-reveal, .reflow .XL .minor-reveal {animation:appear-xl 0.1s ease-out 0.45s 1 backwards;}

.reflow .major-reveal:nth-child(10n+1), .reflow .minor-reveal:nth-child(10n+1) {animation-delay: 0s;}
.reflow .major-reveal:nth-child(10n+2), .reflow .minor-reveal:nth-child(10n+2) {animation-delay: 0.05s;}
.reflow .major-reveal:nth-child(10n+3), .reflow .minor-reveal:nth-child(10n+3) {animation-delay: 0.1s;}
.reflow .major-reveal:nth-child(10n+4), .reflow .minor-reveal:nth-child(10n+4) {animation-delay: 0.15s;}
.reflow .major-reveal:nth-child(10n+5), .reflow .minor-reveal:nth-child(10n+5) {animation-delay: 0.2s;}
.reflow .major-reveal:nth-child(10n+6), .reflow .minor-reveal:nth-child(10n+6) {animation-delay: 0.25s;}
.reflow .major-reveal:nth-child(10n+7), .reflow .minor-reveal:nth-child(10n+7) {animation-delay: 0.3s;}
.reflow .major-reveal:nth-child(10n+8), .reflow .minor-reveal:nth-child(10n+8) {animation-delay: 0.35s;}
.reflow .major-reveal:nth-child(10n+9), .reflow .minor-reveal:nth-child(10n+9) {animation-delay: 0.4s;}

/* ----- Glyph ----- */
.Glyph {position:relative; width:100%;}
.Glyph:before {content:""; display:block; padding-bottom:100%;}
.Glyph svg, .Glyph img {position:absolute; transform:scale( calc( var(--Glyph-Scale) * var(--Glyph-Adjustment,1) ) ); z-index:1; object-fit:contain; top:0; left:0; width:100%; height:100%; vertical-align:baseline; transition-duration:var(--Slow);}
.Glyph-Adjustment-Largest {--Glyph-Adjustment:1.5;}
.Glyph-Adjustment-Larger {--Glyph-Adjustment:1.2;}
.Glyph-Adjustment-Smaller {--Glyph-Adjustment:0.8;}
.Glyph-Adjustment-Smallest {--Glyph-Adjustment:0.5;}

/* --- Avatars --- */
[data-field="Avatar"] {position:relative; width:100%; box-shadow:var(--Avatar-Shadow); border-radius:var(--Avatar-Radius);}
[data-field="Avatar"]:before {content:""; display:block; --Avatar-Aspect-Ratio:1/1; padding-bottom:calc(100% / ( var(--Avatar-Aspect-Ratio, 1/1) ) ); background:var(--Background-Secondary) url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="rgba(0,0,0,0.1)" d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"/></svg>') 50% 45%/45% no-repeat; border-radius:50%;}
[data-field="Avatar"] img {position: absolute; object-fit:cover; top:0; left:0; width:100%; border-radius:50%; margin:auto; border:var(--Avatar-Border-Size) solid var(--Overlay);}
[data-field="Avatar"]:after {position:absolute; content:""; top:0; left:0; width:100%; height:100%; visibility:hidden;}
[data-field="Avatar"]:empty {background:rgba(0,0,0,0.01) url('data:image/svg+xml;utf8,<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><circle fill="rgba(0,0,0,0.1)" cx="512" cy="512" r="512"></circle></svg>') 50% 0%/100% no-repeat; text-align:center; padding-bottom:100%; border:var(--Image-Border);}

/* --- Picture --- */
[data-field="Picture"] {position: relative; width:100%;  box-shadow:var(--Image-Shadow); border-radius:var(--Image-Radius); overflow:hidden;}
[data-field="Picture"]:before {content: ""; display: block; padding-bottom: calc(100% / ( var( --Picture-Aspect-Ratio, 4/3 ) ) ); /*background:rgba(0,0,0,0.01) url('data:image/svg+xml;utf8,<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(0,0,0,0.2)" d="M678.5625,358 L345.4375,358 C324.208789,358 307,375.180807 307,396.375 L307,626.625 C307,647.819193 324.208789,665 345.4375,665 L678.5625,665 C699.791211,665 717,647.819193 717,626.625 L717,396.375 C717,375.180807 699.791211,358 678.5625,358 Z M673.757812,626.625 L350.242188,626.625 C347.588632,626.625 345.4375,624.477366 345.4375,621.828125 L345.4375,401.171875 C345.4375,398.522634 347.588632,396.375 350.242188,396.375 L673.757812,396.375 C676.411368,396.375 678.5625,398.522634 678.5625,401.171875 L678.5625,621.828125 C678.5625,624.477366 676.411368,626.625 673.757812,626.625 L673.757812,626.625 Z M409.5,428.354167 C391.809941,428.354167 377.46875,442.672039 377.46875,460.333333 C377.46875,477.994628 391.809941,492.3125 409.5,492.3125 C427.190059,492.3125 441.53125,477.994628 441.53125,460.333333 C441.53125,442.672039 427.190059,428.354167 409.5,428.354167 Z M383.875,588.25 L640.125,588.25 L640.125,524.291667 L570.044629,454.325247 C566.292168,450.578888 560.207832,450.578888 556.45457,454.325247 L460.75,549.875 L429.107129,518.283581 C425.354668,514.537221 419.270332,514.537221 415.51707,518.283581 L383.875,549.875 L383.875,588.25 Z" id="ImagePlaceholder"></path></svg>') 50% 50%/contain no-repeat;*/}
[data-field="Picture"] img {position: absolute; object-fit:cover; top: 0; left: 0; width: 100%; height: 100%; vertical-align: baseline; border:var(--Image-Border); border-radius:var(--Image-Radius);}
[data-field="Picture"].No-Border img {border:none;}
[data-field="Picture"].No-Shadow {box-shadow:none;}
[data-field="Picture"]:after {position: absolute; content: ""; top: 0; left: 0; width: 100%; height: 100%; visibility:hidden;}
[data-field="Picture"]:empty:before {visibility:hidden;}
[data-field="Picture"]:empty:after {background:rgba(0,0,0,0.1); visibility: visible;}
section > [data-field="Picture"] {overflow:hidden;}

/*
@Name: Picture Aspect
@Summary: Adjusts amount of crop for scrollable rows.
@Property:Picture-Aspect-Square - 1:1 Aspect.
@Property:Picture-Aspect-Portrait - 3:4 Aspect.
@Property:Picture-Aspect-Landscape - 4:3 Aspect.
@Property:Picture-Aspect-Widescreen - 16:9 Aspect.
*/
.Picture-Aspect-Square {--Picture-Aspect-Ratio:1/1!important;}
.Picture-Aspect-Portrait {--Picture-Aspect-Ratio:3/4!important;}
.Picture-Aspect-Landscape {--Picture-Aspect-Ratio:4/3!important;}
.Picture-Aspect-Widescreen {--Picture-Aspect-Ratio:16/9!important;}
.Picture-Aspect-TwoByOne {--Picture-Aspect-Ratio:2/1!important;}
.Picture-Aspect-ThreeByOne {--Picture-Aspect-Ratio:3/1!important;}
.Picture-Aspect-FourByOne {--Picture-Aspect-Ratio:4/1!important;}
.Picture-Aspect-FiveByOne {--Picture-Aspect-Ratio:5/1!important;}

/* --- Map --- */
[data-field="Map"] {position: relative; width:100%;  box-shadow:var(--Image-Shadow); border-radius:var(--Image-Radius); overflow:hidden;}
[data-field="Map"]:before {content: ""; display: block; padding-bottom: calc(100% / ( var( --Map-Aspect-Ratio, 4/3 ) ) ); background:rgba(0,0,0,0.01) url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>') 50% 50%/contain no-repeat;}
[data-field="Map"] img {position: absolute; object-fit:cover; top: 0; left: 0; width: 100%; height: 100%; vertical-align: baseline; border:var(--Image-Border);}
[data-field="Map"]:after {position: absolute; content: ""; top: 0; left: 0; width: 100%; height: 100%; visibility:hidden;}
[data-field="Map"]:empty:before {visibility:hidden;}
[data-field="Map"]:empty:after {background:rgba(0,0,0,0.1); visibility: visible;}
section > [data-field="Map"] {overflow:hidden;}

/*
@Name: Map Aspect
@Summary: Adjusts aspect.
@Property:Map-Aspect-Square - 1:1 Aspect.
@Property:Map-Aspect-Portrait - 3:4 Aspect.
@Property:Map-Aspect-Landscape - 4:3 Aspect.
@Property:Map-Aspect-Widescreen - 16:9 Aspect.
*/
.Map-Aspect-Square {--Map-Aspect-Ratio:1/1;}
.Map-Aspect-Portrait {--Map-Aspect-Ratio:3/4;}
.Map-Aspect-Landscape {--Map-Aspect-Ratio:4/3;}
.Map-Aspect-Widescreen {--Map-Aspect-Ratio:16/9;}

/*
@Name: Image Initials
@Summary: Monogram an image if no data available.
*/
[data-initials]:empty:before {display:flex; content: attr(data-initials); background:var(--Background-Primary); font:bold var(--Title); text-transform: uppercase; color: var(--Surface-Primary); padding: 50% 0 50%; line-height:0; visibility:visible; align-items:center; justify-content:center; z-index: 1; position:relative;}

/*
@Name: Image Monogram
@Summary: Monogram an image if no data available.
*/
[data-monogram]:before {display:flex; content: attr(data-monogram); background:var(--Background-Secondary); font:var(--Heading); text-transform: uppercase; color: var(--Surface-Primary); padding: 50% 0 50%; line-height:0; visibility:visible; align-items:center; justify-content:center; z-index: 1; position:relative;}


/* --- Summary --- */
[data-field="Summary"] * {-webkit-text-size-adjust:none;}
[data-field="Summary"] p {overflow-wrap: break-word; line-height:var(--Line-Spacing); margin:0 auto; max-width:var(--Max-Content-Width, none);}
[data-field="Summary"] p a {text-decoration: none; color:var(--Active);}
[data-field="Summary"] p.small {padding:0 0 var(--Spacing-Regular);}


/* --- Content --- */
[data-field="Content"] {font:var(--Body); color:var(--Surface-Primary);}
[data-field="Content"] p {padding:0 0 calc( var(--Line-Height) * 0.5); line-height:var(--Line-Height);}

[data-field="Content"] > * {-webkit-text-size-adjust:none;}
[data-field="Content"] > h1 {padding:0 0 var(--Spacing-Regular); font:var(--Heading); text-transform:var(--Heading-Case); color:var(--Surface-Primary); margin:0 auto; max-width:var(--Max-Content-Width, none);}
[data-field="Content"] > h2 {padding:0 0 var(--Spacing-Regular); font:var(--Subheading); text-transform:var(--Subheading-Case); color:var(--Surface-Primary); margin:0 auto; max-width:var(--Max-Content-Width, none);}
[data-field="Content"] > h3, [data-field="Content"] h4, [data-field="Content"] h5, [data-field="Content"] h6 {padding:0 0 var(--Spacing-Regular); font:var(--Sub-Subheading); text-transform:var(--Sub-Subheading-Case); color:var(--Surface-Primary); margin:0 auto; max-width:var(--Max-Content-Width, none);}

[data-field="Content"] > * + h1,
[data-field="Content"] > * + h2,
[data-field="Content"] > * + h3 {padding-top: var(--Spacing-Regular);}
[data-field="Content"] > p {padding:0 0 var(--Spacing-Wide); overflow-wrap: break-word; line-height:var(--Line-Spacing); margin:0 auto; max-width:var(--Max-Content-Width, none);}
[data-field="Content"] > * a {text-decoration: var(--Link-Decoration); font-weight:var(--Link-Weight); color:var(--Active);}

[data-field="Content"] > p.small {padding:0 0 var(--Spacing-Regular);}
[data-field="Content"] > blockquote {margin:0 auto var(--Spacing-Wide); padding:var(--Spacing-Regular) var(--Spacing-Wide) var(--Spacing-Narrow); background:var(--Background-Secondary); border-radius:var(--Quote-Radius); max-width:var(--Max-Content-Width, none);}
[data-field="Content"] > blockquote *:last-child {padding-bottom:0;}
[data-field="Content"] > p a.button {padding:var(--Spacing-Narrow) var(--Spacing-Wide); background:var(--Active-Primary); color:var(--Overlay-Primary); font:var(--Button); text-transform: var(--Button-Case); border-radius: var(--Button-Radius);}

[data-field="Content"] > .collection > .Group {--Page-Gutter:0px;}

/* --- Cover --- */
[data-field="Cover"] {position:relative; width:100%;}
[data-field="Cover"]:before {content: ""; display: block; padding-bottom: calc(100% / (var(--Cover-Aspect-Ratio, 16/9))); background:rgba(0,0,0,0.01);}
[data-field="Cover"] img {position: absolute; object-fit:cover; top: 0; left: 0; width: 100%; height: 100%; vertical-align: baseline;}
[data-field="Cover"]:after {position: absolute; content: ""; top: 0; left: 0; width: 100%; height: 100%; visibility:hidden;}
[data-field="Cover"]:empty:before {visibility:hidden;}
[data-field="Cover"]:empty:after {visibility: visible;}
section > [data-field="Cover"] {overflow:hidden;}


/* --- General Content Layout --- */
.Hide:not(:focus):not(:active) {clip:rect(0 0 0 0); height:0; margin:-1px; overflow:hidden; position:absolute; width:0;}

/* --- Hide Data and Nested Collections By Default --- */
[data-field], [data-collection] [data-collection], [data-fieldset]:empty {display:none; z-index:1;}


/* --- General Font Resizing for different available widths TODO Pull to Ln css --- */
.XL {font-size:1.1rem;}
.XS {font-size:0.9rem;}

/*
@Name: Time Format 1
@Summary: No year, shortend day name: 1:27pm Sun 5th March
*/
.Time-Format-1 time data span {display:none;}
.Time-Format-1 time [data-subset="Time"]:before {content:attr(data-format-h-mm-a);}
.Time-Format-1 time [data-subset="Day"]:before {content:attr(data-format-ddd);}
.Time-Format-1 time [data-subset="Day Of Month"]:before {content:attr(data-format-do);}
.Time-Format-1 time [data-subset="Month"]:before {content:attr(data-format-mmmm);}
.Time-Format-1 time [data-subset="Year"] {display:none;}

/* SM - 1:27pm 5th Mar */
.SM.Time-Format-1 time [data-subset="Day"] {display:none;}
.SM.Time-Format-1 time [data-subset="Month"]:before {content:attr(data-format-mmm);}

/* XS - 1:27pm 5 Mar */
.XS.Time-Format-1 time [data-subset="Time"]:before {content:attr(data-format-hh-mm);}
.XS.Time-Format-1 time [data-subset="Day"] {display:none;}
.XS.Time-Format-1 time [data-subset="Day Of Month"]:before {content:attr(data-format-d);}
.XS.Time-Format-1 time [data-subset="Month"]:before {content:attr(data-format-mmm);}

/*
@Name: Time Format 2
@Summary: Time only: 1:27pm
*/
.Time-Format-2 time [data-subset="Time"]:before {content:attr(data-format-h-mm-a);}
.Time-Format-2 time [data-subset="Time"] span {display:none;}
.Time-Format-2 time [data-subset="Day"] {display:none;}
.Time-Format-2 time [data-subset="Day Of Month"] {display:none;}
.Time-Format-2 time [data-subset="Month"] {display:none;}
.Time-Format-2 time [data-subset="Year"] {display:none;}

/*
@Name: Time Format 3
@Summary: No year, shortend day name, shortened day, shortened month: 1:27pm Sun 5 Mar
*/
.Time-Format-3 time data span {display:none;}
.Time-Format-3 time [data-subset="Time"]:before {content:attr(data-format-h-mm-a);}
.Time-Format-3 time [data-subset="Day"]:before {content:attr(data-format-ddd);}
.Time-Format-3 time [data-subset="Day Of Month"]:before {content:attr(data-format-d);}
.Time-Format-3 time [data-subset="Month"]:before {content:attr(data-format-mmm);}
.Time-Format-1 time [data-subset="Year"] {display:none;}

/* SM - 1:27pm 5th Mar */
.SM.Time-Format-3 time [data-subset="Day"] {display:none;}
.SM.Time-Format-1 time [data-subset="Month"]:before {content:attr(data-format-mmm);}

/* XS - 1:27pm 5 Mar */
.XS.Time-Format-3 time [data-subset="Time"]:before {content:attr(data-format-hh-mm);}
.XS.Time-Format-3 time [data-subset="Day"] {display:none;}
.XS.Time-Format-3 time [data-subset="Day Of Month"]:before {content:attr(data-format-d);}
.XS.Time-Format-3 time [data-subset="Month"]:before {content:attr(data-format-mmm);}

/*
@Name: Progress L1
@Summary: Circles for start and current, vertical orientation. SVG icon for end point.
*/
.Progress-L1 .Progress-Route {position:absolute; top:0; left:8px; z-index:1; width:4px; height:100%; background:var(--Progress-Route);}
.Progress-L1 .Progress-Transit {position:absolute; top:0; left:8px; z-index:2; width:4px; height:var(--Progress-Value); background:var(--Progress-Transit); margin:0 auto;}
.Progress-L1 .Progress-Start {position:absolute; top:0%; left:4px; z-index:4; width:12px; height:12px; margin-top:-6px; border-radius:50%; border:3px solid var(--Progress-Start); background:var(--Progress-Background);}
.Progress-L1 .Progress-Current {position:absolute; top:var(--Progress-Value); left:4px; z-index:4; width:12px; height:12px; margin-top:-6px; border-radius:50%; border:3px solid var(--Progress-Current); background:var(--Progress-Background);}
.Progress-L1 .Progress-Current span {position:absolute; color:var(--Progress-Current); top:-3px; right:12px; z-index:1; font:bold 11px Arial;}
.Progress-L1 .Progress-End {position:absolute; top:100%; left:0; z-index:5; width:20px; height:20px; margin-top:-10px;}


/* ----- Notifications ----- */
/*
@Name: Notifications Group
@Summary: Container for Notification Banners.
*/
.Notifications {--Notification-Filter:blur(15px) brightness(110%); grid-gap: var(--Spacing-Narrow); display:grid; grid-auto-rows:50px; grid-template-columns:1fr;}
.Notification {display:grid; grid-gap:0 var(--Spacing-Narrow); grid-template-columns:[backdrop-start] 0px [icon-start] auto [icon-end content-start] 1fr [content-end button-start] auto [button-end alt-button-start] auto [alt-button-end backdrop-end]; grid-template-rows:[backdrop-start icon-start content-start button-start alt-button-start] 50px [alt-button-end button-end content-end icon-end backdrop-end]; align-items: center; animation:vertical-fade-in var(--Slow,0.2s) ease 0s 1 normal both running; border-bottom:1px solid rgba(0,0,0,0.07); background-clip: border-box;   backdrop-filter:var(--Notification-Filter); -webkit-backdrop-filter: var(--Notification-Filter); border-radius: 10px; overflow: hidden;}
.Notification:before {grid-area:backdrop; z-index:-1; content:''; background:var(--Background-Secondary); align-self: stretch; opacity:0.25;}
.Notification > .Glyph {--Glyph-Scale: 0.7; grid-area:icon; display:block; width:35px;}
.Notification > .Glyph svg {fill:var(--Surface-Primary);}
.Notification > .Message {grid-area:content; font:var(--Caption); color:var(--Surface-Primary);}
.Notification > .Action {grid-area:button;}
.Notification > .Action + .Action {grid-area:alt-button;}

@supports not ( backdrop-filter:blur(10px) ) {
    .Notification:before {opacity:1;}
}


/* **************** New Palettes ******************** */

[data-palette="Editor"] {
    --Background-Primary:rgba(255,255,255,1);
    --Background-Secondary:rgba(246,246,246,1);
    --Surface-Primary:rgba(37,38,40,1);
    --Surface-Secondary:rgba(162,162,162,1);
    --Active-Primary:rgba(0,134,85,1);
    --Active-Secondary:rgba(233,233,233,1);
    --Overlay-Primary:rgba(255,255,255,1);
    --Overlay-Secondary:rgba(255,255,255,0.8);
    --Header-Primary:rgba(37,38,40,1);
    --Header-Secondary:rgba(68,69,71,1);
    --Title:var(--px20) "Typeface 3";
    --Title-Case:normal;
    --Subtitle:var(--px17) "Typeface 2";
    --Subtitle-Case:normal;
    --Heading:var(--px22) "Typeface 1";
    --Heading-Case:uppercase;
    --Subheading:var(--px18) "Typeface 1";
    --Subeading-Case:uppercase;
    --Sub-Subheading:var(--px12) "Typeface 2";
    --Sub-Subheading-Case:normal;
    --Body:var(--px16) "Typeface 3";
    --Link-Decoration:none;
    --Link-Weight:bold;
    --Byline:var(--px13) "Typeface 3";
    --Byline-Case:none;
    --Button:var(--px13) "Typeface 3";
    --Button-Case:none;
    --Caption:var(--px13) "Typeface 3";
    --Caption-Case:none;
    --Footnote:var(--px12) "Typeface 3";
    --Footnote-Case:none;

}

[data-palette="Editor-Popup"] {
    --Background-Primary:rgba(255,255,255,1);
    --Background-Secondary:rgba(246,246,246,1);
    --Surface-Primary:rgba(37,38,40,1);
    --Surface-Secondary:rgba(162,162,162,1);
    --Active-Primary:rgba(0,134,85,1);
    --Active-Secondary:rgba(233,233,233,1);
    --Overlay-Primary:rgba(255,255,255,1);
    --Overlay-Secondary:rgba(255,255,255,0.8);
    --Header-Primary:rgba(37,38,40,1);
    --Header-Secondary:rgba(68,69,71,1);
}


[data-palette="Editor-Popup-Banner"] {
    --Background-Primary:rgba(246,246,246,1);
}



/* **************** Popover ******************** */
.Block-Options-Popover {--Popover-Shadow:0px 5px 20px rgba(0,0,0,0.2);
    --Popover-Radius:15px; width:300px; height:300px; box-shadow:var(--Popover-Shadow); border-radius:var(--Popover-Radius); overflow:hidden; --Header-Height:50px; --Nav-Width:0px;}
