.media-block {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 3px 10px;
    grid-template-areas: "label label" "image title" "image details" "image footer";
}

.media-block .label {
    grid-area: label;
    font-size: 12px;
    font-weight: bold;
    color: #4a4a4a;
    font-family: Lato, Arial;
}
.media-block .image { grid-area: image; }
.media-block .image img { max-width: 120px; }

.media-block .title {
    grid-area: title;
    font-size: 15px;
    font-weight: bold;
    font-family: Lato, Arial;
}
.media-block .details {
    grid-area: details;
    font-size: 15px;
    font-family: Lato, Arial;
}
.media-block .footer {
    grid-area: footer;
    font-size: 13px;
    color: #666;
    font-family: Lato, Arial;
}