/* --- Media Element --- */
.Media {position: relative; width:100%; border-radius:var(--Image-Radius); overflow:hidden; margin: 0 0 var(--Line-Height);}
.Media:before {content: ""; display: block; padding-bottom: calc(100% / ( var( --Picture-Aspect-Ratio, 16/9 ) ) ); background:rgba(0,0,0,0.01);}
.Media img {position: absolute; top: 0; left: 0; width: 100%; vertical-align: baseline; border:var(--Image-Border-Size) solid var(--Overlay);}
.Media:after {position: absolute; content: ""; top: 0; left: 0; width: 100%; height: 100%; visibility:hidden;}

/* --- States --- */
/*
@Name:Is Full Bleed
@Description: Use negative margins to pull image to outer edge of <section> by totalled indent amounts
*/
.Media.Is-Full-Bleed  {border-radius:0; margin-left:calc( ( var(--Indent-Amount-Lvl-0, 0em) + var(--Indent-Amount-Lvl-1, 0em) + var(--Indent-Amount-Lvl-2, 0em) + var(--Indent-Amount-Lvl-3, 0em) + var(--Page-Gutter) ) * -1 ); width:calc( 100% + ( var(--Indent-Amount-Lvl-0, 0em) + var(--Indent-Amount-Lvl-1, 0em) + var(--Indent-Amount-Lvl-2, 0em) + var(--Indent-Amount-Lvl-3, 0em) + var(--Page-Gutter) + var(--Page-Gutter) ) );}

/*
@Name:Is Parallax
@Description: Use negative margins to pull image to outer edge of <section> by totalled indent amounts
*/
.Media.Is-Parallax img,
.Media.Is-Parallax video {transform:translateY( calc( ( var(--Media-OffsetTop, 0px) - var(--ScrollTop, 0px) ) * 0.08 ) ) scale(1.1);}

@media (prefers-reduced-motion) {
    .Media.Is-Parallax img,
    .Media.Is-Parallax video {transform: none;}
}
.Reduce-Motion .Media.Is-Parallax img,
.Reduce-Motion .Media.Is-Parallax video {transform: none;}

/*
@Name:Is Shadowed
@Description: Media Shadow is set in the Theme
*/
.Media.Is-Shadowed {box-shadow: var(--Media-Shadow);}

/*  Indents - Work In Progress */
.Indent-Space.IndentLvl-0 {padding-left:var(--Indent-Amount-Lvl-0);}
.Indent-Space.IndentLvl-1 {padding-left:var(--Indent-Amount-Lvl-1);}
.Indent-Space.IndentLvl-2 {padding-left:var(--Indent-Amount-Lvl-2);}
.Indent-Space.IndentLvl-3 {padding-left:var(--Indent-Amount-Lvl-3);}
