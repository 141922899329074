/*
@Name: Header Sticky
@Summary: Enables the Header to be sticky
@Property:Header-Sticky - A single item visible at all device widths.
*/
.Header-Sticky {position:-webkit-sticky; position:sticky; z-index:2; top:0; background:var(--Header-Background); -webkit-backdrop-filter:var(--Header-Backdrop); backdrop-filter:var(--Header-Backdrop);}

/*
@Name: Header Has Max Width
@Summary: Sets header to a max width
@Property:Has-Max-Width
*/
.collection header.Has-Max-Width {margin-left:auto; margin-right:auto; max-width:var(--Max-Content-Width, none);}

/*
@Name: Header 0
@Summary: Hide Header
*/
.Header-L0 {display:none;}

/*
@Name: Header 1
@Summary: Large Heading and right aligned button. Divider runs below indented from left but runs to right full width.
*/
.Header-L1 {grid-column:headings; display:grid; grid-template-columns: var(--Page-Gutter) [divider-start heading-start] auto [heading-end] var(--Spacing-Regular) [subheading-start] auto [subheading-end] minmax(var(--Spacing-Regular),1fr) [button-start] auto [button-end] var(--Page-Gutter) [divider-end]; grid-template-rows:var(--Spacing-Wide) [heading-start subheading-start button-start] auto [heading-end subheading-end button-end] var(--Spacing-Narrow) [divider-start] auto [divider-end]; margin:0 0 var(--Spacing-Regular); align-items: first baseline;}
.Header-L1:before {content:""; grid-area:divider; border-bottom:var(--Divider-Regular);}
.Header-L1 h1,
.Header-L1 h2 {grid-area:heading; font:var(--Heading); text-transform:var(--Heading-Case); color:var(--Header-Primary); align-self:end;}
.Header-L1 p {grid-area:subheading; font:var(--Caption); text-transform:var(--Caption-Case); color:var(--Surface-Secondary); align-self:end;}
.Header-L1 .Action {grid-area:button; align-self:end;}

/*
@Name: Header 2
@Summary: Small normal heading and subheading with right aligned button. No divider. Used for forms.
*/
.Header-L2 {grid-column:headings; display:grid; grid-template-columns: var(--Page-Gutter) [divider-start heading-start] auto [heading-end] var(--Spacing-Regular) [subheading-start] auto [subheading-end] minmax(var(--Spacing-Regular),1fr) [button-start] auto [button-end] var(--Page-Gutter) [divider-end]; grid-template-rows:var(--Spacing-Wide) [heading-start subheading-start button-start] auto [heading-end subheading-end button-end]; align-items: first baseline;}
.Header-L2 h1,
.Header-L2 h2 {grid-area:heading; font:var(--Caption); color:var(--Surface-Primary); align-self:end;}
.Header-L2 p {grid-area:subheading; font:var(--Caption); text-transform:var(--Caption-Case); color:var(--Surface-Secondary); align-self:end;}
.Header-L2 .Action {grid-area:button; align-self:end;}

/*
@Name: Header 3
@Summary: Hidden Heading and right aligned button. Divider runs below indented from left but runs to right full width.
*/
.Header-L3 {grid-column:headings; display:grid; grid-template-columns: var(--Page-Gutter) [divider-start button-start] 20px var(--Spacing-Narrow) [heading-start] auto [heading-end] var(--Spacing-Regular) [subheading-start] auto [subheading-end] minmax(var(--Spacing-Regular),1fr) [button-end] var(--Page-Gutter) [divider-end]; grid-template-rows:var(--Spacing-Wide) [heading-start subheading-start button-start] auto [heading-end subheading-end button-end] var(--Spacing-Narrow) [divider-start] auto [divider-end]; margin:0 0 var(--Spacing-Regular); align-items: center;}
.Header-L3:before {content:""; grid-area:divider; border-bottom:var(--Divider-Narrow);}
.Header-L3 h1,
.Header-L3 h2 {grid-area:heading; font:var(--Caption); text-transform:var(--Heading-Case); color:var(--Header-Primary);}
.Header-L3 p {grid-area:subheading; font:var(--Caption); text-transform:var(--Caption-Case); color:var(--Surface-Secondary);}
.Header-L3 .Action {grid-area:button; justify-self:stretch;}