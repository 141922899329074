.marktextfield { display: block;
    background: #F9F9F9;
    color: #4a4a4a;
    font-family: Lato, Arial;
    font-size: 16px;
    padding: 6px 10px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    height: 33px;
    resize: none;
}