/* --------------------- Sublisting --------------------- */

/*
@Name: Sublisting S1
@Summary: Small Inline Icon and Inline text. Title and Icons are grouped together in wrapping situations.
*/
.Sublisting-S1 {--Glyph-Scale:1; display:flex; flex-flow:row nowrap; align-items:center;}
.Sublisting-S1 [data-field="Icon"] {display:block; width:15px; margin-right:3px;}
.Sublisting-S1 [data-field="Icon"] svg {fill:var(--Surface-Secondary);}
.Sublisting-S1 [data-field="Title"] {display:block; color:var(--Surface-Secondary);}
.Sublisting-S1:not(:last-child):after {content:","; display:block; margin-right:3px;}

/*
@Name: Sublisting S2
@Summary: Small Inline Icon and Inline text. Title and Icons are grouped together in wrapping situations.
*/
.Sublisting-S2 {display:flex; flex-flow:row nowrap; align-items:center;}
.Sublisting-S2 [data-field="Icon"] {display:block; width:15px; margin-right:3px;}
.Sublisting-S2 [data-field="Icon"] svg {fill:var(--Surface-Primary);}
.Sublisting-S2 [data-field="Title"] {display:block; color:var(--Surface-Primary);}
.Sublisting-S2:not(:last-child):after {content:","; display:block; margin-right:3px;}

/*
@Name: Sublisting S3
@Summary: Inline Icon and Inline text, with rounded corners. Title and Icons are grouped together in wrapping situations.
*/
.Sublisting-S3 {display:flex; flex-flow:row nowrap; align-items:center; border-radius:1000px; background:var(--Background-Secondary); padding:var(--Spacing-Narrow) var(--Spacing-Wide); }
.Sublisting-S3 [data-field="Icon"] {display:block; width:15px; margin-right:3px;}
.Sublisting-S3 [data-field="Icon"] svg {fill:var(--Surface-Primary);}
.Sublisting-S3 [data-field="Title"] {display:block; color:var(--Surface-Primary);}