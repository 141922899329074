/* --- Profile Button Layout --- */
#Profile-Button {grid-area:profile; z-index:4; align-self: center; justify-self:start; margin-top: var(--Inset-Top);}

/* --- Hide Profile Button --- */
#Template.Hide-Profile {--Profile-Icon-Width:0px;}
#Template.Hide-Profile #Profile-Button {display:none;}

/* Close Button */
#Close-Button {grid-area:profile; z-index:3; align-self:center; }







