.Toolbar {position:-webkit-sticky; position:sticky; top:0px; z-index:5; background-color: rgba(245, 245, 245, 0.95);}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .Toolbar {-webkit-backdrop-filter:blur(20px) saturate(150%) brightness(95%); backdrop-filter:blur(20px) saturate(150%) brightness(95%); background-color: rgba(250, 250, 250, 0.05);}
}

/*
@Name: Search, Sort and Tools
@Summary: Top of every post record
*/
.SearchSortBar {display:grid; margin:var(--Spacing-Wide) 0; padding:var(--Spacing-Regular) var(--Page-Gutter) var(--Spacing-Regular); grid-gap:0 var(--Spacing-Narrow); grid-template-columns: [search-start tools-start slider-start filter-start] 1fr [search-end sort-start] max-content [sort-end tools-end seeall-start] auto [seeall-end] auto [slider-end filter-end]; grid-template-rows: [search-start sort-start] auto [sort-end search-end tools-start seeall-start] auto [tools-end seeall-end slider-start] auto [slider-end filter-start] auto [filter-end]; align-items:center; }
.Search {grid-area: search; margin-bottom: var(--Spacing-Regular);}
.Sort {grid-area: sort; margin-bottom: var(--Spacing-Regular);}
.Tools {grid-area: tools;}
.SearchSortBar .See-All {grid-area: seeall; margin: 5px var(--Spacing-Wide); }
.Tools .Func-AddRecord .Glyph svg {fill:var(--Active-Primary);}
.SearchSortBar .Slider { grid-area: slider; }
.SearchSortBar .Filter { grid-area: filter; }
.SearchSortBar.thin { margin:0; padding:0; background:none; position: relative; backdrop-filter: none; -webkit-backdrop-filter: none; }
.SearchSortBar.thin form { margin: var(--Spacing-Wide) 0px 0px var(--Spacing-Wide); }
.SearchSortBar.thin form+.Action { margin: var(--Spacing-Wide) var(--Spacing-Wide) 0px 0px; }
.SearchSortBar.thin .Tools { margin: 5px var(--Spacing-Wide); }
.SearchSortBar .IntersectControl { grid-area: slider; }

/*
@Name: Formatting
@Summary: Shows when editing content
*/
.FormattingBar {display:grid; margin:var(--Spacing-Wide) 0; padding:var(--Spacing-Narrow) var(--Page-Gutter) var(--Spacing-Regular); grid-gap:0 var(--Spacing-Narrow); grid-template-columns: [formatting-start] 1fr [formatting-end]; grid-template-rows:[formatting-start] auto [formatting-end]; align-items:center; animation:vertical-fade-in var(--Slow,0.2s) ease 0s 1 normal both running;}
.Formatting {grid-area: formatting;}



.Toolbar-Editing {display:inline-block; background:var(--Background-Primary); box-shadow: var(--Shadow); border-radius:1000px; padding:0 var(--Spacing-Regular);}
.Toolbar-Editing .Action {--Highlight-Margin: 3px;}



/*
 ******************* TODO ************************

 - Add notification aria-live area at same level as <main>
 - More complex/authentic Formatting bar
 - Card Arrangement
*/