/* --------------------- Group UL Margins --------------------- */
.Group > ul {margin-left:0;}
.Group > ul > li {list-style: none; }

/*
@Name: Grid
@Summary: Arranges collection items in a horizontally wrapping grid.
@Property:--Number-Of-Columns - Controls the number of horizontal columns in the grid. Columns will be scaled to fit exactly.
*/
.Group-Type-Grid > * {display:grid; grid-template-columns:repeat(var(--Number-Of-Columns,1),1fr); padding:0 calc( var(--Page-Gutter) * var(--Gutter-Indent,1) ); margin:var(--Row-Gap,0) 0; grid-gap:var(--Row-Gap,0px) var(--Column-Gap,0px); width:100%;}

/*
@Name: Group Type Auto Fit
@Summary: Arranges collection items in a horizontally wrapping grid.
@Property:--Number-Of-Columns - Controls the number of horizontal columns in the grid. Columns will be scaled to fit exactly.
*/
.Group-Type-Auto-Fit > * {display:grid; grid-template-columns: repeat(auto-fill, minmax(30px, auto)); margin:var(--Row-Gap,0) calc( var(--Page-Gutter) * var(--Gutter-Indent,1) ); grid-gap:var(--Row-Gap,0px) var(--Column-Gap,0px); width:100%;}

/*
@Name: Group Type Auto Fill
@Summary: Arranges collection items in a horizontally wrapping grid.
@Property:--Number-Of-Columns - Controls the number of horizontal columns in the grid. Columns will be scaled to fit exactly.
*/
.Group-Type-Auto-Fill > * {display:grid; grid-template-columns: repeat( auto-fill, minmax(auto, 1fr) ); padding:0 calc( var(--Page-Gutter) * var(--Gutter-Indent,1) ); margin:var(--Row-Gap,0) 0; grid-gap:var(--Row-Gap,0px) var(--Column-Gap,0px); width:100%;}


/*
@Name: Fit
@Summary: Arranges narrow collection items in evenly spaced slots. If the width is Medium or less revert to single column;
@Property:--Min--Column-Width - Controls the number of horizontal columns in the grid. Columns will be scaled to fit exactly.
*/
.Group-Type-Fit > * {--Column-Width:70px; padding:0 calc( var(--Page-Gutter) * var(--Gutter-Indent,1) ); margin-top:var(--Row-Gap,0px); }

.XS > .Group-Type-Fit > *,
.SM > .Group-Type-Fit > *,
.MD > .Group-Type-Fit > * {display:flex; flex-flow: column nowrap; }

.MD > .Group-Type-Fit.Group-Alignment-Left > * {align-items: start;}
.MD > .Group-Type-Fit.Group-Alignment-Center > * {align-items: center;}
.MD > .Group-Type-Fit.Group-Alignment-Right > * {align-items: end;}

.LG .Group-Type-Fit > *,
.XL .Group-Type-Fit > * {display:flex; justify-content:space-around; flex-flow: row nowrap;}

.Group-Type-Fit > * > * {margin-bottom:var(--Row-Gap,15px); margin-right:var(--Column-Gap,0px);}
.Group-Type-Fit > * > *:last-child {margin-bottom:0px; margin-right:0px;}

.LG .Group-Type-Fit > * > *,
.XL .Group-Type-Fit > * > * {width:var(--Column-Width);}

/*
@Name: Inline
@Summary: Items are listed in a single wrapping text line.
*/
.Group-Type-Inline {display:inline;}
.Group-Type-Inline > * {display:flex; flex-flow:row wrap; padding:0 calc( var(--Page-Gutter) * var(--Gutter-Indent,1) );}
.Group-Type-Inline > * > * {margin:0 var(--Column-Gap) var(--Row-Gap) 0;}
.Group-Type-Inline > * > *:last-child {margin-right:0;}

/*
@Name: Centered
@Summary: Items are listed in a single wrapping text line.
*/
.Group-Type-Centered {display:inline; }
.Group-Type-Centered > * {display:flex; flex-flow:row wrap; justify-content: center;}
.Group-Type-Centered > * > * {margin:0 var(--Column-Gap) var(--Row-Gap);}
.Group-Type-Centered > * > *:last-child {margin-right:0;}

/*
@Name: Scrolling Line
@Summary: Arranges collection items in a horizontally scrolling line of differing width items.
*/
.Group-Type-Scrolling-Line {display: grid; grid-template-columns:[header-start rows-start] auto [rows-end header-end]; grid-template-rows:[header-start] auto [header-end rows-start] auto [rows-end]; mask-image:var(--Scroll-Mask,none); -webkit-mask-image:var(--Scroll-Mask,none);}
.Group .Group.Group-Type-Scrolling-Line {--Gutter-Indent: 1; margin: 0 calc( var(--Page-Gutter) * var(--Gutter-Indent) * -1 );}
.Group-Type-Scrolling-Line > header {grid-area:header;}
.Group-Type-Scrolling-Line > *:not(.Paging) {grid-area:rows; display:grid; grid-gap:0 var(--Column-Gap,0px); grid-auto-flow: column; grid-auto-columns:var(--Column-Width,max-content); overflow-x: scroll; overflow-y: hidden; justify-content:start; -webkit-overflow-scrolling:touch; padding:var(--Row-Gap) 0 var(--Row-Gap) calc( var(--Page-Gutter) * var(--Gutter-Indent,1) ); -ms-overflow-style: none; scrollbar-width: none;}
.Group-Type-Scrolling-Line > *:not(.Paging)::-webkit-scrollbar {display: none;}

/* After element column is added to apply a gutter after the final element in the list. */
.Group-Type-Scrolling-Line > *:not(.Paging):after {content: ''; width: calc( ( var(--Page-Gutter) * var(--Gutter-Indent,1) ) - var(--Column-Gap)); min-width:0.1px; order:999;}
.Group-Type-Scrolling-Line > *:not(.Paging) > * {scroll-snap-align: center; margin:var(--Row-Gap,0) 0; width: var(--Column-Width,auto); align-self: start;}

/*
@Name: Scrolling Row
@Summary: Arranges collection items in a horizontally scrolling row. Scrollbar is hidden.
@Property:--Number-Of-Columns - Controls the number of complete non cropped horizontal items visible. The last visible item is always cropped in half to indicate there are more items to show.
*/
.Group-Type-Scrolling-Row {display: grid; grid-template-columns:[header-start rows-start] auto [rows-end header-end]; grid-template-rows:[header-start] auto [header-end rows-start] auto [rows-end]; mask-image:var(--Scroll-Mask,none); -webkit-mask-image:var(--Scroll-Mask,none);}
.Group .Group.Group-Type-Scrolling-Row {--Gutter-Indent: 1; margin: 0 calc( var(--Page-Gutter) * var(--Gutter-Indent) * -1 );}
.Group-Type-Scrolling-Row > header {grid-area:header;}
.Group-Type-Scrolling-Row > *:not(.Paging) {grid-area:rows; display:grid; grid-gap:var(--Row-Gap,0px) var(--Column-Gap,0px); grid-auto-flow: column; grid-auto-columns:calc( (100% - ( var(--Number-Of-Columns,1) * var(--Column-Gap) ) ) / ( var(--Number-Of-Columns,1) + var(--Column-Crop,0.5) ) ); overflow-x: scroll; overflow-y: hidden; justify-content: flex-start; scroll-snap-type: x mandatory; -webkit-overflow-scrolling:touch; padding:0 0 0 calc( var(--Page-Gutter) * var(--Gutter-Indent,1) ); -ms-overflow-style: none; scrollbar-width: none;}
.Group-Type-Scrolling-Row > *:not(.Paging)::-webkit-scrollbar {display: none;}

/* After element column is added to apply a gutter after the final element in the list. */
.Group-Type-Scrolling-Row > *:not(.Paging):after {content: ''; width: calc( ( var(--Page-Gutter) * var(--Gutter-Indent,1) ) - var(--Column-Gap)); min-width:0.1px; order:999;}
.Group-Type-Scrolling-Row > *:not(.Paging) > * {scroll-snap-align: center; margin-top:var(--Row-Gap,0);}

/*
@Name: Scrolling Rows
@Summary: Arranges collection items in a double height horizontally scrolling row.
@Property:--Number-Of-Columns - Controls the number of complete non cropped horizontal items visible. The last visible item is always cropped in half to indicate there are more items to show.
@Property:--Focus-Outset - Adds margin on the top and bottom to allow highlight and focus to show without being cropped by the scrollbar clip.
@Property:--Scrollbar-Height - This increase the area at the bottom and the clips it back by the same amount. It is designed to hide the scroll bar and create a more app like experience. Setting to zero will show the scrollbar.
*/
.Group-Type-Scrolling-Rows {display: grid; grid-template-columns:[header-start rows-start] auto [rows-end header-end]; grid-template-rows:[header-start] auto [header-end rows-start] auto [rows-end]; mask-image:var(--Scroll-Mask,none); -webkit-mask-image:var(--Scroll-Mask,none);}
.Group .Group.Group-Type-Scrolling-Rows {--Gutter-Indent: 1; margin: 0 calc( var(--Page-Gutter) * var(--Gutter-Indent) * -1 );}
.Group-Type-Scrolling-Rows > *:not(.Paging) {grid-area:rows; display: grid; grid-gap:var(--Row-Gap,0px) var(--Column-Gap,0px); grid-template-rows: auto auto; grid-auto-flow:column; grid-auto-columns: calc( (100% - (var(--Column-Gap) * (var(--Number-Of-Columns,1) - 1))) / (var(--Number-Of-Columns,1) + var(--Column-Crop,0.5)) ); overflow-x: scroll; overflow-y: hidden; justify-content: flex-start; scroll-snap-type: x mandatory; -webkit-overflow-scrolling:touch; padding:0 0 0 calc( var(--Page-Gutter) * var(--Gutter-Indent,1) ); -ms-overflow-style: none; scrollbar-width: none;}
.Group-Type-Scrolling-Rows > *:not(.Paging)::-webkit-scrollbar {display: none;}
/* After element column is added to apply a gutter after the final element in the list. */
.Group-Type-Scrolling-Rows > *:not(.Paging):after {content: ''; width: calc( ( var(--Page-Gutter) * var(--Gutter-Indent,1) ) - var(--Column-Gap)); min-width:0.1px; order:999;}
.Group-Type-Scrolling-Rows > *:not(.Paging) > * {scroll-snap-align:center;}
/* Only each even item is padded */
.Group-Type-Scrolling-Rows > *:not(.Paging) > *:nth-child(odd) {margin-top:var(--Row-Gap,0);}
/* Final column item is set to span both rows vertically if the number of items is odd. This ensures the final after element is added at the end of the list. */
.Group-Type-Scrolling-Rows > *:not(.Paging) > *:last-child:nth-child(odd) {grid-row-end: span 2;}

/* --- Adding hidden scroll bar support for < 14 iOS versions --- */
@supports not (line-break: anywhere) {
    [class^="Group-Type-Scrolling"] { --Scrollbar-Height:16px; --Hinted-Padding:10px; clip-path: inset(0 0 var(--Scrollbar-Height) 0); -webkit-clip-path: inset(0 0 var(--Scrollbar-Height) 0); margin-top:calc( var(--Hinted-Padding) * -1 ); margin-bottom:calc( ( var(--Scrollbar-Height) * -1 ) - var(--Hinted-Padding) );}
    [class^="Group-Type-Scrolling"] > *:not(.Paging) {padding-top:var(--Hinted-Padding); padding-bottom:var(--Hinted-Padding);}
    [class^="Group-Type-Scrolling"] > *:not(.Paging) > * {margin-bottom:var(--Scrollbar-Height);}
}

/* --------------------- Custom Properties --------------------- */

/*
@Name: Paging
@Summary: Left / Right Scroll buttons for Scrollable Row(s).
*/
.Action.Paging.Is-Interactive {display:none;}
@media (hover: hover) {
    .Group-Type-Scrolling-Row .Action.Paging,
    .Group-Type-Scrolling-Rows .Action.Paging,
    .Group-Type-Scrolling-Line .Action.Paging {grid-area:rows; display:grid; z-index:10; width:auto; align-self:center; opacity:0; transition-duration: var(--Slow); transform: scale(0.8);}
    .Group-Type-Scrolling-Row:hover .Action.Paging,
    .Group-Type-Scrolling-Rows:hover .Action.Paging,
    .Group-Type-Scrolling-Line:hover .Action.Paging {opacity:1; transform: scale(1);}
    .Group-Type-Scrolling-Row .Action.Paging.Paging-Left,
    .Group-Type-Scrolling-Rows .Action.Paging.Paging-Left,
    .Group-Type-Scrolling-Line .Action.Paging.Paging-Left {margin-left:var(--Page-Gutter); justify-self:start;}
    .Group-Type-Scrolling-Row .Action.Paging.Paging-Right,
    .Group-Type-Scrolling-Rows .Action.Paging.Paging-Right,
    .Group-Type-Scrolling-Line .Action.Paging.Paging-Right {margin-right:var(--Page-Gutter); justify-self:end;}
}

/*
@Name: Columns Crop
@Summary: Adjusts amount of crop for scrollable rows.
@Property:Columns-No-Crop - Crop amount is zero.
@Property:Columns-Minimal-Crop - Crop is a twentyith of item width.
@Property:Columns-Hal-Crop - Crop is a half of item width.
*/
.Group-Type-Scrolling-Row.Group-Crop-None,
.Group-Type-Scrolling-Rows.Group-Crop-None {--Column-Crop:0; --Column-Adjustment:1;}
.Group-Type-Scrolling-Row.Group-Crop-Minimal,
.Group-Type-Scrolling-Rows.Group-Crop-Minimal {--Column-Crop:0.1; --Column-Adjustment:0;}
.Group-Type-Scrolling-Row.Group-Crop-Half,
.Group-Type-Scrolling-Rows.Group-Crop-Half {--Column-Crop:0.5; --Column-Adjustment:0;}
.Group-Type-Scrolling-Row.Group-Crop-Quarter,
.Group-Type-Scrolling-Rows.Group-Crop-Quarter {--Column-Crop:0.75; --Column-Adjustment:0;}
.Group-Type-Scrolling-Row.Group-Crop-Three-Quarter,
.Group-Type-Scrolling-Rows.Group-Crop-Three-Quarter {--Column-Crop:0.25; --Column-Adjustment:0;}

/*
@Name: Column Spacing
@Summary: Choose from no or wider spacing.
@Property:Columns-Spaced - Wider horizontal spacing between items.
@Property:Columns-No-Spacing - No spacing between items.
*/
.Group-Column-Gap-Match-Gutter {--Column-Gap:var(--Page-Gutter);}
.Group-Column-Gap-Wide {--Column-Gap:var(--Spacing-Wide);}
.Group-Column-Gap-Regular {--Column-Gap:var(--Spacing-Regular);}
.Group-Column-Gap-Narrow {--Column-Gap:var(--Spacing-Narrow);}
.Group-Column-Gap-None {--Column-Gap:0px;}

/*
@Name: Row Spacing
@Summary: Choose from none or wider spacing.
@Property:Columns-Spaced - Wider horizontal spacing between items.
@Property:Columns-No-Spacing - No spacing between items.
*/

.Group-Row-Gap-Extra-Wide {--Row-Gap:calc(var(--Spacing-Wide) * 2);}
.Group-Row-Gap-Wide {--Row-Gap:var(--Spacing-Wide);}
.Group-Row-Gap-Regular {--Row-Gap:var(--Spacing-Regular);}
.Group-Row-Gap-Narrow {--Row-Gap:var(--Spacing-Narrow);}
.Group-Row-Gap-None {--Row-Gap:0px;}

/*
@Name: Scroll Mask
@Summary: Fades out items in the scroll row to the left and right as they near the edge.
@Property:Scroll-Mask - Scroll Mask is applied.
*/
.Group-Scroll-Mask-None {--Scroll-Mask:none;}
.Group-Scroll-Mask-Regular {--Scroll-Mask-Width:calc( var(--Page-Gutter) + var(--Column-Gap,0px) ); --Scroll-Mask:linear-gradient(to right, transparent 0%, rgb(0, 0, 0) var(--Scroll-Mask-Width), rgb(0, 0, 0) calc( 100% - var(--Scroll-Mask-Width) ), transparent 100%);}

/*
@Name: Is Full Bleed
@Summary: Adjusts Gutter width page edges. Expands to the full width of the page or indents. Useful for galleries.
*/
.Group.Is-Full-Bleed {--Gutter-Indent:0;}

/*
@Name: Has Max Width
@Summary: Adds a max page width to the group.
*/
.Group.Has-Max-Width:not(Group-Type-Scrolling-Row),
.Group.Has-Max-Width:not(Group-Type-Scrolling-Rows) {margin-left:auto; margin-right:auto;  max-width:var(--Max-Content-Width, none);}
.Group.Has-Max-Width.Group-Type-Scrolling-Row > :not(.Paging),
.Group.Has-Max-Width.Group-Type-Scrolling-Rows > :not(.Paging) {padding-left: calc( ( 100% - var(--Max-Content-Width) ) * 0.5 );}

/*
@Name: Is Nested
@Summary: Adjusts Gutter for groups that need indentation. Defaults to none.
*/
.Group .Group {--Gutter-Indent:0;}
.Group.Is-Nested {--Gutter-Indent:1;}

/*
@Name: Fixed Column Amounts
@Summary: Choose from a fixed number of columns.
*/
.Group-Columns-Single {--Number-Of-Columns:1;}
.Group-Columns-Double {--Number-Of-Columns:2;}
.Group-Columns-Triple {--Number-Of-Columns:3;}
.Group-Columns-Quadruple {--Number-Of-Columns:4;}
.Group-Columns-Quintuple {--Number-Of-Columns:5;}

/*
@Name: Flexible Column Amounts
@Summary: Choose from a dynamic number of columns. Dynamic columns add more visible items as the width of the device increases.
*/
section.XS .Group-Columns-Very-Sparse {--Number-Of-Columns:1;}
section.SM .Group-Columns-Very-Sparse {--Number-Of-Columns:1;}
section.MD .Group-Columns-Very-Sparse {--Number-Of-Columns:1;}
section.LG .Group-Columns-Very-Sparse {--Number-Of-Columns:2;}
section.XL .Group-Columns-Very-Sparse {--Number-Of-Columns:3;}

section.XS .Group-Columns-Sparse {--Number-Of-Columns:1;}
section.SM .Group-Columns-Sparse {--Number-Of-Columns:1;}
section.MD .Group-Columns-Sparse {--Number-Of-Columns:2;}
section.LG .Group-Columns-Sparse {--Number-Of-Columns:3;}
section.XL .Group-Columns-Sparse {--Number-Of-Columns:5;}

section.XS .Group-Columns-Moderate {--Number-Of-Columns:2;}
section.SM .Group-Columns-Moderate {--Number-Of-Columns:2;}
section.MD .Group-Columns-Moderate {--Number-Of-Columns:3;}
section.LG .Group-Columns-Moderate {--Number-Of-Columns:5;}
section.XL .Group-Columns-Moderate {--Number-Of-Columns:7;}

section.XS .Group-Columns-Dense {--Number-Of-Columns:2;}
section.SM .Group-Columns-Dense {--Number-Of-Columns:3;}
section.MD .Group-Columns-Dense {--Number-Of-Columns:3;}
section.LG .Group-Columns-Dense {--Number-Of-Columns:6;}
section.XL .Group-Columns-Dense {--Number-Of-Columns:8;}

section.XS .Group-Columns-Very-Dense {--Number-Of-Columns:3;}
section.SM .Group-Columns-Very-Dense {--Number-Of-Columns:4;}
section.MD .Group-Columns-Very-Dense {--Number-Of-Columns:5;}
section.LG .Group-Columns-Very-Dense {--Number-Of-Columns:7;}
section.XL .Group-Columns-Very-Dense {--Number-Of-Columns:9;}

/*
@Name: Fixed Column Widths
@Summary: Choose from a fixed set of widths
*/
.Group-Columns-Fixed-Very-Thin {--Column-Width:60px;}
.Group-Columns-Fixed-Thin {--Column-Width:80px;}
.Group-Columns-Fixed-Medium {--Column-Width:120px;}
.Group-Columns-Fixed-Wide {--Column-Width:160px;}
.Group-Columns-Fixed-Extra-Wide {--Column-Width:200px;}
.Group-Columns-Flexible {--Column-Width:auto;}

/*
@Name: Column Span
@Summary: Applied to individual items in a collection. Allows them to span columns. Best used in form field grids.
*/
.Group-Columns-Span-1 {grid-column-end: span 1; flex-basis:calc( var(--Column-Width) * 1 );}
.Group-Columns-Span-2 {grid-column-end: span 2; flex-basis:calc( var(--Column-Width) * 2 );}
.Group-Columns-Span-3 {grid-column-end: span 3; flex-basis:calc( var(--Column-Width) * 3 );}
.Group-Columns-Span-4 {grid-column-end: span 4; flex-basis:calc( var(--Column-Width) * 4 );}

/*
@Name: Direction
@Summary: Change direction flow of a line group. Note that items reverse order flow, it is not alignment.
*/
.Group-Alignment-LTR {direction:ltr;}
.Group-Alignment-RTL {direction:rtl;}

/*
@Name: Alignment
@Summary: Change direction flow of a line group. Note that items reverse order flow, it is not alignment.
*/


/* Test Group Sizing */
.Group-Sizing-MD-SM {}

.Group-Sizing-LG-MD {}