/* ------------------------------ Arrangement-1 ------------------------------ */
/*
@Name: Arrangement-1
@Summary: Horizontal Columns which push in from right to left. Header bar is fixed at top and solid. Section below does not flow under.
*/
.Arrangement-1 {--Page-Gutter:15px;}
.wide .Arrangement-1 {--Page-Gutter:25px;}
.regular .Arrangement-1 {--Page-Gutter:20px;}
.narrow .Arrangement-1 {--Page-Gutter:15px;}
.thin .Arrangement-1 {--Page-Gutter:10px;}
.short .Arrangement-1 {--Page-Gutter:10px;}

/* Setup */
.Arrangement-1 {--Page-Transition-Speed:var(--Slow); z-index:0; pointer-events: none; transition-delay:calc( var(--Page-Transition-Speed) * 0.5 ); overflow:hidden; width:100%; height:100%;}
.Arrangement-1.Stack-Active {z-index:1; pointer-events: auto;}
.Arrangement-1 > article {--Cover-Height:200px; position:absolute; top:0; left:0; width:100%; height:100%; z-index:0; overflow:hidden; display: grid; grid-template-columns:[header-start section-start cover-start] 1fr [ cover-end header-end section-end]; grid-template-rows:[header-start] var(--Header-Height) [header-end section-start cover-start] 1fr [ cover-end section-end footer-start] auto [footer-end]; margin-left:0%; background:var(--Background-Primary);}

/* ----- Width Variations ----- */

/* LG Arrangement - Two Pages Side By Side */
/* Set all Articles to default To left at half width with no pointer events  */
.LG.Arrangement-1 > article[class*="Layer-Lvl"] {--Cover-Height:250px; opacity:0; z-index:2; left:0; border-left:var(--Nav-Width-Inset) solid transparent; border-top:var(--Nav-Height-Inset) solid transparent; width:calc(50% + (var(--Nav-Width-Inset) * 0.5)); pointer-events:none; transition-duration: var(--Page-Transition-Speed); transition-property: opacity;}
/* Shift right half way if Layer-Lvl1 */
.LG.Arrangement-1 > article.Layer-Lvl1 {border-left:none; left:calc(50% + (var(--Nav-Width-Inset) * 0.5)); width: calc(50% - (var(--Nav-Width-Inset) * 0.5));}
/* If Layer-Root, set to full width */
.LG.Arrangement-1 > article.Layer-Lvl1.Layer-Root {left:0; border-left:var(--Nav-Width-Inset) solid transparent; border-top:var(--Nav-Height-Inset) solid transparent; width:100%;}
/* Turn on pointer events for all Lvl1 & 2 Current Articles */
.LG.Arrangement-1 > article.Layer-Lvl1.Layer-Current,
.LG.Arrangement-1 > article.Layer-Lvl2.Layer-Current {opacity:1; z-index:1; pointer-events: auto;}
/* Add Page Divider when showing two pages  */
.LG.Arrangement-1 > article:not(.Layer-Root).Layer-Lvl1 > section:before {position:absolute; z-index:99; content:""; left:0; top:0; width:1px; height:100%; background:rgba(128,128,128,0.1);}

/* MD */
/* Set all Articles to default To left at half width with no pointer events  */
.MD.Arrangement-1 > article[class*="Layer-Lvl"] {opacity:0; z-index:2; left:0; border-left:var(--Nav-Width-Inset) solid transparent; border-top:var(--Nav-Height-Inset) solid transparent; width:100%; pointer-events:none; transition-duration: var(--Page-Transition-Speed); transition-property: opacity;}
/* Turn on pointer events for all Lvl1 Current Articles */
.MD.Arrangement-1 > article.Layer-Lvl1.Layer-Current {opacity:1; z-index:1; pointer-events: auto;}

/* SM */
/* Set all Articles to default To left at half width with no pointer events  */
.SM.Arrangement-1 > article[class*="Layer-Lvl"] {opacity:0; z-index:1; left:0; border-left:var(--Nav-Width-Inset) solid transparent; border-top:var(--Nav-Height-Inset) solid transparent; width:100%; pointer-events:none; transition-duration: var(--Page-Transition-Speed); transition-property: opacity;}
/* Turn on pointer events for all Lvl1 Current Articles */
.SM.Arrangement-1 > article.Layer-Lvl1.Layer-Current {opacity:1; z-index:2; pointer-events: auto;}

/* ----- Internal Elements ----- */

/* Cover */
.Arrangement-1 article > [data-field="Cover"] {grid-area:cover; display: block; z-index:-1; background: var(--Cover-Background);}
.Arrangement-1 article > [data-field="Cover"] img {height:var(--Cover-Height); width:calc( 100% + var(--Page-Gutter) ); margin-left:calc( var(--Page-Gutter) * -0.5 );}
.Arrangement-1 article > [data-field="Cover"]:after {z-index:1; height:var(--Cover-Height); visibility: visible; background:var(--Cover-Overlay);}
.Arrangement-1 article > [data-field="Cover"]:empty {display:none;}
.Arrangement-1 article > [data-field="Cover"]:empty + section > .Card {--Cover-Height:0px; --Header-Height:0px;}

/* Header */
.Arrangement-1 article > header {grid-area:header; display:grid; background:var(--Background-Primary); font:var(--Title); text-transform:var(--Title-Case); color:var(--Surface-Primary);grid-template-columns:[button-start] auto [button-end title-start] 1fr [title-end] var(--Search-Icon-Width, 0px) var(--Profile-Icon-Width, 0px); grid-template-rows:[title-start button-start] 1fr [button-end title-end]; align-items: center; height:var(--Header-Height); padding:var(--Inset-Top) var(--Page-Gutter) 0 var(--Page-Gutter);}
.Arrangement-1 > article > header > :not(.Action) {grid-area: title; /*! padding-right:calc(var(--Search-Icon-Width) + var(--Profile-Icon-Width) - var(--Page-Gutter)); */ display: -webkit-box; -webkit-line-clamp: 2; outline-style:none; -moz-outline-style: none; overflow:hidden; text-overflow:ellipsis; -webkit-box-orient: vertical; position:relative; top:var(--Leading-Adjust,0px);}
.Arrangement-1 > article > header > :not(.Action)::-moz-focus-inner {border:0;}

/* Header Button */
.Arrangement-1 > article > header .Action {grid-area: button; margin:0 var(--Spacing-Narrow) 0 calc( ( var(--Page-Gutter) - var(--Spacing-Narrow) ) * -1 );}
.Template .LG.Arrangement-1 > article.Layer-Lvl1 header .Action [data-field="Icon Close"] svg {transform:scale( var(--Glyph-Scale) );  opacity:1;}
.Template .LG.Arrangement-1 > article.Layer-Lvl1 header .Action [data-field="Icon Back"] svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) );  opacity:0;}

/* Section */
.Arrangement-1  > article > section {grid-area:section; width:100%; overflow-y:scroll; -webkit-overflow-scrolling:touch;}


/* ----- Stack Animation States ----- */

/* Initialise Out */
.Arrangement-1:not(.Stack-Active) > article > header > p,
.Arrangement-1:not(.Stack-Active) > article > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-1:not(.Stack-Active) > article > header > .Action {--Scale-Position:0.25; animation:scale-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-1:not(.Stack-Active) > article > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); animation:horizontal-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running!important;}
/* Initialise In */
.Arrangement-1.Stack-Init.Stack-Active > article > header > p,
.Arrangement-1.Stack-Init.Stack-Active > article > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-1.Stack-Init.Stack-Active > article > header > .Action {--Scale-Position:0.25; animation:scale-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-1.Stack-Init.Stack-Active > article > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); animation:horizontal-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}

/* Push Out */
.Arrangement-1.Stack-Push.Stack-Animating > article.Layer-Lvl3 > header > p,
.Arrangement-1.Stack-Push.Stack-Animating > article.Layer-Lvl3 > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-out var(--Page-Transition-Speed) ease 0s 1 normal both running;}
.Arrangement-1.Stack-Push.Stack-Animating > article > header > .Action {--Scale-Position:0.25; animation:scale-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-1.Stack-Push.Stack-Animating > article.Layer-Lvl3 > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); --Fade-Opacity:1; animation:horizontal-fade-out var(--Page-Transition-Speed) ease 0s 1 normal both running;}
/* Push In */
.Arrangement-1.Stack-Push.Stack-Animating > article.Layer-Current > header > p,
.Arrangement-1.Stack-Push.Stack-Animating > article.Layer-Current > header > .Action,
.Arrangement-1.Stack-Push.Stack-Animating > article.Layer-Current > section {--Horizontal-Position:calc( var(--Page-Gutter) * 0.75 ); animation:horizontal-fade-in var(--Page-Transition-Speed) ease 0s 1 normal both running;}
.Arrangement-1.Stack-Push.Stack-Animating > article.Layer-Current.Layer-Lvl1 > header > .Action {--Scale-Position:0.25; animation:scale-fade-in var(--Page-Transition-Speed) ease 0s 1 normal both running;}
.Arrangement-1.Stack-Push.Stack-Animating > article.Layer-Current > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * 0.25 ); --Fade-Opacity:1; animation:horizontal-fade-in var(--Page-Transition-Speed) ease 0s 1 normal both running;}

/* Pop Out */
.Arrangement-1.Stack-Pop.Stack-Animating > article.Layer-Lvl1 > header > p,
.Arrangement-1.Stack-Pop.Stack-Animating > article.Layer-Lvl1 > section {--Horizontal-Position:calc( var(--Page-Gutter) * 0.75 ); animation:horizontal-fade-out var(--Page-Transition-Speed) ease 0s 1 normal both running;}
.Arrangement-1.Stack-Pop.Stack-Animating > article.Layer-Lvl1  > header > .Action {--Scale-Position:0.25; animation:scale-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-1.Stack-Pop.Stack-Animating > article.Layer-Lvl1 > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * 0.25 ); --Fade-Opacity:1; animation:horizontal-fade-out var(--Page-Transition-Speed) ease 0s 1 normal both running;}

/* Pop In */
.Arrangement-1.Stack-Pop.Stack-Animating > article.Layer-Current > header > p,
.Arrangement-1.Stack-Pop.Stack-Animating > article.Layer-Current > header >.Action,
.Arrangement-1.Stack-Pop.Stack-Animating > article.Layer-Current > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-in var(--Page-Transition-Speed) ease 0s 1 normal both running;}
.Arrangement-1.Stack-Pop.Stack-Animating > article.Layer-Current > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); --Fade-Opacity:1; animation:horizontal-fade-in var(--Page-Transition-Speed) ease 0s 1 normal both running;}

/* Swap Out */
.Arrangement-1.Stack-Swap.Stack-Animating > article.Layer-Lvl1 > header > p,
.Arrangement-1.Stack-Swap.Stack-Animating > article.Layer-Lvl1 > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-1.Stack-Swap.Stack-Animating > article.Layer-Lvl1  > header > .Action {--Scale-Position:0.25; animation:scale-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-1.Stack-Swap.Stack-Animating > article.Layer-Lvl1 > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); --Fade-Opacity:1; animation:horizontal-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}

/* Swap In */
.Arrangement-1.Stack-Swap.Stack-Animating > article.Layer-Lvl1.Layer-Current > header > p,
.Arrangement-1.Stack-Swap.Stack-Animating > article.Layer-Lvl1.Layer-Current > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-1.Stack-Swap.Stack-Animating > article.Layer-Lvl1.Layer-Current > header > .Action {--Scale-Position:0.25; animation:scale-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-1.Stack-Swap.Stack-Animating > article.Layer-Lvl1.Layer-Current > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); --Fade-Opacity:1; animation:horizontal-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}

/* ------------------------------ Arrangement-2 ------------------------------ */
/*
@Name: Arrangement-2
@Summary: Single horizontal columns which push in from right to left. Transparent or translucent Header - header does not appear on root screen.
*/
.Arrangement-2 {--Page-Gutter:15px;}
.wide .Arrangement-2 {--Page-Gutter:25px;}
.regular .Arrangement-2 {--Page-Gutter:20px;}
.narrow .Arrangement-2 {--Page-Gutter:15px;}
.thin .Arrangement-2 {--Page-Gutter:10px;}
.short .Arrangement-2 {--Page-Gutter:10px;}

/* Setup */
.Arrangement-2 {--Page-Transition-Speed:var(--Slow); z-index:0; pointer-events: none; transition-delay:calc( var(--Page-Transition-Speed) * 0.5 ); overflow:hidden; width:100%; height:100%;}
.Arrangement-2.Stack-Active {z-index:1; pointer-events: auto;}
.Arrangement-2 > article {--Cover-Height:200px; position:absolute; top:0; left:0; width:100%; height:100%; z-index:0; overflow:hidden; display: grid; grid-template-columns:[header-start section-start cover-start] 1fr [ cover-end header-end section-end]; grid-template-rows:[cover-start header-start  section-start] var(--Header-Height) [header-end] 1fr [cover-end section-end]; margin-left:0%; background:var(--Background-Primary);}

/* ----- Width Variations ----- */

/* LG Arrangement - Two Pages Side By Side */
/* Set all Articles to default To left at half width with no pointer events  */
.LG.Arrangement-2 > article[class*="Layer-Lvl"] {--Cover-Height:250px; opacity:0; z-index:2; left:0; border-left:var(--Nav-Width-Inset) solid transparent;  border-top:var(--Nav-Height-Inset) solid transparent; width:calc(50% + (var(--Nav-Width-Inset) * 0.5)); pointer-events:none; transition-duration: var(--Page-Transition-Speed); transition-property: opacity;}
/* Shift right half way if Layer-Lvl1 */
.LG.Arrangement-2 > article.Layer-Lvl1 {border-left:none; left:calc(50% + (var(--Nav-Width-Inset) * 0.5)); width: calc(50% - (var(--Nav-Width-Inset) * 0.5));}
/* If Layer-Root, set to full width */
.LG.Arrangement-2 > article.Layer-Lvl1.Layer-Root {left:0; border-left:var(--Nav-Width-Inset) solid transparent; width:100%;}
/* Turn on pointer events for all Lvl1 & 2 Current Articles */
.LG.Arrangement-2 > article.Layer-Lvl1.Layer-Current,
.LG.Arrangement-2 > article.Layer-Lvl2.Layer-Current {opacity:1; z-index:1; pointer-events: auto;}
/* Add Page Divider when showing two pages  */
.LG.Arrangement-2 > article:not(.Layer-Root).Layer-Lvl1 > section:before {position:absolute; z-index:99; content:""; left:0; top:0; width:1px; height:100%; background:rgba(128,128,128,0.1)}

/* MD */
/* Set all Articles to default To left at half width with no pointer events  */
.MD.Arrangement-2 > article[class*="Layer-Lvl"] {opacity:0; z-index:2; left:0; border-left:var(--Nav-Width-Inset) solid transparent; border-top:var(--Nav-Height-Inset) solid transparent; width:100%; pointer-events:none; transition-duration: var(--Page-Transition-Speed); transition-property: opacity;}
/* Turn on pointer events for all Lvl1 Current Articles */
.MD.Arrangement-2 > article.Layer-Lvl1.Layer-Current {opacity:1; z-index:1; pointer-events: auto;}

/* SM */
/* Set all Articles to default To left at half width with no pointer events  */
.SM.Arrangement-2 > article[class*="Layer-Lvl"] {opacity:0; z-index:2; left:0; border-left:var(--Nav-Width-Inset) solid transparent; border-top:var(--Nav-Height-Inset) solid transparent; width:100%; pointer-events:none; transition-duration: var(--Page-Transition-Speed); transition-property: opacity;}
/* Turn on pointer events for all Lvl1 Current Articles */
.SM.Arrangement-2 > article.Layer-Lvl1.Layer-Current {opacity:1; z-index:1; pointer-events: auto;}

/* ----- Internal Elements ----- */

/* Cover */
.Arrangement-2 article > [data-field="Cover"] {grid-area:cover; display: block; z-index:-1; background: var(--Cover-Background);}
.Arrangement-2 article > [data-field="Cover"] img {height:var(--Cover-Height); width:calc( 100% + var(--Page-Gutter) ); margin-left:calc( var(--Page-Gutter) * -0.5 );}
.Arrangement-2 article > [data-field="Cover"]:after {z-index:1; height:var(--Cover-Height); visibility: visible; background:var(--Cover-Overlay);}
.Arrangement-2 article > [data-field="Cover"]:empty {--Cover-Height:0px; display:none;}
.Arrangement-2 article > [data-field="Cover"]:empty + section > .Card {--Cover-Height:0px;}

/* Header */
.Arrangement-2 article > header {grid-area:header; display:grid; z-index:2; background:var(--Background-Primary); font:var(--Title); text-transform:var(--Title-Case); color:var(--Surface-Primary); grid-template-columns:[button-start] auto [button-end title-start] 1fr [title-end] var(--Search-Icon-Width, 0px) var(--Profile-Icon-Width, 0px); grid-template-rows:[title-start button-start] 1fr [button-end title-end]; align-items: center; height:var(--Header-Height); padding:var(--Inset-Top) var(--Page-Gutter) 0 var(--Page-Gutter); background:var(--Header-Underlay);}
.Arrangement-2 > article > header > :not(.Action) {grid-area: title; /*! padding-right:calc(var(--Search-Icon-Width) + var(--Profile-Icon-Width) - var(--Page-Gutter)); */ display: -webkit-box; -webkit-line-clamp: 2; outline-style:none; -moz-outline-style: none; /*! white-space:nowrap; */ overflow:hidden; text-overflow:ellipsis;-webkit-box-orient: vertical;}
.Arrangement-2 > article > header > :not(.Action)::-moz-focus-inner {border:0;}

/* Header Button */
.Arrangement-2 > article > header .Action {grid-area: button; margin: 0 var(--Spacing-Narrow) 0 0;}
.LG.Arrangement-2 > article.Layer-Lvl1 header .Action [data-field="Icon Close"] svg {transform:scale( var(--Glyph-Scale) );  opacity:1;}
.LG.Arrangement-2 > article.Layer-Lvl1 header .Action [data-field="Icon Back"] svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) );  opacity:0;}

/* Section */
.Arrangement-2  > article > section {grid-area:section; width:100%; overflow-y:scroll; -webkit-overflow-scrolling:touch;}


/* ----- Stack Animation States ----- */

/* Initialise Out */
.Arrangement-2:not(.Stack-Active) > article > header > p,
.Arrangement-2:not(.Stack-Active) > article > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-2:not(.Stack-Active) > article > header > .Action {--Scale-Position:0.25; animation:scale-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-2:not(.Stack-Active) > article > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); animation:horizontal-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running!important;}
/* Initialise In */
.Arrangement-2.Stack-Init.Stack-Active > article > header > p,
.Arrangement-2.Stack-Init.Stack-Active > article > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-2.Stack-Init.Stack-Active > article > header > .Action {--Scale-Position:0.25; animation:scale-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-2.Stack-Init.Stack-Active > article > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); animation:horizontal-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}

/* Push Out */
.Arrangement-2.Stack-Push.Stack-Animating > article.Layer-Lvl3 > header > p,
.Arrangement-1.Stack-Push.Stack-Animating > article.Layer-Lvl3 > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-out var(--Page-Transition-Speed) ease 0s 1 normal both running;}
.Arrangement-2.Stack-Push.Stack-Animating > article > header > .Action {--Scale-Position:0.25; animation:scale-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-2.Stack-Push.Stack-Animating > article.Layer-Lvl3 > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); --Fade-Opacity:1; animation:horizontal-fade-out var(--Page-Transition-Speed) ease 0s 1 normal both running;}
/* Push In */
.Arrangement-2.Stack-Push.Stack-Animating > article.Layer-Current > header > p,
.Arrangement-2.Stack-Push.Stack-Animating > article.Layer-Current > header > .Action,
.Arrangement-2.Stack-Push.Stack-Animating > article.Layer-Current > section {--Horizontal-Position:calc( var(--Page-Gutter) * 0.75 ); animation:horizontal-fade-in var(--Page-Transition-Speed) ease 0s 1 normal both running;}
.Arrangement-2.Stack-Push.Stack-Animating > article.Layer-Current.Layer-Lvl1 > header > .Action {--Scale-Position:0.25; animation:scale-fade-in var(--Page-Transition-Speed) ease 0s 1 normal both running;}
.Arrangement-2.Stack-Push.Stack-Animating > article.Layer-Current > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * 0.25 ); --Fade-Opacity:1; animation:horizontal-fade-in var(--Page-Transition-Speed) ease 0s 1 normal both running;}

/* Pop Out */
.Arrangement-2.Stack-Pop.Stack-Animating > article.Layer-Lvl1 > header > p,
.Arrangement-2.Stack-Pop.Stack-Animating > article.Layer-Lvl1 > section {--Horizontal-Position:calc( var(--Page-Gutter) * 0.75 ); animation:horizontal-fade-out var(--Page-Transition-Speed) ease 0s 1 normal both running;}
.Arrangement-2.Stack-Pop.Stack-Animating > article.Layer-Lvl1  > header > .Action {--Scale-Position:0.25; animation:scale-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-2.Stack-Pop.Stack-Animating > article.Layer-Lvl1 > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * 0.25 ); --Fade-Opacity:1; animation:horizontal-fade-out var(--Page-Transition-Speed) ease 0s 1 normal both running;}

/* Pop In */
.Arrangement-2.Stack-Pop.Stack-Animating > article.Layer-Current > header > p,
.Arrangement-2.Stack-Pop.Stack-Animating > article.Layer-Current > header >.Action,
.Arrangement-2.Stack-Pop.Stack-Animating > article.Layer-Current > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-in var(--Page-Transition-Speed) ease 0s 1 normal both running;}
.Arrangement-2.Stack-Pop.Stack-Animating > article.Layer-Current > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); --Fade-Opacity:1; animation:horizontal-fade-in var(--Page-Transition-Speed) ease 0s 1 normal both running;}

/* Swap Out */
.Arrangement-2.Stack-Swap.Stack-Animating > article.Layer-Lvl1 > header > p,
.Arrangement-2.Stack-Swap.Stack-Animating > article.Layer-Lvl1 > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-2.Stack-Swap.Stack-Animating > article.Layer-Lvl1  > header > .Action {--Scale-Position:0.25; animation:scale-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-2.Stack-Swap.Stack-Animating > article.Layer-Lvl1 > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); --Fade-Opacity:1; animation:horizontal-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}

/* Swap In */
.Arrangement-2.Stack-Swap.Stack-Animating > article.Layer-Lvl1.Layer-Current > header > p,
.Arrangement-1.Stack-Swap.Stack-Animating > article.Layer-Lvl1.Layer-Current > section {--Horizontal-Position:calc( var(--Page-Gutter) * -0.75 ); animation:horizontal-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-2.Stack-Swap.Stack-Animating > article.Layer-Lvl1.Layer-Current > header > .Action {--Scale-Position:0.25; animation:scale-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-2.Stack-Swap.Stack-Animating > article.Layer-Lvl1.Layer-Current > [data-field="Cover"] {--Horizontal-Position:calc( var(--Page-Gutter) * -0.25 ); --Fade-Opacity:1; animation:horizontal-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}


/* ------------------------------ Arrangement-3 ------------------------------ */
/*
@Name: Arrangement-3
@Summary: Vertical cards which push in from bottom to top in a stack.
*/

/* Setup */
.Arrangement-3 {--Page-Transition-Speed:var(--Quick); --Panel-Shadow:0px 5px 10px rgba(0,0,0,0.4); --Panel-Radius:15px; z-index:0; pointer-events: none; transition-delay:calc( var(--Page-Transition-Speed) * 0.5 ); overflow:hidden; width:100%; height:100%;}
.Arrangement-3.Stack-Active {z-index:1; pointer-events: auto;}
.Arrangement-3 > article {--Cover-Height:200px; --Card-One-Indent:0px; --Card-Two-Indent:15px; position:absolute; top:0; left:0; width:calc(100% - var(--Nav-Width-Inset,0px) ); height:100%; z-index:0; display: grid; grid-template-columns:[panel-start header-start section-start footer-start cover-start] 1fr [cover-end footer-end section-end header-end panel-end]; grid-template-rows:[header-start] var(--Card-Offset,0px) [panel-start] var(--Header-Height) [header-end section-start cover-start] 1fr [section-end footer-start] auto [footer-end cover-end panel-end]; left:var(--Nav-Width-Inset,0px); pointer-events:none; transition-duration: var(--Page-Transition-Speed); transition-property: opacity,transform;}
.Arrangement-3 > article:before {content:""; grid-area:panel; background:var(--Background-Primary); box-shadow: var(--Panel-Shadow); border-radius:var(--Panel-Radius);}
.Arrangement-3 > article.Layer-Root {grid-template-rows:[header-start] var(--Card-Offset,0px) [panel-start] calc( var(--Nav-Height-Inset,0px) + var(--Header-Height,0px) ) [header-end section-start cover-start] 1fr [section-end footer-start] auto [cover-end footer-end panel-end];}

/* Card Offset */
.Arrangement-3 > article {--Card-Offset:0px;}
.Arrangement-3 > article.Layer-Root.Layer-Lvl2 + article.Layer-Lvl1,
.Arrangement-3 > article.Layer-Lvl2,
.Arrangement-3 > article:not(.Layer-Current).Layer-Root.Layer-Lvl1,
.Arrangement-3 > article.Layer-Lvl1.Layer-Root + article.Layer-Lvl1 {--Card-Offset:calc( var(--Nav-Height-Inset) + var(--Card-One-Indent) );}
.Arrangement-3 > article.Layer-Lvl1 {--Card-Offset:calc( var(--Nav-Height-Inset) + var(--Card-Two-Indent) );}
.Arrangement-3 > article.Layer-Root {--Card-Offset:0px;}
.Arrangement-3 > article:not(.Layer-Lvl1):not(.Layer-Lvl2):not(.Layer-Lvl3) {opacity:0!important;}
.Arrangement-3 > article:not(.Layer-Lvl1):not(.Layer-Lvl2) > header {opacity:0;}

/* Turn on pointer events for all Lvl1 Current Articles */
.Arrangement-3 > article[class*="Layer-Lvl"].Layer-Current {pointer-events: auto;}

/* ----- Internal Elements ----- */

/* Cover */
.Arrangement-3 article > [data-field="Cover"] {grid-area:cover; pointer-events:none; display: block; z-index:0; background: var(--Cover-Background);}
.Arrangement-3 article > [data-field="Cover"] img {height:var(--Cover-Height); width:calc( 100% + var(--Page-Gutter) ); margin-left:calc( var(--Page-Gutter) * -0.5 );}
.Arrangement-3 article > [data-field="Cover"]:after {z-index:1; height:var(--Cover-Height); visibility: visible; background:var(--Cover-Overlay);}
.Arrangement-3 article > [data-field="Cover"]:empty {--Cover-Height:0px; display:none;}
.Arrangement-3 article > [data-field="Cover"]:empty + section > .Card {--Cover-Height:0px;}


/* Header */
.Arrangement-3 article > header {grid-area:header; display:grid; z-index:2; font:var(--Title); text-transform:var(--Title-Case); color:var(--Surface-Primary); grid-template-columns:[button-start title-start] 1fr [title-end button-end]; grid-template-rows:[button-start] var(--Card-Offset,0px) [title-start] 1fr [title-end button-end]; align-items: center;}
.Arrangement-3 > article > header > :not(.Action) {grid-area: title; z-index:1; display:flex; align-items: center; border-radius:var(--Panel-Radius) var(--Panel-Radius) 0 0;  height: var(--Header-Height); background:var(--Background-Primary); padding:var(--Inset-Top) var(--Page-Gutter) 0 var(--Page-Gutter); padding-right:calc(var(--Search-Icon-Width) + var(--Profile-Icon-Width) - var(--Page-Gutter)); outline-style:none; -moz-outline-style: none; /*! white-space:nowrap; */ overflow:hidden; text-overflow:ellipsis; -webkit-box-orient: vertical;}
.Arrangement-3 > article > header > :not(.Action)::-moz-focus-inner {border:0;}

/* Header Button */
.Arrangement-3 > article > header .Action {grid-area:button; z-index:1; grid-template-columns:1fr [focus-start highlight-start icon-start] 30px [icon-end highlight-end focus-end]; grid-template-rows:var(--Nav-Height-Inset) [focus-start highlight-start icon-start] auto [icon-end highlight-end focus-end];}
.Arrangement-3 > article > header .Action [data-field="Icon Close"] svg {transform:scale( var(--Glyph-Scale) ); opacity:1;}
.Arrangement-3 > article > header .Action [data-field="Icon Back"] svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) );  opacity:0;}

/* Section */
.Arrangement-3  > article > section {grid-area:section; width:100%; overflow-y:scroll; -webkit-overflow-scrolling:touch;}

/* Footer */
.Arrangement-3 article > footer {grid-area:footer; z-index:2; background: var(--Background-Secondary); border-radius: 0 0 var(--Panel-Radius) var(--Panel-Radius);}

/* ----- Stack Animation States ----- */

/* Initialise Out */
.Arrangement-3:not(.Stack-Active) > article {--Vertical-Position:50px; animation:vertical-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-3:not(.Stack-Active) > article > header > .Action {--Scale-Position:0.25; animation:scale-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-3:not(.Stack-Active) > article > [data-field="Cover"] {--Vertical-Position:25px; animation:vertical-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running!important;}
/* Initialise In */
.Arrangement-3.Stack-Init.Stack-Active > article {--Vertical-Position:50px; animation:vertical-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-3.Stack-Init.Stack-Active > article > header > .Action {--Scale-Position:0.25; animation:scale-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-3.Stack-Init.Stack-Active > article > [data-field="Cover"] {--Vertical-Position:25px; animation:vertical-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}

/* Push In */
.Arrangement-3.Stack-Push.Stack-Animating > article.Layer-Lvl1.Layer-Current {--Vertical-Position:75px; --Fade-Opacity:0; animation:vertical-fade-in var(--Page-Transition-Speed) ease-out 0s 1 normal both running;}
.Arrangement-3.Stack-Push.Stack-Animating > article.Layer-Root ~ article.Layer-Lvl3.Layer-Current,
.Arrangement-3.Stack-Push.Stack-Animating > article:not(.Layer-Lvl3).Layer-Root ~ article.Layer-Lvl2.Layer-Current {--Vertical-Position:75px; --Fade-Opacity:1; animation:vertical-fade-in var(--Page-Transition-Speed) ease-out 0s 1 normal both running;}

/* Pop Out */
.Arrangement-3.Stack-Pop.Stack-Animating > article:not(.Layer-Current).Layer-Lvl1 {--Vertical-Position:75px; animation:vertical-fade-out var(--Page-Transition-Speed) ease-out 0s 1 normal both running;}
.Arrangement-3.Stack-Pop.Stack-Animating > article.Layer-Root ~ article.Layer-Lvl2.Layer-Current,
.Arrangement-3.Stack-Pop.Stack-Animating > article:not(.Layer-Lvl2).Layer-Root ~ article.Layer-Lvl1.Layer-Current {--Vertical-Position:-75px; --Fade-Opacity:1; animation:vertical-fade-in var(--Page-Transition-Speed) ease-out 0s 1 normal both running;}
.Arrangement-3.Stack-Pop.Stack-Animating > article.Layer-Lvl1.Layer-Root + article.Layer-Lvl1 {--Vertical-Position:75px!important;}




/* ------------------------------ Arrangement-4 ------------------------------ */
/*
@Name: Arrangement-4
@Summary: Vertical cards which push in from bottom to top in a stack.
*/

/* Setup */
.Arrangement-4 {--Page-Transition-Speed:var(--Quick); --Panel-Shadow:0px 5px 10px rgba(0,0,0,0.4); --Panel-Radius:15px; z-index:0; pointer-events: none; transition-delay:calc( var(--Page-Transition-Speed) * 0.5 ); overflow:hidden; width:100%; height:100%;}
.Arrangement-4.Stack-Active {z-index:1; pointer-events: auto;}
.Arrangement-4 > article {--Cover-Height:calc(300px + var(--Header-Height) ); --Card-One-Indent:0px; --Card-Two-Indent:0px; position:absolute; top:0; left:0; width:calc(100% - var(--Nav-Width-Inset,0px) ); height:100%; z-index:0; display: grid; grid-template-columns:[panel-start header-start section-start footer-start cover-start] 1fr [cover-end footer-end section-end header-end panel-end]; grid-template-rows:[header-start] var(--Card-Offset,0px) [panel-start  section-start cover-start] var(--Header-Height) [header-end] 1fr [section-end footer-start] auto [footer-end cover-end panel-end]; left:var(--Nav-Width-Inset,0px); pointer-events:none; transition-duration: var(--Page-Transition-Speed); transition-property: opacity,transform;}
.Arrangement-4 > article:before {content:""; grid-area:panel; background:var(--Background-Primary); box-shadow: var(--Panel-Shadow); border-radius:var(--Panel-Radius);}
.Arrangement-4 > article.Layer-Root {grid-template-rows:[header-start] var(--Card-Offset,0px) [panel-start section-start cover-start] calc( var(--Nav-Height-Inset,0px) + var(--Header-Height,0px) ) [header-end] 1fr [section-end footer-start] auto [cover-end footer-end panel-end];}
.SM.Arrangement-4 > article {--Cover-Height:calc(200px + var(--Header-Height) );}
.LG.Arrangement-4 > article {--Cover-Height:calc(400px + var(--Header-Height) );}
.XL.Arrangement-4 > article {--Cover-Height:calc(450px + var(--Header-Height) );}

/* Card Offset */
.Arrangement-4 > article {--Card-Offset:0px;}
.Arrangement-4 > article.Layer-Root.Layer-Lvl2 + article.Layer-Lvl1,
.Arrangement-4 > article.Layer-Lvl2,
.Arrangement-4 > article:not(.Layer-Current).Layer-Root.Layer-Lvl1,
.Arrangement-4 > article.Layer-Lvl1.Layer-Root + article.Layer-Lvl1 {--Card-Offset:calc( var(--Nav-Height-Inset) + var(--Card-One-Indent) );}
.Arrangement-4 > article.Layer-Lvl1 {--Card-Offset:calc( var(--Nav-Height-Inset) + var(--Card-Two-Indent) );}
.Arrangement-4 > article.Layer-Root {--Card-Offset:0px;}
.Arrangement-4 > article:not(.Layer-Lvl1):not(.Layer-Lvl2):not(.Layer-Lvl3) {opacity:0!important;}
.Arrangement-4 > article:not(.Layer-Lvl1):not(.Layer-Lvl2) > header {opacity:0;}

/* Turn on pointer events for all Lvl1 Current Articles */
.Arrangement-4 > article[class*="Layer-Lvl"].Layer-Current {pointer-events: auto;}

/* ----- Internal Elements ----- */

/* Cover */
.Arrangement-4 article > [data-field="Cover"] {grid-area:cover; pointer-events:none; display: block; z-index:0; background: var(--Cover-Background);}
.Arrangement-4 article > [data-field="Cover"] img {height:var(--Cover-Height); width:calc( 100% + var(--Page-Gutter) ); margin-left:calc( var(--Page-Gutter) * -0.5 );}
.Arrangement-4 article > [data-field="Cover"]:after {z-index:1; height:var(--Cover-Height); visibility: visible; background:var(--Cover-Overlay);}
.Arrangement-4 article > [data-field="Cover"]:empty {--Cover-Height:0px; display:none;}
.Arrangement-4 article > [data-field="Cover"]:empty + section > .Card {--Cover-Height:0px;}


/* Header */
.Arrangement-4 article > header {grid-area:header; display:grid; z-index:2; font:var(--Title); text-transform:var(--Title-Case); color:var(--Surface-Primary); grid-template-columns:[title-start] 1fr [title-end button-start] auto [button-end] var(--Page-Gutter); grid-template-rows:var(--Spacing-Narrow) [button-start] var(--Card-Offset,0px) [title-start] 1fr [title-end button-end]; align-items: center;}

.Arrangement-4 > article > header > :not(.Action) {opacity:0; grid-area: title; z-index:1; display:flex; align-items: center; height: var(--Header-Height); padding:var(--Inset-Top) var(--Page-Gutter) 0 var(--Page-Gutter); padding-right:calc(var(--Search-Icon-Width) + var(--Profile-Icon-Width) - var(--Page-Gutter)); outline-style:none; -moz-outline-style: none; /*! white-space:nowrap; */ overflow:hidden; text-overflow:ellipsis; -webkit-box-orient: vertical;}
.Arrangement-4 > article > header > :not(.Action)::-moz-focus-inner {border:0;}

/* Header Button */
.Arrangement-4 > article > header .Action {grid-area:button; z-index:1;}
.Arrangement-4 > article > header .Action [data-field="Icon Close"] svg {transform:scale( var(--Glyph-Scale) ); opacity:1;}
.Arrangement-4 > article > header .Action [data-field="Icon Back"] svg {transform:scale( calc(var(--Glyph-Scale) * 0.25) );  opacity:0;}

/* Section */
.Arrangement-4  > article > section {grid-area:section; width:100%; overflow-y:scroll; -webkit-overflow-scrolling:touch;}

/* Footer */
.Arrangement-4 article > footer {grid-area:footer; z-index:2; background: var(--Background-Secondary); border-radius: 0 0 var(--Panel-Radius) var(--Panel-Radius);}

/* ----- Stack Animation States ----- */

/* Initialise Out */
.Arrangement-4:not(.Stack-Active) > article {--Vertical-Position:50px; animation:vertical-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-4:not(.Stack-Active) > article > header > .Action {--Scale-Position:0.25; animation:scale-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running;}
.Arrangement-4:not(.Stack-Active) > article > [data-field="Cover"] {--Vertical-Position:25px; animation:vertical-fade-out calc( var(--Page-Transition-Speed) * 0.5 ) ease 0s 1 normal both running!important;}
/* Initialise In */
.Arrangement-4.Stack-Init.Stack-Active > article {--Vertical-Position:50px; animation:vertical-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-4.Stack-Init.Stack-Active > article > header > .Action {--Scale-Position:0.25; animation:scale-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}
.Arrangement-4.Stack-Init.Stack-Active > article > [data-field="Cover"] {--Vertical-Position:25px; animation:vertical-fade-in calc( var(--Page-Transition-Speed) * 0.5 ) ease calc( var(--Page-Transition-Speed) * 0.5 ) 1 normal both running;}

/* Push In */
.Arrangement-4.Stack-Push.Stack-Animating > article.Layer-Lvl1.Layer-Current {--Vertical-Position:75px; --Fade-Opacity:0; animation:vertical-fade-in var(--Page-Transition-Speed) ease-out 0s 1 normal both running;}
.Arrangement-4.Stack-Push.Stack-Animating > article.Layer-Root ~ article.Layer-Lvl3.Layer-Current,
.Arrangement-4.Stack-Push.Stack-Animating > article:not(.Layer-Lvl3).Layer-Root ~ article.Layer-Lvl2.Layer-Current {--Vertical-Position:75px; --Fade-Opacity:1; animation:vertical-fade-in var(--Page-Transition-Speed) ease-out 0s 1 normal both running;}

/* Pop Out */
.Arrangement-4.Stack-Pop.Stack-Animating > article:not(.Layer-Current).Layer-Lvl1 {--Vertical-Position:50px; animation:vertical-fade-out var(--Page-Transition-Speed) ease-out 0s 1 normal both running;}
.Arrangement-4.Stack-Pop.Stack-Animating > article.Layer-Root ~ article.Layer-Lvl2.Layer-Current,
.Arrangement-4.Stack-Pop.Stack-Animating > article:not(.Layer-Lvl2).Layer-Root ~ article.Layer-Lvl1.Layer-Current {--Vertical-Position:-50px; --Fade-Opacity:1; animation:vertical-fade-in var(--Page-Transition-Speed) ease-out 0s 1 normal both running;}
.Arrangement-4.Stack-Pop.Stack-Animating > article.Layer-Lvl1.Layer-Root + article.Layer-Lvl1 {--Vertical-Position:50px!important;}
