/*
@Name: Page 1
@Summary: Title and Subtitle above main content
*/
.Page-D1 > .Card {display:grid; grid-template-columns:[title-start subtitle-start card-start body-start] 1fr [body-end card-end subtitle-end title-end]; grid-template-rows: [title-start] max-content [title-end subtitle-start] max-content [subtitle-end] var(--Spacing-Wide) [card-start] var(--Card-Padding-Top) [body-start] max-content [body-end] var(--Spacing-Wide) [card-end];}
.Page-D1 > .Card:before {grid-area:card; position:relative; top:var(--Card-Vertical-Offset); display:block; content:""; z-index:-1; border-radius:var(--Card-Radius); background:var(--Background-Primary);}
.Page-D1 > .Card > [data-field="Title"] {grid-area:title; display:block; padding:var(--Spacing-Wide) var(--Page-Gutter); font:var(--Title); text-transform:var(--Title-Case); color:var(--Surface-Primary); color:var(--Overlay-Primary);}
.Page-D1 > .Card > [data-field="Subtitle"] {grid-area:subtitle; display:block; padding:0 var(--Page-Gutter) 0 var(--Page-Gutter); font:var(--Subtitle); text-transform:var(--Subtitle-Case); color:var(--Overlay-Primary);}
.Page-D1 > .Card > [data-field="Body"] {grid-area:body; display:block; font:var(--Body); color:var(--Surface-Primary);}

/*
@Name: Page 2
@Summary: No Title or Subtitle 
*/
.Page-D2 > .Card {display:grid; grid-template-columns:[card-start body-start] 1fr [body-end card-end]; grid-template-rows:[card-start] var(--Card-Padding-Top) [body-start] max-content [body-end] var(--Spacing-Wide) [card-end];}
.Page-D2 > .Card:before {grid-area:card; position:relative; top:var(--Card-Vertical-Offset); display:block; content:""; z-index:-1; border-radius:var(--Card-Radius); background:var(--Background-Primary);}
.Page-D2 > .Card > [data-field="Body"] {grid-area:body; display:block; font:var(--Body); color:var(--Surface-Primary);}


/* Temp Tab D0 */
.Tab-D0 > .Card {display:grid; grid-template-columns:[body-start] 1fr [body-end]; grid-template-rows: [body-start] 1fr [body-end];}
.Tab-D0 > .Card > [data-field="Body"] {grid-area:body; display:block; font:var(--Body); color:var(--Surface-Primary);}

/* Temp Tab D1 */
.Tab-D1 > .Card {display:grid; grid-template-columns:[title-start subtitle-start card-start body-start] 1fr [body-end card-end subtitle-end title-end]; grid-template-rows: [title-start] max-content [title-end subtitle-start] max-content [subtitle-end] var(--Spacing-Wide) [card-start] var(--Card-Padding-Top) [body-start] max-content [body-end] var(--Spacing-Wide) [card-end];}
.Tab-D1 > .Card:before {grid-area:card; position:relative; top:var(--Card-Vertical-Offset); display:block; content:""; z-index:-1; border-radius:var(--Card-Radius); background:var(--Background-Primary);}
.Tab-D1 > .Card > [data-field="Title"] {grid-area:title; display:block; padding:var(--Spacing-Wide) var(--Page-Gutter); font:var(--Title); text-transform:var(--Title-Case); color:var(--Surface-Primary); color:var(--Overlay-Primary);}
.Tab-D1 > .Card > [data-field="Subtitle"] {grid-area:subtitle; display:block; padding:0 var(--Page-Gutter) 0 var(--Page-Gutter); font:var(--Subtitle); text-transform:var(--Subtitle-Case); color:var(--Overlay-Primary);}
.Tab-D1 > .Card > [data-field="Body"] {grid-area:body; display:block; font:var(--Body); color:var(--Surface-Primary);}


