
.addblockpopover .content {
	height: 195px;
	overflow-y: scroll;
	background: #fff;
	margin: 0px 4px 4px 4px;
	border-radius: 8px;
}

.addblockpopover .heading {
	display: flex;
}

.addblockpopover .heading .title {
	flex:1; display:flex;align-items:center; padding-left: 15px; height: 40px;
	display: none;
}

.addblockpopover .heading .toolbar {
	flex:1; padding: 4px;
}


.addblockpopover .heading .toolbar input {
	width: 100%;
    padding: 6px;
    box-sizing: border-box;
}


.addblockpopover .heading .close {
	flex:1;
    display:flex;justify-content:center;align-items:center;
    max-width: 30px;
    margin-right: 6px;
}

.addblockpopover .heading .close * { max-width: 20px; max-height: 20px; }
.addblockpopover .heading .close svg { fill:#aaa; }


.addblockpopover .content .item {
	display: grid;
	grid-template-columns: [icon-start] 50px [icon-end title-start summary-start] 1fr [title-end summary-end];
	grid-template-rows: [icon-start title-start] auto [title-end summary-start] 1fr [summary-end icon-end];
	min-height: 45px;
}
.addblockpopover .content .item .title { grid-area: title; font-weight: bold; padding-top: 5px; }
.addblockpopover .content .item .summary { grid-area: summary; font-size: 12px; padding-bottom: 5px; }
.addblockpopover .content .item .icon { grid-area: icon; display: flex; justify-content:center;align-items:center; }
.addblockpopover .content .item .icon svg { max-width: 50%; max-height: 60%; }
