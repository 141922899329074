
.tagspopover .content {
	height: 180px;
	overflow-y: scroll;
	background: #fff;
	margin: 0px 4px 4px 4px;
	border-radius: 8px;
}

.tagspopover .heading {
	display: flex;
}

.tagspopover .heading .title {
	flex:1; display:flex;align-items:center; padding-left: 15px; height: 40px;
	display: none;
}

.tagspopover .heading .toolbar {
	flex:1; padding: 4px;
}


.tagspopover .heading .toolbar input {
	width: 100%;
    padding: 6px;
    box-sizing: border-box;
}


.tagspopover .heading .close {
	flex:1;
    display:flex;justify-content:center;align-items:center;
    max-width: 30px;
    margin-right: 6px;
}

.tagspopover .heading .close * { max-width: 20px; max-height: 20px; }
.tagspopover .heading .close svg { fill:#aaa; }

.tagspopover .content .tagitem {
	margin: 6px;
}

.tagspopover .content .tagitem span.tag {
	border: 1px solid #eee;
	padding: 1px 8px;
	border-radius: 6px;
    background: #ddd;
    font-family: Lato;
    font-size: 15px;
}
