.Editor-Audience-L1 [data-field="Title"] { display: block; }
.Editor-Audience-L1 [data-field="Email"] { display: block; }

/** Workflow editor */

._WorkflowState-E1 {
    display: grid;
    margin: 15px;
    margin-bottom: 30px;
    grid-template-columns: 40px 1fr 1fr 24px;
    grid-template-rows: auto auto auto;
    gap: 5px 5px;
    grid-auto-flow: row;
    grid-template-areas:
      "Color Title Edit Drag"
      "Moves Moves Autos ."
      "Add-Move Add-Move Add-Auto .";
}
._WorkflowState-E1 > [data-field="Title"] {
    grid-area: Title; display: block;
    font: var(--Body);
    color: var(--Surface-Primary);
}
._WorkflowState-E1 > [data-field="Title"] p { margin: 4px 0; }
._WorkflowState-E1 > [data-field="Color"] {
    grid-area: Color; display: block;
    background: var(--Option-Color);
    height: 30px;
    width: 30px;
    overflow: hidden;
    color: transparent;
    border-radius: 30px;
}
._WorkflowState-E1 > [data-field="Moves"] { grid-area: Moves; display: block; }
._WorkflowState-E1 > [data-field="Add Move"] { grid-area: Add-Move; display: block; }
._WorkflowState-E1 > [data-field="Automations"] { grid-area: Autos; display: block; }
._WorkflowState-E1 > [data-field="Add Auto"] { grid-area: Add-Auto; display: block; }
._WorkflowState-E1 > [data-field="Edit"] { grid-area: Edit; display: block; }
._WorkflowState-E1 > [data-field="Edit"] > .Action {
    margin-left: auto;
    width: 40px;
}
._WorkflowState-E1 > .Operator-Grab { grid-area: Drag; }

table.Basic-Table.Full {
    width: 100%;
}
table.Basic-Table.Full.Gutter {
    width: 94%;
    margin: 3%;
}
table.Basic-Table {
    font: var(--Body);
    border-collapse: collapse;
}
table.Basic-Table th {
    font: var(--Subtitle);
}
table.Basic-Table td {
    padding: 2px;
    border: 1px solid #aaa;
}
