.datatoolbar {
    display: flex;
}
.datatoolbar .button {
    padding: 5px 9px;
    margin: 2px 1px;
    background: #eee;
    font: 14px Lato, Arial;
    border-radius: 25px;
}
.datatoolbar .button:active { background: #ccc; }

.datatoolbar .button.add { }
.datatoolbar .button.search { }
.datatoolbar .button.more { }
