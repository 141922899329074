/**/
.menupopover .content { max-height: 320px; overflow-y: auto; }

.FilterRow { display: flex; flex-direction: row; padding-bottom:3px; }

.SelectField .Action { min-width: 120px; }

.FilterRow .FilterRow-Value {
    flex: 1;
    display: flex;
    border: 1px solid #ddd;
    padding: 0 6px;
    border-radius: 15px;
}
.FilterRow .FilterRow-Value > div { flex: 1; align-items: center; display: flex; }
.FilterRow .FilterRow-Value > div > .Is-Richtext { flex: 1; }


.FilterRow .FilterRow-Value:empty { display:none; }
.FilterRow .FilterRow-Value header { display:none; }
.FilterRow .FilterRow-Value .Group { --Spacing-Regular: 1px; --Spacing-Narrow: 2px; }
