.record-slider { display: flex; flex-wrap: wrap; }
.slider-row { background: #eee;
    margin: 2px;
    padding: 5px 4px;
    border-radius: 4px; }
.slider-row .slider-label { font-family:Lato, Arial; font-size: 14px; color:#333; }
.slider-row.selected { background: #308df0; }
.slider-row.selected .slider-label { color: #fff; }
.record-slider .add-button {
    background: #f7f5f5;
    margin: 2px;
    padding: 5px 4px;
    border-radius: 4px;
}
